import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState, useEffect } from "react";
import { connect, shallowEqual, useSelector } from "react-redux";
import i18n from "../../../modules/Auth/pages/i18n";
import { useTranslation } from "react-i18next";
import CommonSlider from "./CommonSlider";
import { toast } from "material-react-toastify";
import moment from "moment";
import {
  djangoHandleDataMutationRequest,
  djangoHandleDataRequests,
  handleSpringDataMutationRequest,
} from "../../../api";
import axios from "axios";

const NewPaymentModalEditView = ({
  open,
  handleAddEventSidebar,
  invoiceData,
  selectedLanguage,
  currency,
  hotelType,
  reservationData,
  reservationId,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);
  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );

  const [actionType, setactionType] = useState("Cash payment");
  const [showLine, setShowLine] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const loggedUserEmail = localStorage.getItem("logedUserEmail");

  const [ctaAmt, setCtaAmt] = useState(reservationData?.balance_due);
  const [ctaDesc, setCtaDesc] = useState("");
  const [ctaLoader, setCtaLoader] = useState(false);
  const [settleCtaDate, setSettleCtaDate] = useState(new Date());

  // perform EMV transaction loading
  const [performEMVTransactionLoading1, setPerformEMVTransactionLoading1] =
    useState(false);
  const [performEMVTransactionLoading2, setPerformEMVTransactionLoading2] =
    useState(false);
  const [performEMVTransactionLoading3, setPerformEMVTransactionLoading3] =
    useState(false);

  const [isAdmin, setisAdmin] = useState(
    hotel?.accessControl?.ADMINRIGHTS === "ADMIN"
  );

  const [paymentViewAccess, setpaymentViewAccess] = useState(
    JSON.parse(hotel?.accessControl?.user_feature_map?.PaymentViewAccess)
      .read === "YES"
      ? "YES"
      : "NO"
  );

  //STRIPE ONLINE PAYMENT INTENT CREATION
  const [stripeAccount, setStripeAccount] = useState(
    hotel?.accessControl?.hotel?.pg_account_id
  );
  const [savedCards, setSavedCards] = useState(null);
  const getSavedCards = () => {
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/customer-card/get-saved-customer-card?bookingId=${reservationData?.booking_id}`,
      { email: invoiceData?.invoice?.customer_email }
    )
      .then((res) => setSavedCards(res))
      // .then((res) => fetchBookingData())
      .catch((err) => toast.error(err));
  };

  useEffect(() => {
    if (invoiceData?.invoice?.customer_email) {
      getSavedCards();
    }
  }, [reservationData?.booking_id, invoiceData?.invoice?.customer_email]);

  const [customPgData, setCustomPgData] = useState(null);
  const getCustomPgData = () => {
    djangoHandleDataRequests(`payments/getHotelCustomPgInfo/`)
      .then((res) => setCustomPgData(res !== null ? res : []))
      // .then((res) => fetchBookingData())
      .catch((err) => toast.error(err));
  };

  // fields for for perform EMV transaction
  const [performEMVTransactionAmt, setPerformEMVTransactionAmt] = useState();
  const [performEMVTransactionSaveCard, setPerformEMVTransactionSaveCard] =
    useState(true);
  const [emvTerminalId, setEmvTerminalId] = useState(null);
  useEffect(() => {
    const terminalIds = () => {
      if (customPgData && customPgData?.hasOwnProperty("terminals")) {
        setEmvTerminalId(customPgData?.terminals[0].id);
      }
    };
    terminalIds();
  }, [customPgData, hotel.hotelId]);

  const enablePerformEMVTxn = ["FORTIS", "TSYS", "SHIFT4", "STRIPE"].includes(
    customPgData?.active_pg_name
  );

  const enableAddCard = ["FORTIS", "TSYS", "SHIFT4", "STRIPE"].includes(
    customPgData?.active_pg_name
  );
  // Disabling the flow untill reports is fixed - Sasank (commit by arunesh-kumar-sf)
  const enableRefundCard = false;
  const custom_pg_name = customPgData?.active_pg_name;
  const shift4_utg_url = customPgData?.shift4_utg_url;
  const shift4_access_token = customPgData?.shift4_access_token;

  // OTA BOOKING INFO DATAs
  // Get the OTA booking info data
  const [otaBookingInfoData, setOtaBookingInfoData] = useState([]);
  const getOtaBookingInfoData = () => {
    djangoHandleDataRequests(
      `invoice/getOTADetailsForBooking/?bookingID=${reservationData?.booking_id}`
    )
      .then((res) => setOtaBookingInfoData(res !== null ? res : []))
      // .then((res) => fetchBookingData())
      .catch((err) => toast.error(err));
  };

  useEffect(() => {
    reservationData?.booking_id && getOtaBookingInfoData();
    reservationData?.booking_id && getCustomPgData();
  }, [reservationData?.booking_id]);

  const [openOTAOTAAddedCard, setOpenOTAAddedCard] = useState(false);
  const handleOpenOTAAddedCard = () => setOpenOTAAddedCard(true);
  const handleCloseOTAAddedCard = () => setOpenOTAAddedCard(false);

  const [openAddedCard, setOpenAddedCard] = useState(false);
  const [openedAddedCardData, setOpenedAddedCardData] = useState(false);
  const handleOpenAddedCard = (item) => {
    setOpenAddedCard(true);
    setOpenedAddedCardData(item);
  };
  const handleCloseAddedCard = () => setOpenAddedCard(false);

  const [openPerformEMVTransaction, setOpenPerformEMVTransaction] =
    useState(false);
  const handleOpenPerformEMVTransaction = () =>
    setOpenPerformEMVTransaction(true);
  const handleClosePerformEMVTransaction = () =>
    setOpenPerformEMVTransaction(false);

  const [openCta, setOpenCta] = useState(false);
  const handleOpenCta = () => setOpenCta(true);
  const handleCloseCta = () => setOpenCta(false);

  // add Card modal

  const [openAddCard, setOpenAddCard] = useState(false);
  const handleOpenAddCard = () => setOpenAddCard(true);
  const handleCloseAddCard = () => setOpenAddCard(false);

  /*SHIFT4 multi terminal support
   * Get the specific terminal details if multiple terminals are configured
   * */
  function getShift4TerminalDetails(custom_pg_terminal_id) {
    let terminals = customPgData?.terminals;
    let shift4_utg_url = "";
    let shift4_api_terminal_id = "";
    terminals.forEach((element) => {
      if (custom_pg_terminal_id === element.id) {
        shift4_utg_url = element.shift4_utg_url;
        shift4_api_terminal_id = element.shift4_api_terminal_id;
      }
    });
    return {
      shift4_utg_url: shift4_utg_url,
      shift4_api_terminal_id: shift4_api_terminal_id,
    };
  }

  // handling the perform EMV transaction flow

  const handlePerformEMVTransactionCharge = async () => {
    setPerformEMVTransactionLoading1(true);
    let data = {
      hotelId: hotel.hotelId,
      bookingID: reservationData?.booking_id,
      reservation_id: reservationId,
      cust_email: invoiceData?.invoice?.customer_email,
      name: invoiceData?.invoice?.customer_name,
      is_emv: "YES",
      trans_type: "sale",
      add_card: performEMVTransactionSaveCard,
      amount: Number(performEMVTransactionAmt),
      logged_user_email: loggedUserEmail,
    };
    if (custom_pg_name === "SHIFT4" && shift4_utg_url) {
      djangoHandleDataMutationRequest(
        "POST",
        "invoice/generateShift4UtgRequest/",
        data
      )
        .then((shift4_utg_request) => {
          console.log(shift4_utg_request);
          let URL = shift4_utg_url + "api/rest/v1/transactions/sale";
          if (emvTerminalId) {
            data["terminal_id"] = emvTerminalId;
            let shift4_terminal_info = getShift4TerminalDetails(emvTerminalId);
            URL =
              shift4_terminal_info.shift4_utg_url +
              "api/rest/v1/transactions/sale";
            shift4_utg_request["device"]["terminalId"] =
              shift4_terminal_info.shift4_api_terminal_id;
          }

          let myHeaders = new Headers();
          myHeaders.append("InterfaceVersion", "4.0");
          myHeaders.append("InterfaceName", "Stayflexi");
          myHeaders.append("CompanyName", "Stayflexi");
          myHeaders.append("AccessToken", shift4_access_token);
          myHeaders.append("Content-Type", "application/json");

          let raw = JSON.stringify(shift4_utg_request);
          let requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };

          fetch(URL, requestOptions)
            .then((response) => {
              return response.json().then((json) => {
                return response.ok
                  ? json
                  : Promise.reject({ status: response.status, json });
              });
            })
            .then((result) => {
              console.log(result);
              data["shift4_utg_response"] = result;
              data["shift4_utg_request"] = shift4_utg_request;
              djangoHandleDataMutationRequest(
                "POST",
                "invoice/recordShift4UtgResponse/",
                data
              )
                .then((res) => callInvoiceIdGeneration())
                .then((res) => {
                  console.log(res);
                  toast.success(`${t("EMV Card charged successfully")}`, {
                    closeOnClick: true,
                    pauseOnHover: true,
                    autoClose: 8000,
                    hideProgressBar: true,
                    bodyClassName: "importantToast",
                  });
                })
                .then((res) => setPerformEMVTransactionLoading1(false))
                .then((res) => handleClosePerformEMVTransaction())
                .then((res) => setPerformEMVTransactionAmt())
                .then((res) => setPerformEMVTransactionSaveCard(true))
                .catch((error) => {
                  toast.error(error, {
                    closeOnClick: true,
                    pauseOnHover: true,
                    autoClose: 8000,
                    hideProgressBar: true,
                    bodyClassName: "importantToast",
                  });
                  setPerformEMVTransactionLoading1(false);
                });
            })
            .catch((error) => {
              setPerformEMVTransactionLoading1(false);
              if (
                error.json &&
                error.json.result &&
                error.json.result.length > 0 &&
                error.json.result[0].error
              ) {
                console.log(error);
                console.log(error.json.result[0].error.longText);
                console.error(error.json);
                toast.error(
                  "Transaction failed. Gateway message: " +
                    error.json.result[0].error.longText,
                  {
                    closeOnClick: true,
                    pauseOnHover: true,
                    autoClose: 8000,
                    hideProgressBar: true,
                    bodyClassName: "importantToast",
                  }
                );
              } else {
                console.log(error);
                toast.error("Failed to charge the card", {
                  closeOnClick: true,
                  pauseOnHover: true,
                  autoClose: 8000,
                  hideProgressBar: true,
                  bodyClassName: "importantToast",
                });
              }
            });
        })
        .catch((error) => {
          toast.error(error, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
          setPerformEMVTransactionLoading1(false);
        });
    } else {
      if (emvTerminalId) {
        data["terminal_id"] = emvTerminalId;
      }
      djangoHandleDataMutationRequest(
        "POST",
        "invoice/chargeCustomerCardOwn/",
        data
      )
        .then((res) => callInvoiceIdGeneration())
        .then((res) => {
          toast.success(`${t("EMV Card charged successfully")}`, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
        })
        .then((res) => setPerformEMVTransactionLoading1(false))
        .then((res) => handleClosePerformEMVTransaction())
        .then((res) => setPerformEMVTransactionAmt())
        .then((res) => setPerformEMVTransactionSaveCard(true))
        .catch((error) => {
          toast.error(error, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
          setPerformEMVTransactionLoading1(false);
        });
    }
  };

  const handlePerformEMVTransactionRefund = async () => {
    setPerformEMVTransactionLoading3(true);
    if (enablePerformEMVTxn && enableRefundCard) {
      let data = {
        hotelId: hotel.hotelId,
        bookingID: reservationData?.booking_id,
        reservation_id: reservationId,
        cust_email: invoiceData?.invoice?.customer_email,
        name: invoiceData?.invoice?.customer_name,
        is_emv: "YES",
        trans_type: "refund",
        add_card: performEMVTransactionSaveCard,
        amount: Number(performEMVTransactionAmt),
        logged_user_email: loggedUserEmail,
      };
      if (emvTerminalId) {
        data["terminal_id"] = emvTerminalId;
      }
      djangoHandleDataMutationRequest(
        "POST",
        "invoice/savedCardStandaloneRefund/",
        data
      )
        .then((res) => callInvoiceIdGeneration())
        .then((res) => {
          toast.success(`${t("EMV Card refunded successfully")}`, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
        })
        .then((res) => setPerformEMVTransactionLoading3(false))
        .then((res) => handleClosePerformEMVTransaction())
        .then((res) => setPerformEMVTransactionAmt())
        .then((res) => setPerformEMVTransactionSaveCard(true))
        .catch((error) => {
          toast.error(error, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
          setPerformEMVTransactionLoading3(false);
        });
    }
  };

  const handlePerformEMVTransactionHold = () => {
    setPerformEMVTransactionLoading2(true);
    let data = {
      hotelId: hotel.hotelId,
      bookingID: reservationData?.booking_id,
      reservation_id: reservationId,
      cust_email: invoiceData?.invoice?.customer_email,
      name: invoiceData?.invoice?.customer_name,
      is_emv: "YES",
      trans_type: "auth",
      add_card: performEMVTransactionSaveCard,
      amount: Number(performEMVTransactionAmt),
      logged_user_email: loggedUserEmail,
    };
    if (emvTerminalId) {
      data["terminal_id"] = emvTerminalId;
    }
    if (custom_pg_name === "SHIFT4" && shift4_utg_url) {
      djangoHandleDataMutationRequest(
        "POST",
        "invoice/generateShift4UtgRequest/",
        data
      )
        .then((shift4_utg_request) => {
          console.log(shift4_utg_request);
          let URL = shift4_utg_url + "api/rest/v1/transactions/authorization";
          if (emvTerminalId) {
            data["terminal_id"] = emvTerminalId;
            let shift4_terminal_info = getShift4TerminalDetails(emvTerminalId);
            URL =
              shift4_terminal_info.shift4_utg_url +
              "api/rest/v1/transactions/authorization";
            shift4_utg_request["device"]["terminalId"] =
              shift4_terminal_info.shift4_api_terminal_id;
          }

          let myHeaders = new Headers();
          myHeaders.append("InterfaceVersion", "4.0");
          myHeaders.append("InterfaceName", "Stayflexi");
          myHeaders.append("CompanyName", "Stayflexi");
          myHeaders.append("AccessToken", shift4_access_token);
          myHeaders.append("Content-Type", "application/json");
          let raw = JSON.stringify(shift4_utg_request);
          let requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };

          fetch(URL, requestOptions)
            .then((response) => {
              return response.json().then((json) => {
                return response.ok
                  ? json
                  : Promise.reject({ status: response.status, json });
              });
            })
            .then((result) => {
              console.log(result);
              data["shift4_utg_response"] = result;
              data["shift4_utg_request"] = shift4_utg_request;
              djangoHandleDataMutationRequest(
                "POST",
                "invoice/recordShift4UtgResponse/",
                data
              )
                .then((res) => callInvoiceIdGeneration())
                .then((res) => {
                  console.log(res);
                  toast.success(`${t("EMV Card authorized successfully")}`, {
                    closeOnClick: true,
                    pauseOnHover: true,
                    autoClose: 8000,
                    hideProgressBar: true,
                    bodyClassName: "importantToast",
                  });
                })
                .then((res) => setPerformEMVTransactionLoading2(false))
                .then((res) => handleClosePerformEMVTransaction())
                .then((res) => setPerformEMVTransactionAmt())
                .then((res) => setPerformEMVTransactionSaveCard(true))
                .catch((error) => {
                  toast.error(error, {
                    closeOnClick: true,
                    pauseOnHover: true,
                    autoClose: 8000,
                    hideProgressBar: true,
                    bodyClassName: "importantToast",
                  });
                  setPerformEMVTransactionLoading2(false);
                });
            })
            .catch((error) => {
              if (
                error.json &&
                error.json.result &&
                error.json.result.length > 0 &&
                error.json.result[0].error
              ) {
                console.log(error);
                console.log(error.json.result[0].error.longText);
                console.error(error.json);
                toast.error(
                  "Transaction failed. Gateway message: " +
                    error.json.result[0].error.longText,
                  {
                    closeOnClick: true,
                    pauseOnHover: true,
                    autoClose: 8000,
                    hideProgressBar: true,
                    bodyClassName: "importantToast",
                  }
                );
                setPerformEMVTransactionLoading2(false);
              } else {
                console.log(error);
                toast.error("Failed to authorize the card", {
                  closeOnClick: true,
                  pauseOnHover: true,
                  autoClose: 8000,
                  hideProgressBar: true,
                  bodyClassName: "importantToast",
                });
              }
            });
        })
        .catch((error) => {
          toast.error(error, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
          setPerformEMVTransactionLoading2(false);
        });
    } else {
      djangoHandleDataMutationRequest(
        "POST",
        "invoice/authorizeCustomerCardOwn/",
        data
      )
        .then((res) => callInvoiceIdGeneration())
        .then((res) => {
          toast.success(`${t("EMV Card authorized successfully")}`, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
        })
        .then((res) => setPerformEMVTransactionLoading2(false))
        .then((res) => handleClosePerformEMVTransaction())
        .then((res) => setPerformEMVTransactionAmt())
        .then((res) => setPerformEMVTransactionSaveCard(true))
        .catch((error) => {
          toast.error(error, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
          setPerformEMVTransactionLoading2(false);
        });
    }
  };

  function handleDirectCtaBilling() {
    setCtaLoader(true);
    djangoHandleDataMutationRequest(
      "POST",
      "invoice/addBillToCTAServiceToInvoice/",
      {
        hotelId: hotel.hotelId,
        booking_id: reservationData?.booking_id,
        reservation_id: reservationId,
        bill_to_cta_date: moment(settleCtaDate).format("YYYY-MM-DD"),
        bill_to_cta_amt: ctaAmt,
        bill_to_cta_amt_desc: ctaDesc,
        logged_user_email: loggedUserEmail,
      }
    )
      .then((res) => toast.success(`${t("Operation successful.")}`))
      /* .then((res) =>
            amplitude
              .getInstance()
              .logEvent(
                "USER PREFORMED DIRECT BILLING OPERATION THROUGH FOLIO PAGE",
                amplitudeEventProperties
              )
          ) */
      .then((res) => setCtaLoader(false))
      .then((res) => handleCloseCta())
      .then((res) => callInvoiceIdGeneration())
      .catch((err) => setCtaLoader(false))
      .catch((err) => handleCloseCta())
      .catch((err) => toast.error(err));
  }

  const [performOnlinePaymentModal, setPerformOnlinePaymentModal] =
    useState(false);
  const openPerformOnlinePaymentModal = () =>
    setPerformOnlinePaymentModal(true);
  const closePerformOnlinePaymentModal = () =>
    setPerformOnlinePaymentModal(false);

  const [onlinePaymentAmount, setOnlinePaymentAmount] = useState(0);
  const [onlinePaymentLoader, setOnlinePaymentLoader] = useState(false);
  const [clientSecretKey, setClientSecretKey] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");

  const [paymentIntentModal, setPaymentIntentModal] = useState(false);
  const openPaymentIntentModal = () => setPaymentIntentModal(true);
  const closePaymentIntentModal = () => setPaymentIntentModal(false);
  const [performPayment, setPerformPayment] = useState(false);
  const [openTransferReservation, setOpenTransferReservation] = useState(false);

  const handleCreateOnlinePaymentIntent = () => {
    if (onlinePaymentAmount <= 0) {
      toast.error(`${t("Payment amount must be greater than 0")}`);
      return;
    }
    setOnlinePaymentLoader(true);
    let request_payload = {
      hotel_id: hotel.hotelId,
      amount: Number(onlinePaymentAmount).toFixed(2),
      hotelName: invoiceData.hotel.hotel_name,
      currency: currency,
      booking_id: reservationData?.booking_id,
      module_source: "BOOKING_FOLIO",
      booking_source: "BOOKING_FOLIO",
      stripe_account: stripeAccount,
    };
    djangoHandleDataMutationRequest(
      "POST",
      "payments/createDirectPaymentIntent/",
      request_payload
    )
      .then((res) => {
        if (res?.client_secret_key !== "") {
          setClientSecretKey(res?.client_secret_key);
          setPaymentIntentId(res?.id);
        }
      })
      .then((res) => setOnlinePaymentLoader(false))
      .then((res) => openPaymentIntentModal());
  };

  const handleStripeSuccessPayment = (paymentId, bookingId) => {
    setOnlinePaymentLoader(true);
    let request_payload = {
      hotelId: hotel.hotelId,
      booking_id: reservationData?.booking_id,
      payment_gateway_id: paymentId,
      pg_name: "STRIPE",
      module_source: "BOOKING_FOLIO",
      booking_source: "BOOKING_FOLIO",
      amount: Number(onlinePaymentAmount).toFixed(2),
      requires_post_payment_confirmation: "true",
      customer_email: invoiceData?.invoice?.customer_email,
      stripe_account: stripeAccount,
      logged_user_email: loggedUserEmail,
    };
    djangoHandleDataMutationRequest(
      "POST",
      "payments/recordPayment/",
      request_payload
    )
      .then((res) =>
        toast.success(`${t("Online payment successfully recorded!")}`)
      )
      .then((res) => setOnlinePaymentLoader(false))
      .then((res) => setOnlinePaymentAmount(0))
      .then((res) => closePaymentIntentModal())
      .then((res) => closePerformOnlinePaymentModal());
  };

  //call to create custom invoice
  const callInvoiceIdGeneration = () => {
    axios.get(
      `${process.env.REACT_APP_SPRING_API_URL}web-api/generate-custom-invoice-id?bookingId=${reservationData?.booking_id}&bookingStatus=${reservationData?.booking_status}`
    );
  };

  useEffect(() => {
    setPerformEMVTransactionAmt(reservationData?.balance_due);
  }, [reservationData]);

  return (
    <div className={classes.root}>
      <CommonSlider
        open={open}
        onClose={() => {
          handleAddEventSidebar();
        }}
      >
        <Grid container>
          <Grid container item xs={12} className={classes.header}>
            <Grid
              container
              xs={10}
              spacing={2}
              justifyContent="flex-start"
              item
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {t("Action Type")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={actionType}
                    label={t("Payment Type")}
                    onChange={(eve) => {
                      setactionType(eve.target.value);
                    }}
                    MenuProps={MenuProps}
                  >
                    {JSON.parse(
                      hotel?.accessControl?.user_feature_map?.CashPayment
                    ).read === "YES" && (
                      <MenuItem value={"Cash payment"}>
                        {t("Cash payment")}
                      </MenuItem>
                    )}
                    {JSON.parse(
                      hotel?.accessControl?.user_feature_map?.OfflineCardPayment
                    ).read === "YES" && (
                      <MenuItem value={"Offline card payment"}>
                        {t("Offline card payment")}
                      </MenuItem>
                    )}
                    {JSON.parse(
                      hotel?.accessControl?.user_feature_map
                        ?.OfflineChequePayment
                    ).read === "YES" && (
                      <MenuItem
                        onClick={() => {
                          setactionType("Offline cheque payment");
                        }}
                      >
                        {t("Offline cheque payment")}
                      </MenuItem>
                    )}
                    {hotelType !== "ISHA" && currency === "INR" && (
                      <MenuItem
                        onClick={() => {
                          setactionType("UPI Payment");
                        }}
                      >
                        {t("UPI Payment")}
                      </MenuItem>
                    )}

                    {hotelType !== "ISHA" &&
                      JSON.parse(
                        hotel?.accessControl?.user_feature_map
                          ?.OtherPaymentModes
                      ).read === "YES" && (
                        <MenuItem
                          onClick={() => {
                            setactionType("Other payment modes");
                          }}
                        >
                          {t("Other payment modes")}
                        </MenuItem>
                      )}

                    {JSON.parse(
                      hotel?.accessControl?.user_feature_map?.OtherPaymentModes
                    ).read === "YES" && (
                      <MenuItem
                        onClick={() => {
                          setactionType("Bank transfer");
                        }}
                      >
                        {t("Bank transfer")}
                      </MenuItem>
                    )}
                    {JSON.parse(
                      hotel?.accessControl?.user_feature_map?.CashDeposit
                    ).read === "YES" && (
                      <MenuItem
                        onClick={() => {
                          {
                            setactionType("Cash deposit");
                          }
                        }}
                      >
                        {t("Cash deposit")}
                      </MenuItem>
                    )}

                    {JSON.parse(
                      hotel?.accessControl?.user_feature_map?.PaymentLink
                    ).read === "YES" ? (
                      <>
                        {invoiceData?.invoice?.is_enquiry === "true" ? (
                          <>
                            {moment(
                              invoiceData?.invoice?.enquiry_expiration_time
                            ).unix() > moment().unix() && (
                              <MenuItem
                                onClick={() => {
                                  setactionType("Send payment link");
                                }}
                              >
                                {t("Send payment link")}
                              </MenuItem>
                            )}
                          </>
                        ) : (
                          <MenuItem
                            onClick={() => {
                              setactionType("Send payment link");
                            }}
                          >
                            {t("Send payment link")}
                          </MenuItem>
                        )}
                      </>
                    ) : (
                      <></>
                    )}

                    {invoiceData.hotel.hotel_country?.toUpperCase() !==
                      "INDIA" &&
                      hotel?.accessControl?.hotel?.pg_account_id !== "" &&
                      hotelType !== "ISHA" &&
                      JSON.parse(
                        hotel?.accessControl?.user_feature_map?.ChargeCard
                      ).read === "YES" && (
                        <MenuItem
                          onClick={() => openPerformOnlinePaymentModal()}
                        >
                          {t("Gateway Payment (Stripe)")}
                        </MenuItem>
                      )}
                    {isAdmin === "ADMIN" &&
                      custom_pg_name !== "SHIFT4" &&
                      Object.keys(otaBookingInfoData) &&
                      otaBookingInfoData?.card_num &&
                      JSON.parse(
                        hotel?.accessControl?.user_feature_map?.ChargeCard
                      ).read === "YES" && (
                        <MenuItem onClick={() => handleOpenOTAAddedCard()}>
                          {t("Charge")}{" "}
                          {otaBookingInfoData?.card_holder_first_name}{" "}
                          {otaBookingInfoData?.card_holder_last_name} CARD **
                          {otaBookingInfoData?.card_num?.slice(12, 16)} -{" "}
                          {otaBookingInfoData?.source}
                        </MenuItem>
                      )}
                    {isAdmin !== "ADMIN" &&
                      custom_pg_name !== "SHIFT4" &&
                      paymentViewAccess === "YES" &&
                      Object.keys(otaBookingInfoData) &&
                      otaBookingInfoData?.card_num &&
                      JSON.parse(
                        hotel?.accessControl?.user_feature_map?.ChargeCard
                      ).read === "YES" && (
                        <MenuItem onClick={() => handleOpenOTAAddedCard()}>
                          {t("Charge")}{" "}
                          {otaBookingInfoData?.card_holder_first_name}{" "}
                          {otaBookingInfoData?.card_holder_last_name} CARD **
                          {otaBookingInfoData?.card_num?.slice(12, 16)} -{" "}
                          {otaBookingInfoData?.source}
                        </MenuItem>
                      )}
                    <div className="horizontalSep" />
                    {enableAddCard &&
                      JSON.parse(
                        hotel?.accessControl?.user_feature_map?.AddCard
                      ).read === "YES" && (
                        <MenuItem onClick={() => handleOpenAddCard()}>
                          {t("Add card")}
                        </MenuItem>
                      )}
                    {enablePerformEMVTxn &&
                      JSON.parse(
                        hotel?.accessControl?.user_feature_map?.PerformEMV
                      ).read === "YES" && (
                        <MenuItem
                          onClick={() => handleOpenPerformEMVTransaction()}
                        >
                          {t("Perform EMV transaction")}
                        </MenuItem>
                      )}
                    {(enableAddCard || stripeAccount !== "") &&
                      savedCards?.map((item, index) => {
                        return (
                          <MenuItem
                            key={index}
                            onClick={() => {
                              handleOpenAddedCard(item);
                            }}
                          >
                            {item?.is_emv === "YES" ? "EMV " : ""}
                            {item.card_first_name} {t("CARD")}**
                            {item.card_last4}
                          </MenuItem>
                        );
                      })}
                    {(enableAddCard || stripeAccount !== "") &&
                      invoiceData?.saved_cards_own?.map((item, index) => {
                        return (
                          <MenuItem
                            key={index}
                            onClick={() => handleOpenAddedCard(item)}
                          >
                            {item.card_first_name} {t("CARD")}
                            **
                            {item.card_last4}
                          </MenuItem>
                        );
                      })}
                    {invoiceData?.invoice?.cta_details && (
                      <>
                        <div className="horizontalSep" />
                        <MenuItem onClick={() => handleOpenCta()}>
                          {t("Direct bill to")} -{" "}
                          {invoiceData?.invoice.booking_source_displayname}
                        </MenuItem>
                      </>
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {showLine && <LinearProgress className={classes.linerProgress} />}
          {!showLine && <Divider sx={{ width: "100%", marginBottom: 2 }} />}

          {!dataLoading && (
            <Grid sx={{ width: "100%", height: "100%" }} container></Grid>
          )}
        </Grid>
      </CommonSlider>
    </div>
  );
};

export default NewPaymentModalEditView;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  textField: {
    padding: theme.spacing(2),
  },
  linerProgress: {
    marginBottom: theme.spacing(2),
    width: "100%",
    "& > * + *": {
      marginop: theme.spacing(2),
    },
  },
  header: {
    padding: theme.spacing(2),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
    },
  },
};
