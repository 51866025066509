import React, { useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Fab,
  Grid,
  Grow,
  IconButton,
  Paper,
  Popper,
  TextField,
  Typography,
  Button,
  Stack,
  Checkbox,
} from "@mui/material";
import { Close, Send } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import CommonSlider from "./CommonSlider";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { shallowEqual, useSelector } from "react-redux";
import { toast } from "material-react-toastify";
import { useTranslation } from "react-i18next";
import i18n from "../../../modules/Auth/pages/i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
  custom_box: {
    width: "100%",
    padding: theme.spacing(2),
    whiteSpace: "no-wrap",
    borderRadius: 10,
  },
}));

const BookingNotesModal = (props) => {
  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );
  const {
    open,
    closeFunc,
    modalClose,
    handleCloseBookingConf,
    invoiceData,
    handleSendBookingConfirmation,
    selectedLanguage,
    hotelType,
    setBookingConfMail,
    bookingConfMail,
    bookingConfLoading,
    createBookingNote,
    loading,
    bookingNoteData,
    setbookingNoteData,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CommonSlider
        onBackdropClick={closeFunc}
        onClose={modalClose}
        open={open}
      >
        <Grid container className={classes.textField}>
          <Grid item xs={10} alignSelf="center">
            <Typography variant="h5"> {"Create a booking note"}</Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              onClick={() => {
                modalClose();
              }}
              sx={{ float: "right" }}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>

        <Divider />

        <Grid container spacing={2} className={classes.textField}>
          <Grid item xs={12}>
            <div className="settleDuesWrapper">
              <div className="amountWrapper">
                <div className="fieldWrapper">
                  <TextField
                    required
                    id="outlined-required"
                    label={t("Booking notes")}
                    placeholder={t("Enter a note")}
                    className="w-100"
                    onChange={(e) => setbookingNoteData(e.target.value)}
                  />
                </div>
              </div>
              <div
                className="settleDuesSubmitBtns w-100"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "2rem",
                }}
              >
                <Button
                  className="cancelBtn"
                  onClick={() => handleCloseBookingConf()}
                >
                  {t("Cancel")}
                </Button>

                <Button
                  className="w-auto"
                  variant="outlined"
                  style={{
                    textTransform: "none",
                    backgroundColor: `${"#36454F"}`,
                    color: `${"white"}`,
                    borderColor: "#36454F",
                    fontWeight: "bold",
                  }}
                  disabled={loading}
                  onClick={createBookingNote}
                >
                  {loading ? (
                    <CircularProgress
                      size={15}
                      color="inherit"
                      sx={{ ml: 1.5 }}
                    />
                  ) : (
                    `${t("Save")}`
                  )}
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </CommonSlider>
    </div>
  );
};

export default BookingNotesModal;
