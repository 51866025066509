import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  djangoHandleDataRequests,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { Button, Checkbox, Typography } from "@mui/material";
import { connect } from "react-redux";
import { handleGetDoorLockDetails } from "../../CalendarPage/api/ReservationApi";
import { addNewBookingNote } from "../../CalendarPage/api/BookingAndCustomerNotesApi";
import { invalidateNightAuditReservation } from "../../CalendarPage/api/ReservationApi";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import i18n from "../../../modules/Auth/pages/i18n";
import axios from "axios";

function UpgradeRoom({
  onCancel,
  bookingId,
  invoiceData,
  hotelId,
  reloadGrid,
  reservationId,
  isGrid,
  data,
  selectedLanguage,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);
  /* Booking Info */
  const [bookingInfo, setBookingInfo] = useState(null);
  // upgrade price loader
  const [upgradeLoader, setUpgradeLoader] = useState(false);
  const [remark, setremark] = useState("");
  const [openDirtyModal, setopenDirtyModal] = useState(false);
  const [isRoomDirty, setisRoomDirty] = useState(false);
  const [showButtonOfPricing, setshowButtonOfPricing] = useState("");
  const [selectedRatePlan, setselectedRatePlan] = useState("");
  const [allRatePlans, setallRatePlans] = useState([]);
  const [ratePlanObj, setratePlanObj] = useState({});
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const closeDirtysModal = () => setopenDirtyModal(false);
  const openDirtysModal = () => setopenDirtyModal(true);
  function getBookingInfo() {
    setUpgradeLoader(true);
    djangoHandleDataRequests(
      `invoice/getBookingInfoById?bookingID=${bookingId}`
    )
      .then((res) => {
        setBookingInfo(res);
        setUpgradeLoader(false);
      })
      .catch((err) => {
        toast.error(`${t("Error fetching the data.")}`);
        setUpgradeLoader(false);
      });
  }

  useEffect(() => {
    setUpgradeLoader(true);
    getBookingInfo();
    setUpgradeLoader(false);
  }, []);

  // getting all the room types
  const [roomTypes, setRoomTypes] = useState(null);

  // Handling getting all the room types
  function handleGetAllRoomTypes() {
    setUpgradeLoader(true);
    handleSpringDataRequest("core/api/v1/reservation/getAllNonHiddenRoomTypes")
      .then((res) => {
        setRoomTypes(res);
        setRoomType(
          Object.keys(res).find(
            (key) =>
              res[key] ===
              invoiceData?.invoice.reservation_room_info_list[0].roomTypeName
          )
        );
        setUpgradeLoader(false);
      })
      .catch((err) => {
        toast.error(`${t("Error getting all the room types.")}`);
        setUpgradeLoader(false);
      });
  }

  useEffect(() => {
    setUpgradeLoader(true);
    handleGetAllRoomTypes();
    setUpgradeLoader(false);
  }, []);

  // choosing room type
  const [roomType, setRoomType] = useState(
    roomTypes ? Object.keys(roomTypes)[0] : ""
  );
  const handleSelectRoomTypeChange = (event) => {
    setRoomType(event.target.value);
  };

  // Setting up the room type
  // useEffect(() => {
  //   roomTypes &&
  //     setRoomType(
  //       Object.keys(roomTypes).find(
  //         (key) =>
  //           roomTypes[key] ===
  //           invoiceData?.invoice.reservation_room_info_list[0].roomTypeName
  //       )
  //       // invoiceData?.invoice.reservation_room_info_list[0].roomTypeName
  //     );
  // }, []);

  // getting the unblocked rooms based on the room type id
  const [allRooms, setAllRooms] = useState(null);
  function handleGetUnblockedRooms() {
    setUpgradeLoader(true);
    handleSpringDataRequest(
      `core/api/v1/room/non-blocked-rooms-with-status/?resId=${
        reservationId[selectedRooms]
      }&roomTypeId=${roomType === "all" ? "" : roomType}`
    )
      .then((res) => {
        setAllRooms(res);
        setUpgradeLoader(false);
      })
      .catch((error) => {
        console.log(`${t("Error in fetching the room ids.")}`);
        setUpgradeLoader(false);
      });
  }

  useEffect(() => {
    setUpgradeLoader(true);
    roomType && handleGetUnblockedRooms();
    setUpgradeLoader(false);
  }, [roomType, selectedRooms]);

  // getting the selected room
  const [selectedRoom, setSelectedRoom] = useState("");
  useEffect(() => {
    setUpgradeLoader(true);
    allRooms && setSelectedRoom(allRooms[0]?.roomId);
    setUpgradeLoader(false);
  }, [allRooms]);

  const handleSelectRoomIdChange = (event) => {
    setSelectedRoom(event.target.value);
    if (roomType === "all") {
      handleGetUpgradeCost();
    }
  };

  // rate types and the rate
  const [rateType, setRateType] = useState("TOTAL_PRETAX");
  const [newRate, setNewRate] = useState(null);

  // handling the room upgrade cost
  const [upgradeCost, setUpgradeCost] = useState(null);
  function handleGetUpgradeCost(val) {
    console.log("val", val);
    setUpgradeLoader(true);
    bookingInfo &&
      handleSpringDataMutationRequest(
        "POST",
        "core/api/v1/reservation/getUpgradePricePayload",
        {
          destRoomTypeId: roomType === "all" ? null : roomType,
          hotelId: hotelId,
          newRate: parseInt(newRate),
          newRateType: rateType,
          reservationId: bookingInfo?.res_id_list[0],
          destRoomId: selectedRoom,
          destRatePlanId: checked
            ? val === undefined || val === null
              ? selectedRatePlan
              : val
            : null,
        }
      )
        .then((res) => {
          setUpgradeCost(res);
          setUpgradeLoader(false);
          setshowButtonOfPricing("");
        })
        .catch((err) => {
          console.log(`${t("Error getting the prices.")}`);
          setUpgradeLoader(false);
          setshowButtonOfPricing("");
        });
  }

  useEffect(() => {
    setUpgradeLoader(true);
    setTimeout(() => {
      handleGetUpgradeCost();
    }, 1000);
    setUpgradeLoader(false);
  }, [rateType, newRate, roomType, selectedRoom]);

  useEffect(() => {
    if (newRate == null && newRate === undefined) {
      handleGetUpgradeCost();
    }
  }, [newRate]);

  useEffect(() => {
    setUpgradeLoader(true);
    if (upgradeCost) {
      setPricePerNight(upgradeCost.perNightPrice.toFixed(2));
      setTotalBeforeTax(upgradeCost.priceBeforeTax.toFixed(2));
      setTotalTax(upgradeCost.taxValue.toFixed(2));
      setTotalWithTax(upgradeCost.priceAfterTax.toFixed(2));
    }
    setUpgradeLoader(false);
  }, [upgradeCost]);

  // price per change
  const [pricePerNight, setPricePerNight] = useState(0);
  const handlePricePerNightChange = (event) => {
    setshowButtonOfPricing("perNightPrice");
    setPricePerNight(event.target.value);
  };

  // Total price before tax
  const [totalBeforeTax, setTotalBeforeTax] = useState(null);
  const handleTotalBeforeTaxChange = (event) => {
    setshowButtonOfPricing("priceBeforeTax");
    setTotalBeforeTax(event.target.value);
  };

  // Total tax value
  const [totalTax, setTotalTax] = useState(null);
  const handleTotalTaxChange = (event) => {
    setTotalTax(event.target.value);
  };

  // Total price after tax
  const [totalWithTax, setTotalWithTax] = useState(null);
  const handleTotalWithTaxChange = (event) => {
    setshowButtonOfPricing("priceAfterTax");
    setTotalWithTax(event.target.value);
  };

  // Handle upgrade room
  async function handleUpgradeReservation() {
    if (
      JSON.parse(data?.accessControl?.user_feature_map?.MoveSwapRoomRemark)
        .read === "YES" &&
      remark.length <= 0 &&
      data?.accessControl?.ADMINRIGHTS !== "ADMIN" &&
      data?.accessControl?.ADMINRIGHTS !== "SUBADMIN"
    ) {
      toast.error("Please add remark");
    } else {
      setUpgradeLoader(true);
      try {
        const res = axios.post(
          `${process.env.REACT_APP_BG_API_URL}core/api/v1/reservation/moveReservation`,
          {
            destRoomTypeId: roomType === "all" ? null : roomType,
            hotelId: hotelId,
            moveCost: upgradeCost.priceAfterTax,
            physicalRoomId: selectedRoom,
            reservationId: reservationId[selectedRooms],
            remark: remark,
            destRatePlanId: checked ? selectedRatePlan : null,
            destRatePlan: checked ? ratePlanObj[selectedRatePlan] : null,
          }
        );

        if (Number(upgradeCost.priceAfterTax) !== 0) {
          await invalidateNightAuditReservation(reservationId[selectedRooms]);
        }

        await handleGetDoorLockDetails({
          bookingId: bookingId,
          reservationId: reservationId,
          actionRequired: "recreate",
        });
        if (remark.length > 0) {
          addBookingNotes();
        }
        if (isGrid) {
          reloadGrid();
        }
        toast.success(
          `${t("Successfully moved/upgraded room for")} ${bookingId}`
        );
        onCancel("right", false);
      } catch (err) {
        toast.error(`${t("Error performing the operation.")}`);
        setUpgradeLoader(false);
      }
      setUpgradeLoader(false);
      closeDirtysModal();
    }

    // setUpgradeLoader(true);
    // handleSpringDataMutationRequest(
    //   "POST",
    //   "core/api/v1/reservation/moveReservation",
    //   {
    //     destRoomTypeId: roomType === "all" ? null : roomType,
    //     hotelId: hotelId,
    //     moveCost: upgradeCost.priceAfterTax,
    //     physicalRoomId: selectedRoom,
    //     reservationId: reservationId[selectedRooms],
    //   }
    // )
    //   .then((res) => toast.success("Operation successful"))
    //   .then((res) => (isGrid ? reloadGrid() : ""))
    //   .then((res) => setUpgradeLoader(false))
    //   .catch((err) => toast.error("Error performing the operation."))
    //   .catch((err) => setUpgradeLoader(false));
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [selectedRooms, setselectedRooms] = useState([
    invoiceData.invoice.room_ids_list[0],
  ]);

  const handleChanges = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length > 0)
      setselectedRooms(
        // On autofill we get a the stringified value.
        typeof value === "string" ? value.split(",") : value
      );
  };

  const addBookingNotes = async () => {
    const payload = {
      hotelId: hotelId,
      bookingID: bookingId,
      note:
        "EMAIL :- " +
        localStorage.getItem("email") +
        "|| MOVE ROOM ||" +
        remark,
      logged_in_user: localStorage.getItem("email"),
    };
    await addNewBookingNote(payload);
  };

  useEffect(() => {
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/reservation/get-room-dirty-status`,
      selectedRooms
    )
      .then((res) => {
        const datas = Object.values(res).filter((e) => e === "DIRTY");
        if (datas?.length > 0) {
          setisRoomDirty(true);
        } else {
          setisRoomDirty(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [selectedRooms]);

  const moveRes = (str) => {
    setUpgradeLoader(true);
    if (str === "YES") {
      axios
        .post(
          `${process.env.REACT_APP_BG_API_URL}core/api/v1/hk/bulk-mark-rooms-dirty`,
          {
            roomIds: selectedRooms,
            staffId: "",
            hotelId: hotelId,
          }
        )
        .then((res) => handleUpgradeReservation())
        .catch((err) => handleUpgradeReservation());
    } else {
      handleUpgradeReservation();
    }
  };

  const getRatePlans = () => {
    handleSpringDataRequest(
      `core/api/v1/reservation/get-room-id-rate-plan?reservationId=${reservationId[selectedRooms]}&physicalRoomId=${selectedRoom}`
    ).then((res) => {
      const ratePlanObj = res.reduce((acc, item) => {
        acc[item.id] = item.name;
        return acc;
      }, {});

      setratePlanObj(ratePlanObj); // Store the transformed object

      if (res.length > 0) {
        setallRatePlans(Object.entries(ratePlanObj)); // Keep original array for UI or dropdown list

        // Select the first rate plan's id as default
        setselectedRatePlan(res[0].id);

        // Call handleGetUpgradeCost with the first rate plan's id
        handleGetUpgradeCost(res[0].id);
      }
    });
  };

  useEffect(() => {
    if (
      selectedRoom !== null &&
      selectedRoom !== undefined &&
      selectedRoom !== "" &&
      checked
    ) {
      getRatePlans();
    }
  }, [selectedRoom, checked]);

  useEffect(() => {
    if (!checked) {
      handleGetUpgradeCost();
    }
  }, [checked]);

  return (
    <Stack m={3}>
      <Stack spacing={4}>
        <Stack spacing={1}>
          <h4>{t("Select the room id to upgrade room")}</h4>

          <FormControl sx={{ width: "100%" }}>
            <InputLabel>{t("Room ids")}</InputLabel>
            <Select
              value={selectedRooms}
              onChange={handleChanges}
              required="true"
              MenuProps={MenuProps}
              label={t("Room ids")}
            >
              {invoiceData?.invoice?.room_ids_list?.map((name) => (
                <MenuItem key={name.id} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>

        <Stack direction="row" spacing={2}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {t("Choose room type")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="outlined-select-currency"
              select
              label={t("Choose room type")}
              value={roomType}
              onChange={handleSelectRoomTypeChange}
              // helperText="Choose Room Type"
              sx={{ width: "100%" }}
            >
              <MenuItem value={"all"}> {t("All")}</MenuItem>
              {roomTypes &&
                Object.entries(roomTypes).map(([key, value]) => (
                  <MenuItem value={key}> {value} </MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {t("Choose room ID")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="outlined-select-currency"
              select
              label={t("Choose room ID")}
              value={selectedRoom}
              onChange={handleSelectRoomIdChange}
              // helperText="Choose Room Type"
              sx={{ width: "100%" }}
            >
              {allRooms &&
                allRooms?.map((id) => (
                  <MenuItem
                    key={id?.roomId}
                    value={id?.roomId}
                    style={{
                      color: `${
                        id?.dirtyStatus === "CLEAN" ? "#1BC5BD" : "#B96E6E"
                      }`,
                      fontWeight: "bold",
                    }}
                  >
                    <Stack
                      direction={"row"}
                      spacing={2}
                      // style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography sx={{ color: "black" }}>
                        {id.roomId}
                      </Typography>

                      <Typography
                        sx={{
                          color: `${
                            id?.dirtyStatus === "CLEAN" ? "#1BC5BD" : "#B96E6E"
                          }`,
                        }}
                      >
                        {id.dirtyStatus}
                      </Typography>
                    </Stack>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <>
            <div
              className="d-flex"
              style={{ alignItems: "center", marginBottom: "1rem" }}
            >
              <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
              <p
                style={{
                  marginLeft: "0.5rem",
                  marginBottom: "0px",
                }}
              >
                Edit rate plan
              </p>
            </div>

            {checked && (
              <TextField
                label={"Rate plan"}
                name="ratePlan"
                id="ratePlan"
                required
                fullWidth
                select
                onChange={(e) => {
                  setselectedRatePlan(e.target.value);
                  handleGetUpgradeCost(e.target.value);
                }}
                value={selectedRatePlan}
              >
                {allRatePlans.map((id) => (
                  <MenuItem key={id[0]} value={id[0]}>
                    {id[1]}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </>
        </Stack>
        <Stack direction="row" spacing={4}>
          <div className="d-flex w-100">
            <TextField
              id="outlined-multiline-flexible"
              label={t("Price per night")}
              multiline
              // rows={1}
              value={pricePerNight}
              sx={{ width: "100%" }}
              // onChange={(e) =>
              //   e.target.value.match("^[0-9]*.[0-9]{0,2}$")
              //     ? setPricePerNight(e.target.value)
              //     : setPricePerNight(pricePerNight)
              // }
              onChange={handlePricePerNightChange}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={
                JSON.parse(
                  data?.accessControl?.user_feature_map?.EditBookingPrice
                ).read !== "YES"
                  ? true
                  : upgradeLoader
                  ? true
                  : false
              }
              // InputProps={{ inputProps: { min: 0 } }}
            />

            {showButtonOfPricing === "perNightPrice" && (
              <Button
                size="small"
                style={{ marginLeft: "2rem" }}
                variant="custom-button"
                onClick={() => {
                  setUpgradeLoader(true);
                  setRateType("PER_NIGHT_PRETAX");
                  setNewRate(pricePerNight);
                }}
                disabled={upgradeLoader}
              >
                {upgradeLoader ? (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                ) : (
                  `${t("Apply")}`
                )}
              </Button>
            )}
          </div>

          <div className="d-flex w-100">
            <TextField
              id="outlined-multiline-flexible"
              label={t("Total before tax")}
              multiline
              // rows={1}
              value={totalBeforeTax}
              sx={{ width: "100%" }}
              onChange={handleTotalBeforeTaxChange}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={
                JSON.parse(
                  data?.accessControl?.user_feature_map?.EditBookingPrice
                ).read !== "YES"
                  ? true
                  : upgradeLoader
                  ? true
                  : false
              }
            />
            {showButtonOfPricing === "priceBeforeTax" && (
              <Button
                size="small"
                style={{ marginLeft: "2rem" }}
                variant="custom-button"
                onClick={() => {
                  setUpgradeLoader(true);
                  setRateType("TOTAL_PRETAX");
                  setNewRate(totalBeforeTax);
                }}
                disabled={upgradeLoader}
              >
                {upgradeLoader ? (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                ) : (
                  `${t("Apply")}`
                )}
              </Button>
            )}
          </div>
        </Stack>
        <Stack direction="row" spacing={4}>
          <div className="w-100">
            <TextField
              id="outlined-multiline-flexible"
              label={t("Total tax")}
              multiline
              // rows={1}
              value={totalTax}
              sx={{ width: "100%" }}
              onChange={handleTotalTaxChange}
              disabled
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>

          <div className="d-flex w-100">
            <TextField
              id="outlined-multiline-flexible"
              label={t("Total with tax")}
              multiline
              // rows={1}
              value={totalWithTax}
              sx={{ width: "100%" }}
              onChange={handleTotalWithTaxChange}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={
                JSON.parse(
                  data?.accessControl?.user_feature_map?.EditBookingPrice
                ).read !== "YES"
                  ? true
                  : upgradeLoader
                  ? true
                  : false
              }
            />
            {showButtonOfPricing === "priceAfterTax" && (
              <Button
                size="small"
                style={{ marginLeft: "2rem" }}
                variant="custom-button"
                onClick={() => {
                  setUpgradeLoader(true);
                  setRateType("TOTAL_WITHTAX");
                  setNewRate(totalWithTax);
                }}
                disabled={upgradeLoader}
              >
                {upgradeLoader ? (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                ) : (
                  `${t("Apply")}`
                )}
              </Button>
            )}
          </div>
        </Stack>
        <Stack direction="row" spacing={4}>
          <TextField
            fullWidth
            onChange={(e) => setremark(e.target.value)}
            label={t("Remark")}
            value={remark}
          />
        </Stack>
      </Stack>
      <Stack alignItems="flex-end" mt={2}>
        {upgradeLoader === false ? (
          <Button
            variant="custom-button"
            disabled={showButtonOfPricing !== ""}
            onClick={() => {
              if (
                invoiceData.invoice.status.toLowerCase() === "admin_confirmed"
              ) {
                if (isRoomDirty) {
                  handleUpgradeReservation();
                } else {
                  openDirtysModal();
                }
              } else {
                handleUpgradeReservation();
              }
            }}
          >
            {t("Upgrade room")}
          </Button>
        ) : (
          <Button variant="custom-button">
            <CircularProgress size="15px" />
          </Button>
        )}
      </Stack>
      <Modal
        show={openDirtyModal}
        onHide={closeDirtysModal}
        style={{ zIndex: "3000" }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h6>{`${t("Do you want to mark room number")} - ${selectedRooms} ${t(
            "dirty"
          )} ?`}</h6>
        </Modal.Body>
        <Modal.Footer
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            variant="custom-button"
            onClick={() => moveRes("NO")}
            disabled={upgradeLoader}
          >
            {upgradeLoader ? (
              <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
            ) : (
              `${t("NO")}`
            )}
          </Button>
          <Button
            variant="custom-button"
            onClick={() => moveRes("YES")}
            disabled={upgradeLoader}
          >
            {upgradeLoader ? (
              <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
            ) : (
              `${t("YES")}`
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </Stack>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel, hotelType: state.auth.hotelType };
}
export default connect(mapStateToProps)(UpgradeRoom);
