// ** React Imports
import AddIcon from "@mui/icons-material/Add";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import UploadIcon from "@mui/icons-material/Upload";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Stack,
  FormControl,
  Select,
  InputLabel,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  Switch,
} from "@mui/material";
import Webcam from "react-webcam";
import { makeStyles } from "@mui/styles";
import { format } from "date-fns";
import { useFormik } from "formik";
import { isNull, isUndefined, update } from "lodash";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import React, { useEffect, useRef, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import {
  default as ImageUploading,
  default as ReactImageUploading,
} from "react-images-uploading";
import { shallowEqual, useSelector } from "react-redux";
import * as yup from "yup";
import {
  getUserId,
  getIshaDocuments,
  setBookingUserIdImages,
  setIshaGuestDocument,
} from "../../BookingDashboard/apis/BrandingModalApi";
import { imageUpdateByEmail } from "../../FlexibleSlot/api/FlexibleSlotApi";
import {
  addNewCustomerNote,
  addNewDoNotRentStatus,
  deleteTheCustomerNotes,
  getAllCustomerNotes,
  removeNewDoNotRentStatus,
  upadateNotes,
} from "../api/BookingAndCustomerNotesApi";
import {
  checkIshaAccess,
  checkIshaUSAccess,
  getApprovedByOverseas,
  getCountriesAndStates,
  getGenderList,
  getIshaGenderList,
  validateEmail,
  validatePhoneNumber,
} from "../constants/Calendar-constants";
import { saveAs } from "file-saver";
import { getGeoCodeDetails } from "../api/ReservationApi";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  dateFnsFormat,
  getIshaProgramsAttended,
  getIshaPurposeOfVisit,
  getIshaRelationWithPrimary,
  getNationalityList,
} from "../constants/Calendar-constants";
import {
  addEditGuestUser,
  getIshaGuestConfig,
  getSelectedGuest,
  setIshaGuestConfig,
} from "../../Promocodes/apis/PromocodeApi";
import moment from "moment";
import { RiFileEditFill } from "react-icons/ri";
import CardforButtons from "./CardforButtons.js";
import i18n from "../../../modules/Auth/pages/i18n.js";
import { useTranslation } from "react-i18next";
import IDguru from "../../IDguru/IDguru.js";
import axios from "axios";
import { getAllCTA } from "../../CTA/apis/CTAApi.js";
import CommonSlider from "./CommonSlider.js";
import { Close } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
  footer: {
    margin: theme.spacing(3),
    float: "right",
  },
  displayHeading: {
    // fontSize: theme.typography.pxToRem(15),
    fontSize: "1.1rem",
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    fontWeight: "bold",
  },
  secondaryHeading: {
    // fontSize: theme.typography.pxToRem(15),
    fontSize: "1.1rem",
    fontWeight: "bold",
    color: theme.palette.text.secondary,
    paddingLeft: theme.spacing(1),
    wordBreak: "break-word",
  },
}));
const phoneRegExp =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

const NewBookingInformation = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(props.selectedLanguage);
  }, [props.selectedLanguage]);

  const validationSchema = yup.object({
    customer_name: yup
      .string(`${t("Enter your Name")}`)
      .required(`${t("Customer Name is required")}`),
    // customer_phone: yup
    //   .string()
    //   .matches(phoneRegExp, `${t("Phone number is not valid")}`),
    address: yup.string().nullable(),
    address2: yup.string().nullable(),
    zipcode: yup.string().nullable(),
    country: yup.string().nullable(),
    state: yup.string().nullable(),
    city: yup.string().nullable(),
  });

  const ishaProgramsAttended = getIshaProgramsAttended();
  const ishaRelationWithPrimary = getIshaRelationWithPrimary();
  const ishaApprovedByOverseas = getApprovedByOverseas();
  const nationalities = getNationalityList();
  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );

  const {
    reservationData,
    saveCustomer,
    saveCustomerRes,
    load,
    doNotRent,
    getDoNotRentStatus,
    setLoad,
    allGuestList,
    eventData,
    fetchGuestList,
    handleAddEventSidebar,
    setTabValue,
    setShowModal,
    setActionType,
    setShows,
    reload,
    bookingUserData,
    setInvoiceData,
    invoiceData,
    open,
    close,
    selectedEmailGuest,
  } = props;

  const [CTAData, setCTAData] = useState([]);
  function extractValidChars(str) {
    // Regular expression to allow only numbers, letters, hyphen (-), period (.), and @
    return str.replace(/[^a-zA-Z0-9@.-]/g, "");
  }

  const ishaPurposeOfVisit = getIshaPurposeOfVisit(hotel.hotelId);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [reservationId, setresId] = useState("");
  const countries = getCountriesAndStates();
  // ** Props

  useEffect(() => {
    if (eventData?.event !== undefined) {
      sessionStorage.setItem(
        "resId",
        eventData?.event?.extendedProps?.reservationId
      );
      setresId(eventData?.event?.extendedProps?.reservationId);
    } else {
      setresId(sessionStorage.getItem("resId"));
    }
  }, [eventData]);

  const [subUser, setsubUser] = useState(hotel?.accessControl?.ADMINRIGHTS);
  const [showMore, setshowMore] = useState(false);

  const classes = useStyles();
  const [oldEmail, setoldEmail] = useState(reservationData.user_email);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      customer_email: isUndefined(reservationData.user_email)
        ? ""
        : reservationData.user_email,
      customer_name: isUndefined(reservationData.user_name)
        ? ""
        : reservationData.user_name,
      customer_phone: isUndefined(reservationData.user_contact)
        ? ""
        : reservationData.user_contact.replace(/[\s-]/g, ""),
      customer_gender: isUndefined(reservationData.user_gender)
        ? "Male"
        : reservationData.user_gender,
      customer_category: isUndefined(reservationData?.user_category)
        ? "ADULT"
        : reservationData?.user_category,
      customer_dob: isUndefined(reservationData.user_dob)
        ? ""
        : reservationData.user_dob == "" || reservationData.user_dob == "NA"
        ? ""
        : new Date(reservationData.user_dob),
      nationality: isUndefined(reservationData.nationality)
        ? ""
        : reservationData.nationality,
      occupation: isUndefined(reservationData.occupation)
        ? ""
        : reservationData.occupation,
      address: isUndefined(reservationData.address1)
        ? ""
        : reservationData.address1,
      address2: isUndefined(reservationData.address2)
        ? ""
        : reservationData.address2,
      zipcode: isUndefined(reservationData.zipcode)
        ? ""
        : reservationData.zipcode,
      state: isUndefined(reservationData.state)
        ? ""
        : reservationData.state === "NA" ||
          reservationData.state === "null" ||
          reservationData.state == ""
        ? null
        : reservationData.state,
      city: isUndefined(reservationData.city) ? "" : reservationData.city,
      country: isUndefined(reservationData.country)
        ? ""
        : reservationData.country === "NA" ||
          reservationData.country === "null" ||
          reservationData.country == ""
        ? null
        : reservationData.country,
      company_name: isUndefined(reservationData.company_name)
        ? ""
        : reservationData.company_name,
      eventType: isUndefined(reservationData.eventType)
        ? ""
        : reservationData.eventType,
      guestPrograms: isUndefined(reservationData.programs_attended)
        ? "No"
        : reservationData.programs_attended,
      purposeOfVisit: isUndefined(reservationData.purpose_of_visit)
        ? "-"
        : reservationData.purpose_of_visit,
      relationship_with_primary: isUndefined(
        reservationData.relationship_with_primary
      )
        ? "-"
        : reservationData.relationship_with_primary === "SELF"
        ? "-"
        : ishaRelationWithPrimary.indexOf(
            reservationData.relationship_with_primary
          ) === -1
        ? "Other"
        : reservationData.relationship_with_primary,
      other_relationship_with_primary: isUndefined(
        reservationData?.relationship_with_primary
      )
        ? ""
        : reservationData.relationship_with_primary === "SELF"
        ? ""
        : ishaRelationWithPrimary.indexOf(
            reservationData.relationship_with_primary
          ) === -1
        ? reservationData.relationship_with_primary
        : "",
      emergency_contact: isUndefined(reservationData?.emergency_contact)
        ? ""
        : reservationData?.emergency_contact,
      emergency_contact_name: isUndefined(
        reservationData?.emergency_contact_name
      )
        ? ""
        : reservationData?.emergency_contact_name,
      customer_eta:
        isUndefined(reservationData?.customer_eta) ||
        reservationData?.customer_eta == ""
          ? currentDate
          : new Date(
              moment(
                reservationData?.customer_eta,
                "DD-MM-YYYY HH:mm:ss",
                false
              ).format("YYYY-MM-DDTHH:mm:ssZ")
            ),
      customer_etd:
        isUndefined(reservationData?.customer_etd) ||
        reservationData?.customer_etd == ""
          ? currentDate
          : new Date(
              moment(
                reservationData?.customer_etd,
                "DD-MM-YYYY HH:mm:ss",
                false
              ).format("YYYY-MM-DDTHH:mm:ssZ")
            ),
      ocoCheckinComplete: isUndefined(reservationData?.ocoCheckinComplete)
        ? "No"
        : reservationData?.ocoCheckinComplete,
      approvedByOverseas: isUndefined(reservationData?.approvedByOverseas)
        ? "New"
        : reservationData?.approvedByOverseas,
      medicalCondition: isUndefined(reservationData?.medicalCondition)
        ? "No"
        : reservationData?.medicalCondition,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const ctaResData = CTAData?.filter(
        (item) => item.cta_email === values.customer_email
      );

      if (ctaResData.length >= 1) {
        return toast.error(
          "Please enter different email id this email is already linked with direct billing account"
        );
      }
      let resId = "";
      let roomId = "";
      let primaryResOwner = "NO";
      allGuestList.map((guestData) => {
        if (
          String(guestData?.email).toLowerCase() ==
          String(oldEmail).toLowerCase()
        ) {
          resId = guestData?.reservationId;
          roomId = guestData?.roomId;
          primaryResOwner = guestData?.primaryGuest;
        }
      });
      if (checkIshaUSAccess(hotel?.emailId) && values.customer_gender == "-") {
        toast.error(`${t("Please select customer gender")}`);
        return;
      }
      if (checkIshaUSAccess(hotel?.emailId) && values.purposeOfVisit == "-") {
        toast.error(`${t("Please select a purpose of visit!")}`);
        return;
      }
      if (String(values.customer_name).trim() === "") {
        toast.error(`${t("Please enter a customer name!")}`);
        return;
      }
      if (values.customer_email == "") {
        let customer_name = values.customer_name;
        let dummy_email =
          customer_name.replace(/\s+/g, ".").toLowerCase() +
          hotel.hotelId +
          Math.round(Date.now() / 1000) +
          "@example.com";
        values.customer_email = dummy_email;
      }
      let tempGuestDOB = "";
      if (values.customer_dob != "" && values.customer_dob != "NA") {
        tempGuestDOB = format(values.customer_dob, "yyyy-MM-dd");
      }
      let guestRelation = values.relationship_with_primary;
      if (values.relationship_with_primary === "Other") {
        guestRelation = values.other_relationship_with_primary;
      }
      let temporary = {
        userName: values.customer_name?.trim(),
        address: values.address,
        address1: values.address2,
        city: values.city,
        state: values.state,
        country: values.country,
        phoneNumber: values.customer_phone?.trim(),
        zipCode: values.zipcode,
        email: extractValidChars(values.customer_email?.trim()),
        govtIdType: govIdType,
        govtIdValue: govtIdValue,
        dateOfBirth: tempGuestDOB,
        gender: values.customer_gender,
        nationality: values.nationality,
        occupation: values.occupation,
        purpose_of_visit: values.purposeOfVisit,
        event_type: values.eventType,
        company_name: values.company_name,
        programs_attended: values.guestPrograms,
        relationship_with_primary: guestRelation,
        emergency_contact: values.emergency_contact,
        emergency_contact_name: values.emergency_contact_name,
        old_email: oldEmail,
        guest_category: values.customer_category,
        reservationId: resId,
        roomId: roomId,
      };
      if (reservationData?.user_email == oldEmail) {
        if (updateChecked) {
          saveCustomer(
            temporary,
            reservationData.booking_id,
            values.customer_name,
            invoiceData?.invoice.res_id_list
          );
        }
        if (updateChecked || bookingUserData?.email == oldEmail) {
          saveCustomer(
            temporary,
            reservationData.booking_id,
            values.customer_name,
            allGuestList.filter((item) => item.email === selectedGuestEmail)[0]
              .reservationId
          );
        } else {
          saveCustomerRes(temporary, reservationId, values.customer_name);
        }
        if (
          JSON.parse(
            hotel?.accessControl?.user_feature_map?.IshaExtraGuestDetails
          ).read === "YES" &&
          (checkIshaAccess(hotel?.emailId) || checkIshaUSAccess(hotel?.emailId))
        ) {
          let guestRelation = values.relationship_with_primary;
          if (values.relationship_with_primary === "Other") {
            guestRelation = values.other_relationship_with_primary;
          }
          const ishaPayload = {
            userEmail: temporary?.email,
            userPhone: temporary?.phoneNumber,
            purposeOfVisit: values.purposeOfVisit,
            programsAttended: selectedPrograms?.toString(),
            relationshipWithPrimary: guestRelation,
            userCompany: values.company_name,
            customerETA: moment(values.customer_eta).format(
              "DD-MM-YYYY HH:mm:ss"
            ),
            customerETD: moment(values.customer_etd).format(
              "DD-MM-YYYY HH:mm:ss"
            ),
            ocoCheckinComplete: values.ocoCheckinComplete,
            approvedByOverseas: values.approvedByOverseas,
            medicalCondition: values.medicalCondition,
            eventType: values.eventType,
          };
          await setIshaGuestConfig(ishaPayload);
        }
      } else {
        setLoad(true);
        const payload = {
          bookingId: reservationData.booking_id,
          name: values.customer_name?.trim(),
          contact: values.customer_phone?.trim(),
          email: extractValidChars(values.customer_email?.trim()),
          address1: values.address,
          address2: values.address2,
          city: values.city,
          state: values.state,
          country: values.country,
          zipcode: values.zipcode,
          govtIdType: govIdType,
          govtIdValue: govtIdValue,
          roomId: roomId,
          hotelId: hotel?.hotelId,
          nationality: values.nationality,
          occupation: values.occupation,
          gender: values.customer_gender,
          dateOfBirth: tempGuestDOB,
          oldEmail: oldEmail,
          guestCategory: values.customer_category,
          reservationId: resId,
        };
        await addEditGuestUser(payload);
        if (
          JSON.parse(
            hotel?.accessControl?.user_feature_map?.IshaExtraGuestDetails
          ).read === "YES" &&
          (checkIshaAccess(hotel?.emailId) || checkIshaUSAccess(hotel?.emailId))
        ) {
          let guestRelation = values.relationship_with_primary;
          if (values.relationship_with_primary === "Other") {
            guestRelation = values.other_relationship_with_primary;
          }
          const ishaPayload = {
            userEmail: payload?.email,
            userPhone: payload?.contact,
            purposeOfVisit: values.purposeOfVisit,
            programsAttended: selectedPrograms?.toString(),
            relationshipWithPrimary: guestRelation,
            userCompany: values.company_name,
            customerETA: moment(values.customer_eta).format(
              "DD-MM-YYYY HH:mm:ss"
            ),
            customerETD: moment(values.customer_etd).format(
              "DD-MM-YYYY HH:mm:ss"
            ),
            ocoCheckinComplete: values.ocoCheckinComplete,
            approvedByOverseas: values.approvedByOverseas,
            medicalCondition: values.medicalCondition,
            eventType: values.eventType,
          };
          await setIshaGuestConfig(ishaPayload);
        }

        if (!updateChecked && primaryResOwner === "YES") {
          saveCustomerRes(temporary, resId, values.customer_name);
        }
        setLoad(false);
      }

      if (oldEmail !== extractValidChars(values.customer_email)) {
        await imageUpdateByEmail(
          oldEmail,
          extractValidChars(values.customer_email)
        );
      }

      const userIdPayload = {
        emailId: extractValidChars(values.customer_email),
        frontData:
          image !== undefined
            ? image.split(",")[1]
            : images[0]
            ? images[0].data_url?.split(",")[1]
            : null,
        frontType: backImage !== undefined ? "jpeg" : images[0] ? "jpeg" : null,
        backData:
          backImage !== undefined
            ? backImage.split(",")[1]
            : faviconImages[0]
            ? faviconImages[0].data_url?.split(",")[1]
            : null,
        backType:
          backImage !== undefined ? "jpeg" : faviconImages[0] ? "jpeg" : null,
        passportImageData:
          passportImage !== undefined
            ? passportImage.split(",")[1]
            : passportImages[0]
            ? passportImages[0].data_url?.split(",")[1]
            : null,
        passportImageType:
          passportImage !== undefined
            ? "jpeg"
            : passportImages[0]
            ? "jpeg"
            : null,
        hotelId: hotel.hotelId,
      };
      if (
        userIdPayload?.frontData !== undefined ||
        userIdPayload?.backData !== undefined ||
        userIdPayload?.passportImageData !== undefined
      ) {
        if (values.customer_email === "") {
          toast.error(`${t("Please enter email !")}`);
        } else {
          if (
            userIdPayload.frontData !== null ||
            userIdPayload.backData !== null ||
            userIdPayload.passportImageData !== null
          ) {
            await setBookingUserIdImages(userIdPayload);
          }
        }
      }
      if (scanIdState === "true") {
        scanIdpayload.emailId = extractValidChars(values.customer_email);
        if (
          userIdPayload.frontData !== null ||
          userIdPayload.backData !== null ||
          userIdPayload.passportImageData !== null
        ) {
          const data = await setBookingUserIdImages(scanIdpayload);

          if (data === "Failed to upload new image") {
            toast.error(`${t("Something went wrong")}`);
          }
        }
        await getUserId(values.customer_email);
      }
      //IDGURU
      if (
        hotel?.accessControl?.hotel?.enable_id_scanner === "IDGURU" &&
        Object.entries(selectedUserIdGuruData).length > 0
      ) {
        handleSpringDataMutationRequest(
          `PUT`,
          `core/api/v1/idguru/mark-id-used?id=${selectedUserIdGuruData.id}`
        )
          .then((res) => console.log("res", res))
          .catch((err) => console.error(err.response.data));
      }
      //update guest documents
      setDocumentData(values.customer_email);
      setscanIdState("false");
      toast.success(`${t("Successfully updated customer information")}`);
      fetchGuestList();
      setTabValue("");
      handleAddEventSidebar();
      setActionType("");
      setselectedUserIdGuruData({});
      setShows("");
      setShowModal(null);
      setLoad(false);
    },
  });

  //Setting the date of birth selection in formik(
  const setDateOfBirthSelection = (dob, isTime) => {
    try {
      formik.setFieldValue("customer_dob", dob);
    } catch (e) {
      console.log(e);
    }
  };

  const [selectedPrograms, setSelectedPrograms] = useState(["No"]);
  const handleProgramChange = (event) => {
    const {
      target: { value },
    } = event;
    let attendedPrograms = typeof value === "string" ? value.split(",") : value;
    setSelectedPrograms(attendedPrograms);
    formik.setFieldValue("guestPrograms", attendedPrograms.toString());
  };

  useEffect(() => {
    let value = reservationData.programs_attended;
    let attendedPrograms = typeof value === "string" ? value.split(",") : value;
    if (attendedPrograms?.length > 1) {
      setSelectedPrograms(attendedPrograms);
    }
  }, []);

  //Add attachments
  const [document1, setDocument1] = useState("");
  const [document2, setDocument2] = useState("");
  const handleSetDocumentState = (name, docType, base64) => {
    switch (name) {
      case "document1":
        setDocument1({ data: base64, type: docType });
        break;
      case "document2":
        setDocument2({ data: base64, type: docType });
        break;
      default:
      // code block
    }
  };

  //Early checkout data
  const [showEarlyCheckout, setShowEarlyCheckout] = useState(false);
  const openEarlyCheckoutModal = () => setShowEarlyCheckout(true);
  const closeEarlyCheckoutModal = () => setShowEarlyCheckout(false);
  const [newCheckoutDate, setNewCheckoutDate] = useState(new Date());
  const [earlyCheckoutLoader, setEarlyCheckoutLoader] = useState(false);

  //Converting documents to base64
  const convertToBase64 = (eve) => {
    const file = eve.target.files[0];
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    })
      .then((data) => {
        let type = data.split("/")[1].split(";")[0];
        if (type === "pdf") {
          type = "application/pdf";
        } else {
          type = "image/" + type;
        }
        const base64 = data.split(",")[1];
        handleSetDocumentState(eve.target.name, type, base64);
      })
      .catch((err) => console.log("error caught : ", err));
  };

  // Create a reference to the hidden file input element
  const hiddenFileInput1 = React.useRef(null);
  const hiddenFileInput2 = React.useRef(null);

  //Get selcted guest info
  const [selectedGuestEmail, setSelectedGuestEmail] =
    useState(selectedEmailGuest);

  useEffect(() => {
    handleGuestChange(selectedEmailGuest);
    setSelectedGuestEmail(selectedEmailGuest);
  }, [selectedEmailGuest]);

  const [selectedGuestInfo, setselectedGuestInfo] = useState(null);
  const [selectedIshaConfig, setSelectedIshaConfig] = useState(null);

  const getAllSelectedUsersData = async (email) => {
    try {
      const data = await getSelectedGuest(email, reservationData.booking_id);
      setselectedGuestInfo(data);
    } catch (e) {
      toast.error(e);
    }
  };

  const getIshaGuestConfigData = async (email) => {
    try {
      const data = await getIshaGuestConfig(email);

      setSelectedIshaConfig(data);
    } catch (e) {
      toast.error(e);
    }
  };

  //ETA, ETD, OCO Checkin
  const [ETA, setETA] = useState(new Date());
  const [ETD, setETD] = useState(new Date());

  const handleETAChange = (e) => {
    setETA(e);
    formik.setFieldValue("customer_eta", e);
  };

  const handleETDChange = (e) => {
    setETD(e);
    formik.setFieldValue("customer_etd", e);
  };

  // useEffect(() => {
  //   console.log("customer_Eta: ", formik.values.customer_eta);
  // }, [formik.values.customer_eta, formik.values.customer_etd]);
  //When a guest is set

  useEffect(() => {
    if (selectedGuestInfo !== null) {
      formik.setFieldValue("customer_email", selectedGuestInfo?.email);
      setoldEmail(selectedGuestInfo?.email);
      formik.setFieldValue("customer_name", selectedGuestInfo?.name);
      formik.setFieldValue(
        "customer_phone",
        selectedGuestInfo?.contact.replace(/[\s-]/g, "")
      );
      setenabledPhone(
        JSON.parse(
          hotel?.accessControl?.user_feature_map
            ?.CustomerInformationCaptureAndDisplay
        ).read === "YES" &&
          selectedGuestInfo?.contact !== "0000000000" &&
          selectedGuestInfo?.contact !== ""
      );
      if (selectedGuestInfo?.gender && selectedGuestInfo?.gender !== "NA") {
        formik.setFieldValue("customer_gender", selectedGuestInfo?.gender);
      } else {
        formik.setFieldValue("customer_gender", "-");
      }
      let tempDob = "";
      if (
        selectedGuestInfo?.dateOfBirth != "" &&
        selectedGuestInfo?.dateOfBirth != "NA"
      ) {
        tempDob = new Date(selectedGuestInfo?.dateOfBirth);
      }
      formik.setFieldValue(
        "customer_category",
        selectedGuestInfo?.guestCategory
          ? selectedGuestInfo?.guestCategory
          : "ADULT"
      );
      formik.setFieldValue("customer_dob", tempDob);
      formik.setFieldValue("nationality", selectedGuestInfo?.nationality);
      formik.setFieldValue("occupation", selectedGuestInfo?.occupation);
      formik.setFieldValue("address", selectedGuestInfo?.address1);
      formik.setFieldValue("address2", selectedGuestInfo?.address2);
      formik.setFieldValue("zipcode", selectedGuestInfo?.zipcode);
      let tempState = "";
      if (selectedGuestInfo?.state) {
        if (
          selectedGuestInfo?.state === "NA" ||
          selectedGuestInfo?.state === "null"
        ) {
          tempState = null;
        } else {
          tempState = selectedGuestInfo?.state;
        }
      }
      formik.setFieldValue("state", tempState);
      formik.setFieldValue("city", selectedGuestInfo?.city);
      let tempCountry = "";
      if (selectedGuestInfo?.country) {
        if (
          selectedGuestInfo?.country === "NA" ||
          selectedGuestInfo?.country === "null"
        ) {
          tempCountry = null;
        } else {
          tempCountry = selectedGuestInfo?.country;
        }
      }
      formik.setFieldValue("country", tempCountry);
      if (selectedGuestInfo?.govtIdType == "NA") {
        setGovIdType("Others");
      } else {
        setGovIdType(selectedGuestInfo?.govtIdType);
      }

      setGovtIdValue(selectedGuestInfo?.govtIdValue);
    }
    if (
      selectedIshaConfig !== null &&
      JSON.parse(hotel?.accessControl?.user_feature_map?.IshaExtraGuestDetails)
        .read === "YES" &&
      (checkIshaAccess(hotel?.emailId) || checkIshaUSAccess(hotel?.emailId))
    ) {
      formik.setFieldValue(
        "medicalCondition",
        selectedIshaConfig?.medicalCondition
      );
      formik.setFieldValue(
        "purposeOfVisit",
        selectedIshaConfig?.purposeOfVisit
      );
      formik.setFieldValue("eventType", selectedIshaConfig?.eventType);
      formik.setFieldValue("company_name", selectedIshaConfig?.userCompany);
      if (selectedIshaConfig?.relationshipWithPrimary) {
        if (selectedIshaConfig?.relationshipWithPrimary == "SELF") {
          formik.setFieldValue("relationship_with_primary", "-");
        } else {
          if (
            ishaRelationWithPrimary.indexOf(
              selectedIshaConfig?.relationshipWithPrimary
            ) === -1
          ) {
            formik.setFieldValue("relationship_with_primary", "Other");
            formik.setFieldValue(
              "other_relationship_with_primary",
              selectedIshaConfig?.relationshipWithPrimary
            );
          } else {
            formik.setFieldValue(
              "relationship_with_primary",
              selectedIshaConfig?.relationshipWithPrimary
            );
            formik.setFieldValue("other_relationship_with_primary", "");
          }
        }
      }
      let valueFound = selectedIshaConfig?.programsAttended;
      let attendedPrograms =
        typeof valueFound === "string" ? valueFound.split(",") : valueFound;
      if (attendedPrograms?.length > 0) {
        setSelectedPrograms(attendedPrograms);
        formik.setFieldValue("guestPrograms", attendedPrograms.toString());
      }
      //Customer eta
      if (selectedIshaConfig?.customerETA == "") {
        formik.setFieldValue("customer_eta", new Date());
      } else {
        formik.setFieldValue(
          "customer_eta",
          new Date(selectedIshaConfig?.customerETA)
        );
      }
      //Customer etd
      if (selectedIshaConfig?.customerETD == "") {
        formik.setFieldValue("customer_etd", new Date());
      } else {
        formik.setFieldValue(
          "customer_etd",
          new Date(selectedIshaConfig?.customerETD)
        );
      }
      //OCO check in
      formik.setFieldValue(
        "ocoCheckinComplete",
        selectedIshaConfig?.ocoCheckinComplete
      );
      //approved by overseas
      formik.setFieldValue(
        "approvedByOverseas",
        selectedIshaConfig?.approvedByOverseas
      );
    }
  }, [selectedGuestInfo, selectedIshaConfig]);

  const getAllGuestListEmail = (name) => {
    if (allGuestList?.length > 0) {
      for (let i = 0; i < allGuestList?.length; i++) {
        if (allGuestList[i]?.name == name) {
          return allGuestList[i]?.email;
        }
      }
      return null;
    }
    return null;
  };

  const getAllGuestListName = (email) => {
    if (allGuestList?.length > 0) {
      allGuestList.map((guestData) => {
        if (guestData?.email === email) {
          return guestData?.name;
        }
      });
    }
    return null;
  };

  const handleGuestChange = async (guestEmail) => {
    // const guestEmail = e.target.value;
    const guestName = getAllGuestListName(guestEmail);
    setSelectedGuestEmail(guestEmail);
    await getDoNotRentStatus(guestEmail);
    if (guestEmail !== null) {
      await getAllSelectedUsersData(guestEmail);
      if (
        JSON.parse(
          hotel?.accessControl?.user_feature_map?.IshaExtraGuestDetails
        ).read === "YES" &&
        (checkIshaAccess(hotel?.emailId) || checkIshaUSAccess(hotel?.emailId))
      ) {
        await getIshaGuestConfigData(guestEmail);
      }
      await getAllGuestData(guestEmail);
      await getAllGuestNotes(guestEmail);
    }
  };
  const [ScannerData, setScannerData] = useState();
  const [loading, setloading] = useState(false);
  const getScannerUrl = () => {
    if (
      `${process.env.REACT_APP_ENV}` === "BETA" ||
      `${process.env.REACT_APP_ENV}` === "LOCAL"
    ) {
      return "https://text.motelpro.com/ScanDocDemo?images=front+back";
    } else if (`${process.env.REACT_APP_ENV}` === "PROD") {
      return "http://127.0.0.1:55080/scandoc?images=front+back";
    } else {
      return "http://127.0.0.1:55080/scandoc?images=front+back";
    }
  };

  const [showUserIdImages, setshowUserIdImages] = useState("0");

  //ID  Guru
  const [idGuruModal, setidGuruModal] = useState(false);
  const [selectedUserIdGuruData, setselectedUserIdGuruData] = useState({});
  const handleOpenIdGuruModal = () => {
    setidGuruModal(true);
  };
  const handleCloseIdGuruModal = () => {
    setidGuruModal(false);
  };

  useEffect(() => {
    if (
      Object.entries(selectedUserIdGuruData).length > 0 &&
      idGuruModal === false
    ) {
      scannedIdGuruData();
    }
  }, [selectedUserIdGuruData, idGuruModal]);

  const getIdValue = (type) => {
    if (type === "DrivingLicense") {
      return "Drivers License";
    } else if (type === "Passport") {
      return "Passport";
    } else {
      return "Others";
    }
  };

  const scannedIdGuruData = () => {
    setimageUpload("Device");

    setImageFront(selectedUserIdGuruData?.data?.idFrontImage_Base64);
    setImageBack(selectedUserIdGuruData?.data?.idBackImage_Base64);
    setshowUserIdImages("1");

    formik.setFieldValue("state", selectedUserIdGuruData?.data?.state);
    // formik.setFieldValue("country", data?.apiresponse?.docinfo?.country);
    formik.setFieldValue("zipcode", selectedUserIdGuruData?.data?.zipCode);
    formik.setFieldValue("city", selectedUserIdGuruData?.data?.city);
    formik.setFieldValue(
      "address",
      selectedUserIdGuruData?.data?.streetAddress
    );
    formik.setFieldValue("country", "United States");
    setGovIdType(getIdValue(selectedUserIdGuruData?.data?.idType));
    setGovtIdValue(selectedUserIdGuruData?.data?.idNumber);

    let customer_name =
      selectedUserIdGuruData.data.firstName +
      " " +
      selectedUserIdGuruData.data.middleName +
      " " +
      selectedUserIdGuruData.data.lastName;

    formik.setFieldValue("customer_name", customer_name);
    let dummy_email =
      customer_name.replace(/\s+/g, ".").toLowerCase() +
      hotel.hotelId +
      Math.round(Date.now() / 1000) +
      "@example.com";
    formik.setFieldValue(
      "customer_email",
      selectedUserIdGuruData?.data?.emailAddress
    );

    formik.setFieldValue(
      "customer_phone",
      selectedUserIdGuruData?.data?.phoneCountryCode +
        selectedUserIdGuruData?.data?.phoneAreaCode +
        selectedUserIdGuruData?.data?.phoneNumber
    );

    const userIdPayload = {
      emailId: "",
      frontData: selectedUserIdGuruData?.data?.idFrontImage_Base64,
      frontType: "png",
      backData: selectedUserIdGuruData?.data?.idBackImage_Base64,
      backType: "png",
      hotelId: hotel.hotelId,
    };

    setscanIdpayload(userIdPayload);
    setscanIdState("true");
    setImages(
      selectedUserIdGuruData?.data?.idFrontImage_Base64
        ? [
            {
              data_url: `data:image/png;base64,${selectedUserIdGuruData?.data?.idFrontImage_Base64}`,
              file: {
                type: "png",
              },
            },
          ]
        : []
    );
    setFaviconImages(
      selectedUserIdGuruData?.data?.idBackImage_Base64
        ? [
            {
              data_url: `data:image/png;base64,${selectedUserIdGuruData?.data?.idBackImage_Base64}`,
              file: {
                type: "png",
              },
            },
          ]
        : []
    );
  };

  const ScannedData = () => {
    setimageUpload("Device");
    const URL = getScannerUrl();
    setloader(true);
    const ssd = fetch(URL)
      .then((res) => res.json())
      .then((resp) => {
        setloader(false);
        const data = resp;
        setScannerData(data?.apiresponse);
        setImageFront(data?.apiresponse?.imgfront);
        setImageBack(data?.apiresponse?.imgback);
        setshowUserIdImages("1");
        if (data?.apiresponse?.errormsg?.cd === 0) {
          formik.setFieldValue("state", data?.apiresponse?.docinfo?.state);
          // formik.setFieldValue("country", data?.apiresponse?.docinfo?.country);
          formik.setFieldValue("zipcode", data?.apiresponse?.docinfo?.zipcode);
          formik.setFieldValue("city", data?.apiresponse?.docinfo?.city);
          formik.setFieldValue("address", data?.apiresponse?.docinfo?.addr1);
          formik.setFieldValue("country", "United States");
          setGovIdType("Drivers License");
          setGovtIdValue(data?.apiresponse?.docinfo?.idno);

          let customer_name = "";
          if (data?.apiresponse?.docinfo?.fullname) {
            customer_name = data?.apiresponse?.docinfo?.fullname;
          } else {
            customer_name =
              data?.apiresponse?.docinfo?.fname +
              data?.apiresponse?.docinfo?.lname;
          }
          formik.setFieldValue("customer_name", customer_name);
          let dummy_email =
            customer_name.replace(/\s+/g, ".").toLowerCase() +
            hotel.hotelId +
            Math.round(Date.now() / 1000) +
            "@example.com";
          // formik.setFieldValue("customer_email", "");
          const userIdPayload = {
            emailId: "",
            frontData: data?.apiresponse?.imgfront,
            frontType: "png",
            backData: data?.apiresponse?.imgback,
            backType: "png",
            hotelId: hotel.hotelId,
          };

          setscanIdpayload(userIdPayload);
          setscanIdState("true");
          setImages(
            data?.apiresponse?.imgfront
              ? [
                  {
                    data_url: `data:image/png;base64,${data?.apiresponse?.imgfront}`,
                    file: {
                      type: "png",
                    },
                  },
                ]
              : []
          );
          setFaviconImages(
            data?.apiresponse?.imgback
              ? [
                  {
                    data_url: `data:image/png;base64,${data?.apiresponse?.imgback}`,
                    file: {
                      type: "png",
                    },
                  },
                ]
              : []
          );
          // setImagesData({
          //   frontUrl: `data:image/png;base64,${data?.apiresponse?.imgfront}`,
          //   backUrl: `data:image/png;base64,${data?.apiresponse?.imgback}`,
          // });
        } else {
          toast.error(
            data?.apiresponse?.errormsg?.txt === ""
              ? `${t(
                  "No data has been populated from scanner, kindly enter the customer information. Images has been populated"
                )}`
              : data?.apiresponse?.errormsg?.txt
          );
          setImages(
            data?.apiresponse?.imgfront
              ? [
                  {
                    data_url: `data:image/png;base64,${data?.apiresponse?.imgfront}`,
                    file: {
                      type: "png",
                    },
                  },
                ]
              : []
          );
          setFaviconImages(
            data?.apiresponse?.imgback
              ? [
                  {
                    data_url: `data:image/png;base64,${data?.apiresponse?.imgback}`,
                    file: {
                      type: "png",
                    },
                  },
                ]
              : []
          );
        }
      })
      .catch((err) => {
        console.log("api data err : ", err);
        setloader(false);
      });
  };

  const [scanIdState, setscanIdState] = useState("false");
  const [scanIdpayload, setscanIdpayload] = useState({});
  const [ImagesData, setImagesData] = useState();
  const [imageFront, setImageFront] = useState();
  const [imageBack, setImageBack] = useState();
  const [showFrontImagePopup, setshowFrontImagePopup] = useState(false);
  const [showPopUpImages, setshowPopUpImages] = useState("");

  const [doc1Url, setDoc1Url] = useState("");
  const [doc2Url, setDoc2Url] = useState("");
  const [docLoader, setDocLoader] = useState(false);
  const handleshowFrontImagePopupClose = () => setshowFrontImagePopup(false);
  const handleFrontImagePopupShow = (data) => {
    setshowPopUpImages(data);
    setshowFrontImagePopup(true);
  };

  const genderList = getGenderList();
  const ishaGenderList = getIshaGenderList(hotel.hotelId);

  useEffect(async () => {
    await getAllData();
  }, []);

  const getAllData = async () => {
    try {
      setDocLoader(true);
      const data = await getUserId(reservationData.user_email);
      setimageUpload(
        data?.frontUrl === "" && data?.backUrl === "" ? "Device" : "Camera"
      );
      setPassportImageUpload(
        data?.passportImageUrl === "" ? "Device" : "Camera"
      );
      data?.passportImageUrl !== "" &&
        setPassportImages([{ data_url: data?.passportImageUrl }]);
      data.frontUrl !== "" && setImages([{ data_url: data.frontUrl }]);
      data.backUrl !== "" && setFaviconImages([{ data_url: data.backUrl }]);
      // setImagesData(data);

      const docData = await getIshaDocuments(reservationData?.user_email).then(
        (data) => {
          if (data?.docOneUrl != "") {
            setDoc1Url(data?.docOneUrl);
          }
          if (data?.docTwoUrl != "") {
            setDoc2Url(data?.docTwoUrl);
          }
          setDocLoader(false);
        }
      );
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    // console.log("doc loader: ", docLoader);
  }, [docLoader]);
  const getAllGuestData = async (email) => {
    try {
      setDocLoader(true);
      setImages([]);
      setFaviconImages([]);
      const data = await getUserId(email);
      setimageUpload(data?.frontUrl === "" ? "Device" : "Camera");
      setPassportImageUpload(
        data?.passportImageUrl === "" ? "Device" : "Camera"
      );
      data?.passportImageUrl !== "" &&
        setPassportImages([{ data_url: data?.passportImageUrl }]);
      data.frontUrl !== "" && setImages([{ data_url: data.frontUrl }]);
      data.backUrl !== "" && setFaviconImages([{ data_url: data.backUrl }]);
      setDoc1Url("");
      setDoc2Url("");
      setDocument1(null);
      setDocument2(null);
      let image1Name = document.getElementById("document1Name");
      let image2Name = document.getElementById("document2Name");
      image1Name.innerText = "";
      image2Name.innerText = "";
      const docData = await getIshaDocuments(email).then((data) => {
        if (data?.docOneUrl != "") {
          setDoc1Url(data?.docOneUrl);
        }
        if (data?.docTwoUrl != "") {
          setDoc2Url(data?.docTwoUrl);
        }
        setDocLoader(false);
      });
      // setImagesData(data);
    } catch (e) {
      console.error(e);
    }
  };

  //Customer Notes
  const [customerNotes, setcustomerNotes] = useState([]);
  const [loader, setloader] = useState(false);
  const [customerNoteData, setcustomerNoteData] = useState("");
  const [showUserIdImagesback, setshowUserIdImagesback] = useState(0);

  useEffect(async () => {
    await getAllCustomerData();
  }, []);

  const getAllCustomerData = async () => {
    try {
      const data = await getAllCustomerNotes(reservationData.user_email);
      setcustomerNotes(data);
    } catch (e) {
      console.error(e);
    }
  };

  //Setting guest customer Notes
  const getAllGuestNotes = async (email) => {
    try {
      const data = await getAllCustomerNotes(email);
      setcustomerNotes(data);
    } catch (e) {
      console.error(e);
    }
  };

  const setDocumentData = async (email) => {
    let document_payload = {
      emailId: email,
      hotelId: hotel.hotelId,
    };
    if (document1?.data) {
      document_payload["attachmentOne"] = {
        base64Data: document1?.data,
        dataType: document1?.type,
      };
    }
    if (document2?.data) {
      document_payload["attachmentTwo"] = {
        base64Data: document2?.data,
        dataType: document2?.type,
      };
    }
    if (document_payload?.attachmentOne || document_payload?.attachmentTwo) {
      if (
        JSON.parse(
          hotel?.accessControl?.user_feature_map?.IshaExtraGuestDetails
        ).read === "YES" &&
        (checkIshaAccess(hotel?.emailId) || checkIshaUSAccess(hotel?.emailId))
      ) {
        await setIshaGuestDocument(document_payload);
      }
    }
  };

  const createCustomerNote = async () => {
    setloading(true);
    const payload = {
      hotelId: hotel.hotelId,
      cust_email: oldEmail,
      note: customerNoteData,
      do_not_rent: checked,
      bookingId: reservationData.booking_id,
      logged_in_user: localStorage.getItem("email"),
    };
    const data = await addNewCustomerNote(payload);
    setloading(false);
    setChecked(false);
    await getAllCustomerData();
    handleClose();
  };

  const createDoNotRentStatus = async () => {
    setloading(true);
    const payload = {
      hotelId: hotel.hotelId,
      emailId: oldEmail,
      severity: Severity,
    };
    const data = await addNewDoNotRentStatus(payload);
    setloading(false);
    if (data.status === 200) {
      toast.success(`${t("Successfully added Do-Not rent status")}`);
    } else {
      toast.error(`${t("Something went wrong")}`);
    }
    setChecked(false);
    await getDoNotRentStatus(oldEmail);
    handleDClose();
  };

  const removeDoNotRentStatus = async () => {
    setloading(true);
    const payload = {
      hotelId: hotel.hotelId,
      emailId: oldEmail,
      status: "NO",
      severity: Severity,
    };
    const data = await removeNewDoNotRentStatus(payload);
    setloading(false);
    if (data.status === 200) {
      toast.success(`${t("Successfully removed Do-Not rent status")}`);
    } else {
      toast.error(`${t("Something went wrong")}`);
    }
    setChecked(false);
    await getDoNotRentStatus(oldEmail);
    handleRClose();
  };

  const deleteCustomerNote = async (id, notes) => {
    const data = await deleteTheCustomerNotes(
      oldEmail,
      id,
      reservationData.booking_id,
      localStorage.getItem("email"),
      notes
    );

    if (data.message === "Customer note deleted") {
      toast.success(`${t("Successfully deleted the customer note")}`);
    } else {
      toast.error(`${t("Something went wrong")}`);
    }
    getAllCustomerData();
  };

  const deleteNoteInModal = async (id) => {
    handleDeleteShow(id);
  };

  const deletCustomerNotes = async () => {
    const data = await deleteTheCustomerNotes(
      oldEmail,
      deleteId,
      localStorage.getItem("email"),
      customerNoteData
    );
    if (data.message === "Customer note deleted") {
      toast.success(`${t("Successfully deleted the customer note")}`);
    } else {
      toast.error(`${t("Something went wrong")}`);
    }
    getAllCustomerData();
    handleDeleteClose();
  };

  //Add Booking Note Modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //Add Do Not rent
  const [dshow, setDShow] = useState(false);

  const handleDClose = () => setDShow(false);
  const handleDShow = () => setDShow(true);

  //Add Do Not rent
  const [rshow, setRShow] = useState(false);

  const handleRClose = () => setRShow(false);
  const handleRShow = () => setRShow(true);

  //Add delete Note Modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteClose = () => setShowDeleteModal(false);
  const handleDeleteShow = (id) => {
    setdeleteId(id);
    setShowDeleteModal(true);
  };
  const [deleteId, setdeleteId] = useState("");

  /* Predefining Government Id types */
  // Goverment IDs --- US properties
  const [govIdType, setGovIdType] = useState(reservationData?.govt_id_type);
  const [govtIdValue, setGovtIdValue] = useState(
    reservationData?.govt_id_value
  );

  const govIdUS = [
    `${t("Passport")}`,
    `${t("Drivers License")}`,
    `${t("Others")}`,
  ];

  // Government IDs --- Indian properties
  const govIdIndia = [
    `${t("Passport")}`,
    `${t("Drivers License")}`,
    `${t("Aadhar")}`,
    `${t("Voter ID")}`,
    `${t("Others")}`,
  ];

  //Passport image - isha requirement
  const [passportImageUpload, setPassportImageUpload] = useState("Camera");
  const [showPassportModal, setShowPassportModal] = useState(false);
  const handleClosePassportModal = () => setShowPassportModal(false);
  const handleOpenPassportModal = () => setShowPassportModal(true);
  const [passportImage, setPassportImage] = useState(undefined);
  const passportCapture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPassportImage(imageSrc);
  }, [webcamRef]);
  const [passportImages, setPassportImages] = useState([]);

  //images

  const [images, setImages] = useState([]);
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
    setshowUserIdImages(1);
  };

  const [faviconImages, setFaviconImages] = useState([]);

  const onChangeFavicon = (faviconImageList, addUpdateIndex) => {
    setFaviconImages(faviconImageList);
    setshowUserIdImagesback(1);
  };

  const [checked, setChecked] = React.useState(false);
  const [updateChecked, setUpdateChecked] = useState(false);

  const handleChanges = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    if (reservationData?.group_booking) {
      setUpdateChecked(false);
    } else {
      setUpdateChecked(true);
    }
  }, []);

  //update Customer Note Modal
  const [updateNotesModal, setupdateNotesShow] = useState(false);

  const handleNoteClose = () => setupdateNotesShow(false);
  const handleNoteShow = () => setupdateNotesShow(true);
  const UpdateDonotRent = async () => {
    const data = await upadateNotes(oldEmail);
    if (data === "Updated do not rent") {
      toast.success(data);
    } else {
      toast.error(`${t("Something went wrong")}`);
    }
    getAllCustomerData();
    handleNoteClose();
  };

  //Add Travel Agent Modal
  const [modalShow, setModalShow] = useState(false);
  const [imgURL, setImgURL] = useState("");
  const [title, setTitle] = useState("");

  const handleModalClose = () => setModalShow(false);
  const handleModalShow = (tit, url) => {
    setTitle(tit);
    setImgURL(url);
    setModalShow(true);
  };

  const downloadImage = (url) => {
    saveAs(url, "image.jpg");
  };
  const webcamRef = React.useRef(null);
  const [image, setImage] = useState(undefined);
  const [backImage, setBackImage] = useState(undefined);
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
  }, [webcamRef]);

  const backcapture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setBackImage(imageSrc);
  }, [webcamRef]);

  const videoConstraints = {
    width: 400,
    height: 300,
    facingMode: "user",
  };

  const [imageUpload, setimageUpload] = useState("Device");
  const [cameraUpload, setcameraUpload] = useState(false);
  useEffect(() => {
    if (cameraUpload === true) {
      setimageUpload("Camera");
    } else {
      setimageUpload("Device");
    }
  }, [cameraUpload]);

  const [showFrontIdModal, setshowFrontIdModal] = useState(false);

  const handleCloseFrontIdModal = () => setshowFrontIdModal(false);
  const handleOpenFrontIdModal = () => {
    setshowFrontIdModal(true);
  };

  const [showBackIdModal, setshowBackIdModal] = useState(false);

  const handleCloseBackIdModal = () => setshowBackIdModal(false);
  const handleOpenBackIdModal = () => {
    setshowBackIdModal(true);
  };

  //Save disabled
  const [saveDisable, setSaveDisable] = useState(false);

  useEffect(() => {
    if (
      JSON.parse(hotel?.accessControl?.user_feature_map?.IshaExtraGuestDetails)
        .read === "YES" &&
      (checkIshaAccess(hotel?.emailId) || checkIshaUSAccess(hotel?.emailId))
    ) {
      if (
        formik.values.customer_phone != "" &&
        formik.values.emergency_contact == formik.values.customer_phone
      ) {
        setSaveDisable(true);
      } else {
        setSaveDisable(false);
      }
    }
  }, [formik.values.customer_phone, formik.values.emergency_contact]);

  const getGeoCodeValues = (zipcode) => {
    try {
      getGeoCodeDetails(zipcode).then((resp) => {
        const re = /^[0-9\b]+$/;
        //Cases where the city is not mentioned in gecoding api
        if (!re.test(resp.city)) {
          formik.setFieldValue("city", resp.city);
        } else {
          formik.setFieldValue("city", "");
        }
        formik.setFieldValue("state", resp.state);
        formik.setFieldValue("country", resp.country);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const deleteHotelLogo = (id) => {
    let data;
    if (id === "front") {
      data = images && images[0]?.data_url;
    } else if (id === "back") {
      data = faviconImages && faviconImages[0]?.data_url;
    } else {
      data = passportImages && passportImages[0]?.data_url;
    }

    if (data?.includes("defaultLogo")) {
    } else {
      const splitUrl = data.split(".blob.core.windows.net/");
      axios
        .get(
          `${process.env.REACT_APP_BG_API_URL}core/api/v1/image/delete-image?imagePath=${splitUrl[1]}`
        )
        .then((res) => {
          toast.success(`${t("Successfully deleted")}`);
          getUserId(formik.values.customer_email);
        })
        .catch((err) => toast.error(`${t("Error in deleting")}`));
    }
  };

  const [Severity, setSeverity] = useState("HIGH");
  const [enabledEmail, setenabledEmail] = useState(
    JSON.parse(
      hotel?.accessControl?.user_feature_map
        ?.CustomerInformationCaptureAndDisplay
    ).read === "YES" &&
      !formik.values.customer_email.includes("example") &&
      formik.values.customer_email !== ""
  );
  const [enabledPhone, setenabledPhone] = useState(
    JSON.parse(
      hotel?.accessControl?.user_feature_map
        ?.CustomerInformationCaptureAndDisplay
    ).read === "YES" &&
      formik.values.customer_phone !== "0000000000" &&
      formik.values.customer_phone !== ""
  );
  const [selectedEmailStatus, setselectedEmailStatus] = useState("");
  const [selectedResId, setselectedResId] = useState();

  useEffect(() => {
    setenabledEmail(
      JSON.parse(
        hotel?.accessControl?.user_feature_map
          ?.CustomerInformationCaptureAndDisplay
      ).read === "YES" &&
        !selectedGuestEmail.includes("example") &&
        selectedGuestEmail !== ""
    );

    if (
      allGuestList?.length > 0 &&
      selectedGuestEmail !== "" &&
      selectedGuestEmail !== null &&
      selectedGuestEmail !== undefined
    ) {
      const data = allGuestList?.filter((e) => e.email === selectedGuestEmail);
      setselectedEmailStatus(data[0].guest_status);
      setselectedResId(data[0].reservationId);
    }
  }, [selectedGuestEmail]);

  //handle clean  new room
  const handleCleanRoom = async (room) => {
    axios
      .post(
        `${process.env.REACT_APP_BG_API_URL}core/api/v1/hk/bulk-mark-rooms-clean`,
        {
          roomIds: room,
          staffId: "",
        }
      )
      .then((res) => {})
      .catch((err) => {});
  };

  const handleCheckCleanStatus = (roomId) => {
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/reservation/get-room-dirty-status`,
      [roomId]
    ).then((data) => {
      reload();
      const arr = [];
      Object.keys(data).map((elm) => {
        if (elm !== "UNASSIGNED" && data[elm] === "DIRTY") {
          arr.push(elm);
        }
      });
      arr.length > 0 && handleCleanRoom(arr);
    });
  };

  const checkinBooking = () => {
    handleSpringDataRequest(
      `core/api/v1/reservation/update-guest-status?bookingId=${reservationData.booking_id}&reservationId=${selectedResId}&email=${selectedGuestEmail}&status=ADMIN_CONFIRMED`
    )
      .then((res) => {
        toast.success(`${t("Successfully checked in")}`);
        fetchGuestList();
        // handleCleanRoom(reservationData?.reservation_rooms).then(() => {
        //   reload();
        // });
        try {
          handleCheckCleanStatus(reservationData?.reservation_rooms);
        } catch (e) {
          reload();
        }
      })
      .catch((err) => toast.error(`${t("Something went wrong")}`));
  };

  const checkoutBooking = () => {
    let checkoutDate = moment(
      reservationData.booking_checkout.replace("T", " "),
      "YYYY-MM-DD HH:mm:ss"
    ).startOf("day");
    let currentDate = moment().startOf("day");
    if (
      hotel?.accessControl?.user_feature_map?.EarlyCheckoutAlert &&
      JSON.parse(hotel?.accessControl?.user_feature_map?.EarlyCheckoutAlert)
        .read === "YES" &&
      checkoutDate > currentDate
    ) {
      openEarlyCheckoutModal();
    } else {
      handleSpringDataRequest(
        `core/api/v1/reservation/update-guest-status?bookingId=${reservationData.booking_id}&reservationId=${selectedResId}&email=${selectedGuestEmail}&status=CHECKED_OUT`
      )
        .then((res) => {
          toast.success(`${t("Successfully checked out")}`);
          fetchGuestList();
        })
        .catch((err) => toast.error(`${t("Something went wrong")}`));
    }
  };

  const handleEarlyCheckout = async () => {
    setEarlyCheckoutLoader(true);
    //Edit reservation
    // const res = await handleSpringDataMutationRequest(
    //   "POST",
    //   "core/api/v1/reservation/performExtendReservation",
    //   {
    //     checkinDate: moment(newCheckoutDate)
    //       .minutes(0)
    //       .seconds(0)
    //       .format("DD-MM-YYYY HH:mm:ss"),
    //     gAdditionalAmt: 0,
    //     hotelId: hotel.hotelId,
    //     resIds: [selectedResId],
    //     systemUpdate: true,
    //   }
    // ).catch((res) => res?.response?.data && toast.error(res?.response?.data));
    // if (res == "Operation Successful") {
    handleSpringDataRequest(
      `core/api/v1/reservation/update-guest-status?bookingId=${reservationData.booking_id}&reservationId=${selectedResId}&email=${selectedGuestEmail}&status=CHECKED_OUT`
    )
      .then((res) => {
        toast.success(`${t("Successfully checked out")}`);
        setEarlyCheckoutLoader(false);
        closeEarlyCheckoutModal();
        fetchGuestList();
      })
      .catch(`${t("Something went wrong")}`);
    // } else {
    //   setEarlyCheckoutLoader(false);
    //   closeEarlyCheckoutModal();
    // }
  };

  const [showCheckinButton, setshowCheckinButton] = useState(false);

  useEffect(() => {
    handleSpringDataRequest(
      `core/api/v1/nightAudit/get-night-audit-flag?currentDate=${moment(
        reservationData.booking_checkin.replace("T", " "),
        "YYYY-MM-DD HH:mm:ss"
      )
        .subtract(1, "days")
        .format("DD-MM-YYYY")}&opType=WALK_IN`
    )
      .then((res) => {
        if (
          hotel?.accessControl?.ADMINRIGHTS === "ADMIN" ||
          hotel?.accessControl?.ADMINRIGHTS === "SUBADMIN"
        ) {
          setshowCheckinButton(true);
        } else {
          setshowCheckinButton(res);
        }
      })
      .catch((err) => console.log(err));
  }, [reservationData.booking_checkin]);

  const handleDobKeyDown = (event) => {
    if (event.key !== "Tab") {
      event.preventDefault();
    }
  };

  useEffect(() => {
    getAllCtaData();
  }, []);

  const getAllCtaData = async () => {
    try {
      const data = await getAllCTA();
      setCTAData(data);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className={classes.root}>
      <CommonSlider open={open} onClose={close}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <h2 className="mb-0" style={{ marginLeft: "1rem" }}>
            Guest Information
          </h2>
          <IconButton sx={{ float: "right" }} onClick={close}>
            <Close />
          </IconButton>
        </div>
        <Divider />
        <Grid container paddingTop={2}>
          <form noValidate onSubmit={formik.handleSubmit}>
            {/* <Grid
              container
              rowSpacing={3}
              columnSpacing={2}
              className={classes.textField}
            >
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <TextField
                  select
                  className="position-relative"
                  name="customer_list"
                  id="customer_list"
                  fullWidth
                  placeholder={t("All Guest List")}
                  value={selectedGuestEmail}
                  onChange={(e) => handleGuestChange(e)}
                  label={t("All Guest List")}
                  variant="outlined"
                >
                  {allGuestList?.length > 0 &&
                    allGuestList?.map((guest, index) => {
                      return (
                        <MenuItem key={guest?.email} value={guest?.email}>
                          <Stack
                            direction={"row"}
                            spacing={2}
                            // style={{ display: "flex", justifyContent: "space-between" }}
                          >
                            <Typography sx={{ color: "black" }}>
                              {guest?.name}
                            </Typography>

                            {JSON.parse(
                              hotel?.accessControl?.user_feature_map
                                ?.GuestCheckInCheckOut
                            ).read === "YES" && (
                              <Typography
                                sx={{
                                  color: `${
                                    guest.guest_status === "ADMIN_CONFIRMED"
                                      ? "#96C7C1"
                                      : guest.guest_status === "CONFIRMED"
                                      ? "#F7D59C"
                                      : "#A3AECC"
                                  }`,
                                }}
                              >
                                {guest.guest_status === "ADMIN_CONFIRMED"
                                  ? `${t("CHECKED_IN")}`
                                  : guest.guest_status}
                              </Typography>
                            )}
                          </Stack>
                        </MenuItem>
                      );
                    })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                {hotel?.hotelCurrency === "USD" && (
                  <>
                    {hotel?.accessControl?.hotel?.enable_id_scanner ===
                      "AMBIRSCAN" && (
                      <Button
                        variant="custom-button"
                        disabled={loader}
                        onClick={ScannedData}
                        style={{ marginLeft: "50%", marginTop: "0.5rem" }}
                      >
                        {loader ? (
                          <CircularProgress
                            size={15}
                            color="inherit"
                            sx={{ ml: 1.5 }}
                          />
                        ) : (
                          `${t("Scan id")}`
                        )}
                      </Button>
                    )}

                    {hotel?.accessControl?.hotel?.enable_id_scanner ===
                      "IDGURU" && (
                      <Button
                        onClick={handleOpenIdGuruModal}
                        variant="custom-button"
                        style={{ marginLeft: "50%", marginTop: "0.5rem" }}
                      >
                        ID Guru scanner
                      </Button>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
            <Divider /> */}
            <Grid
              container
              rowSpacing={3}
              columnSpacing={2}
              className={classes.textField}
            >
              {hotel?.hotelCurrency === "USD" && (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <>
                    {hotel?.accessControl?.hotel?.enable_id_scanner ===
                      "AMBIRSCAN" && (
                      <Button
                        variant="custom-button"
                        disabled={loader}
                        onClick={ScannedData}
                        style={{ marginLeft: "50%", marginTop: "0.5rem" }}
                      >
                        {loader ? (
                          <CircularProgress
                            size={15}
                            color="inherit"
                            sx={{ ml: 1.5 }}
                          />
                        ) : (
                          `${t("Scan id")}`
                        )}
                      </Button>
                    )}

                    {hotel?.accessControl?.hotel?.enable_id_scanner ===
                      "IDGURU" && (
                      <Button
                        onClick={handleOpenIdGuruModal}
                        variant="custom-button"
                        style={{ marginLeft: "50%", marginTop: "0.5rem" }}
                      >
                        ID Guru scanner
                      </Button>
                    )}
                  </>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <TextField
                  name="customer_name"
                  id="customer_name"
                  fullWidth
                  placeholder={t("Title")}
                  disabled={
                    JSON.parse(
                      hotel?.accessControl?.user_feature_map
                        ?.CustomerInformationCaptureAndDisplay
                    ).read === "YES"
                  }
                  value={formik.values.customer_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.customer_name &&
                    Boolean(formik.errors.customer_name)
                  }
                  helperText={
                    formik.touched.customer_name && formik.errors.customer_name
                  }
                  label={t("Customer Name")}
                  variant="outlined"
                  required
                />
              </Grid>
              {((subUser?.toLowerCase() === "subuser" &&
                JSON.parse(
                  hotel?.accessControl?.user_feature_map?.ResGridCustomerEmail
                ).read !== "YES") ||
                subUser?.toLowerCase() === "admin" ||
                subUser?.toLowerCase() === "subadmin") && (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <TextField
                    name="customer_email"
                    id="customer_email"
                    fullWidth
                    type="text"
                    disabled={enabledEmail}
                    placeholder={t("Email Address")}
                    value={formik.values.customer_email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label={t("Customer Email")}
                    variant="outlined"
                    required
                    error={
                      formik.values.customer_email?.length > 0 &&
                      !validateEmail(formik.values.customer_email)
                    }
                    helperText={
                      formik.values.customer_email?.length > 0 &&
                      !validateEmail(formik.values.customer_email)
                        ? "Please enter valid email"
                        : ""
                    }
                  />
                </Grid>
              )}
              {((subUser?.toLowerCase() === "subuser" &&
                JSON.parse(
                  hotel?.accessControl?.user_feature_map?.ResGridCustomerPhone
                ).read !== "YES") ||
                subUser?.toLowerCase() === "admin" ||
                subUser?.toLowerCase() === "subadmin") && (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <TextField
                    name="customer_phone"
                    id="customer_phone"
                    fullWidth
                    placeholder={t("Phone Number")}
                    disabled={enabledPhone}
                    onBlur={formik.handleBlur}
                    value={formik.values.customer_phone}
                    onChange={formik.handleChange}
                    label={t("Customer Phone")}
                    variant="outlined"
                    required
                    error={
                      formik.values.customer_phone?.length > 0 &&
                      !validatePhoneNumber(formik.values.customer_phone)
                    }
                    helperText={
                      formik.values.customer_phone?.length > 0 &&
                      !validatePhoneNumber(formik.values.customer_phone)
                        ? "Please enter valid contact"
                        : ""
                    }
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                {JSON.parse(
                  hotel?.accessControl?.user_feature_map?.IshaExtraGuestDetails
                ).read === "YES" &&
                (checkIshaAccess(hotel?.emailId) ||
                  checkIshaUSAccess(hotel?.emailId)) &&
                formik.values.address?.length < 12 ? (
                  <>
                    <TextField
                      error
                      name="address"
                      id="address"
                      fullWidth
                      type="text"
                      placeholder={t("Address")}
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      label={t("Address")}
                      variant="outlined"
                      helperText={`${t(
                        "Address length is minimum 12 characters"
                      )}`}
                    />
                  </>
                ) : (
                  <>
                    <TextField
                      name="address"
                      id="address"
                      fullWidth
                      type="text"
                      placeholder={t("Address")}
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      label={t("Address")}
                      variant="outlined"
                    />
                  </>
                )}
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <TextField
                  name="city"
                  id="city"
                  fullWidth
                  type="text"
                  placeholder={t("City")}
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label={t("City")}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <Autocomplete
                  fullWidth
                  renderInput={(params) => {
                    params.inputProps.autoComplete = "new-password";
                    return <TextField {...params} label={t("State")} />;
                  }}
                  onChange={(eve, val) => {
                    formik.setFieldValue("state", val);
                  }}
                  options={
                    isNull(formik.values.country) ||
                    isUndefined(countries[formik.values.country])
                      ? []
                      : countries[formik.values.country]
                  }
                  value={formik.values.state}
                  filterOptions={(options, { inputValue }) => {
                    if (!inputValue) return options;
                    const input = inputValue.toLowerCase();
                    return options
                      .filter((option) => option.toLowerCase().includes(input))
                      .sort((a, b) => {
                        const aStartsWith = a.toLowerCase().startsWith(input);
                        const bStartsWith = b.toLowerCase().startsWith(input);
                        if (aStartsWith && !bStartsWith) return -1;
                        if (!aStartsWith && bStartsWith) return 1;
                        return a.localeCompare(b);
                      });
                  }}
                ></Autocomplete>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <Autocomplete
                  fullWidth
                  autoComplete={false}
                  onChange={(eve, val) => {
                    if (val !== formik.values.country)
                      formik.setFieldValue("state", null);
                    formik.setFieldValue("country", val);
                  }}
                  options={Object.keys(countries)}
                  value={formik.values.country}
                  renderInput={(params) => {
                    params.inputProps.autoComplete = "new-password";
                    return <TextField {...params} label={t("Country")} />;
                  }}
                  filterOptions={(options, { inputValue }) => {
                    if (!inputValue) return options;
                    const input = inputValue.toLowerCase();
                    return options
                      .filter((option) => option.toLowerCase().includes(input))
                      .sort((a, b) => {
                        const aStartsWith = a.toLowerCase().startsWith(input);
                        const bStartsWith = b.toLowerCase().startsWith(input);
                        if (aStartsWith && !bStartsWith) return -1;
                        if (!aStartsWith && bStartsWith) return 1;
                        return a.localeCompare(b);
                      });
                  }}
                ></Autocomplete>
              </Grid>
              <Grid item lg={3} xl={3}>
                <TextField
                  name="zipcode"
                  id="zipcode"
                  fullWidth
                  type="text"
                  placeholder={t("Zip Code")}
                  value={formik.values.zipcode}
                  onChange={(e) => {
                    const re = /^[0-9A-Za-z\b]+$/;
                    if (e.target.value == "" || re.test(e.target.value)) {
                      formik.handleChange(e);
                      if (
                        e.target.value.length >= 5 &&
                        e.target.value.length <= 10
                      ) {
                        //call the api function and get the city state and country vals
                        getGeoCodeValues(e.target.value);
                      }
                    }
                  }}
                  onBlur={formik.handleBlur}
                  label={t("Zip Code")}
                  variant="outlined"
                />
              </Grid>

              {showMore && (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <TextField
                      name="address2"
                      id="address2"
                      fullWidth
                      type="text"
                      placeholder={t("Address Line 2")}
                      value={formik.values.address2}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      label={t("Address Line 2")}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <TextField
                      select
                      className="position-relative"
                      name="customer_gender"
                      id="customer_gender"
                      fullWidth
                      placeholder={t("Gender")}
                      value={formik.values.customer_gender}
                      onChange={(e) => {
                        formik.setFieldValue("customer_gender", e.target.value);
                      }}
                      error={
                        checkIshaUSAccess(hotel?.emailId) &&
                        formik.values.customer_gender == "-"
                      }
                      helperText={
                        checkIshaUSAccess(hotel?.emailId) &&
                        formik.values.customer_gender == "-"
                          ? "Please select gender"
                          : ""
                      }
                      label={t("Gender")}
                      variant="outlined"
                    >
                      {JSON.parse(
                        hotel?.accessControl?.user_feature_map
                          ?.IshaExtraGuestDetails
                      ).read === "YES" &&
                      (checkIshaAccess(hotel?.emailId) ||
                        checkIshaUSAccess(hotel?.emailId))
                        ? ishaGenderList?.length > 0 &&
                          ishaGenderList.map((genVal) => {
                            return (
                              <MenuItem key={genVal} value={genVal}>
                                {genVal}
                              </MenuItem>
                            );
                          })
                        : genderList?.length > 0 &&
                          genderList.map((genVal) => {
                            return (
                              <MenuItem key={genVal} value={genVal}>
                                {genVal}
                              </MenuItem>
                            );
                          })}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <TextField
                      select
                      className="position-relative"
                      name="customer_category"
                      id="customer_category"
                      fullWidth
                      placeholder={t("Guest Category")}
                      value={formik.values.customer_category}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "customer_category",
                          e.target.value
                        );
                      }}
                      label={t("Guest Category")}
                      variant="outlined"
                    >
                      <MenuItem key={"ADULT"} value={"ADULT"}>
                        {t("Adult")}
                      </MenuItem>
                      <MenuItem key={"CHILD"} value={"CHILD"}>
                        {t("Child")}
                      </MenuItem>
                      <MenuItem key={"INFANT"} value={"INFANT"}>
                        {t("Infant")}
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat={dateFnsFormat}
                        fullWidth
                        disableMaskedInput
                        mask="___ ___, ____"
                        id="customer_dob"
                        name="customer_dob"
                        className={classes.textField}
                        value={formik.values.customer_dob}
                        label={t("Date of Birth")}
                        onChange={setDateOfBirthSelection}
                        onBlur={formik.handleBlur}
                        InputProps={{ onKeyDown: handleDobKeyDown }}
                        renderInput={(params) => {
                          params.error = Boolean(formik.errors.customer_dob);
                          return (
                            <TextField
                              helperText={formik.errors.customer_dob}
                              fullWidth
                              {...params}
                            />
                          );
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={4}>
                    <Autocomplete
                      fullWidth
                      renderInput={(params) => {
                        params.inputProps.autoComplete = "new-password";
                        return (
                          <TextField {...params} label={t("Nationality")} />
                        );
                      }}
                      onChange={(eve, val) => {
                        formik.setFieldValue("nationality", val);
                      }}
                      options={nationalities}
                      // options={
                      //   isNull(formik.values.nationality) ||
                      //   isUndefined(countries[formik.values.country])
                      //     ? []
                      //     : countries[formik.values.country]
                      // }
                      value={formik.values.nationality}
                    ></Autocomplete>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="occupation"
                      id="occupation"
                      fullWidth
                      type="text"
                      placeholder={t("Occupation")}
                      value={formik.values.occupation}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      label={t("Occupation")}
                      variant="outlined"
                    />
                  </Grid>
                  {JSON.parse(
                    hotel?.accessControl?.user_feature_map
                      ?.IshaExtraGuestDetails
                  ).read === "YES" &&
                    (checkIshaAccess(hotel?.emailId) ||
                      checkIshaUSAccess(hotel?.emailId)) && (
                      <>
                        {checkIshaAccess(hotel?.emailId) && (
                          <>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <TimePicker
                                  style={{ width: "23rem" }}
                                  label={t("Estimated time of arrival")}
                                  name="customer_eta"
                                  id="customer_eta"
                                  value={formik.values.customer_eta}
                                  onChange={handleETAChange}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      style={{ width: "23rem" }}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <TimePicker
                                  style={{ width: "23rem" }}
                                  label={t("Estimated time of departure")}
                                  name="customer_etd"
                                  id="customer_etd"
                                  value={formik.values.customer_etd}
                                  onChange={handleETDChange}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      style={{ width: "23rem" }}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                              <TextField
                                select
                                className="position-relative"
                                name="ocoCheckinComplete"
                                id="ocoCheckinComplete"
                                fullWidth
                                placeholder="OCO checkin complete"
                                value={formik.values.ocoCheckinComplete}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "ocoCheckinComplete",
                                    e.target.value
                                  );
                                }}
                                label="OCO checkin complete"
                                variant="outlined"
                              >
                                <MenuItem key={"Yes"} value={"Yes"}>
                                  {"Yes"}
                                </MenuItem>
                                <MenuItem key={"No"} value={"No"}>
                                  {"No"}
                                </MenuItem>
                              </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                              <TextField
                                name="approvedByOverseas"
                                id="approvedByOverseas"
                                select
                                className="position-relative"
                                fullWidth
                                type="text"
                                placeholder="Approved by overseas"
                                value={formik.values.approvedByOverseas}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                label="Approved by overseas"
                                variant="outlined"
                              >
                                {ishaApprovedByOverseas?.length > 0 &&
                                  ishaApprovedByOverseas.map(
                                    (purpose, index) => {
                                      return (
                                        <MenuItem key={purpose} value={purpose}>
                                          {purpose}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                              </TextField>
                            </Grid>
                          </>
                        )}
                        {reservationData?.user_email === oldEmail ? (
                          <>
                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                              <TextField
                                name="company_name"
                                id="company_name"
                                fullWidth
                                type="text"
                                placeholder={t("Company Name")}
                                value={formik.values.company_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                label={t("Company Name")}
                                variant="outlined"
                              />
                            </Grid>
                            {checkIshaAccess(hotel?.emailId) && (
                              <>
                                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                  <TextField
                                    name="emergency_contact_name"
                                    id="emergency_contact_name"
                                    fullWidth
                                    type="text"
                                    placeholder={t("Emergency Contact Name")}
                                    value={formik.values.emergency_contact_name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    label={t("Emergency Contact Name")}
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                  {formik.values.customer_phone != "" &&
                                  formik.values.emergency_contact ==
                                    formik.values.customer_phone ? (
                                    <TextField
                                      name="emergency_contact"
                                      id="emergency_contact"
                                      fullWidth
                                      type="text"
                                      placeholder={t("Emergency Contact")}
                                      value={formik.values.emergency_contact}
                                      onChange={formik.handleChange}
                                      error
                                      helperText={`${t(
                                        "Enter different number"
                                      )}`}
                                      onBlur={formik.handleBlur}
                                      label={t("Emergency Contact")}
                                      variant="outlined"
                                    />
                                  ) : (
                                    <TextField
                                      name="emergency_contact"
                                      id="emergency_contact"
                                      fullWidth
                                      type="text"
                                      placeholder={t("Emergency Contact")}
                                      value={formik.values.emergency_contact}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      label={t("Emergency Contact")}
                                      variant="outlined"
                                      error={
                                        formik.values.emergency_contact
                                          ?.length > 0 &&
                                        !validatePhoneNumber(
                                          formik.values.emergency_contact
                                        )
                                      }
                                      helperText={
                                        formik.values.emergency_contact
                                          ?.length > 0 &&
                                        !validatePhoneNumber(
                                          formik.values.emergency_contact
                                        )
                                          ? "Please enter valid contact"
                                          : ""
                                      }
                                    />
                                  )}
                                </Grid>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                              <TextField
                                name="company_name"
                                id="company_name"
                                fullWidth
                                type="text"
                                placeholder={t("Company Name")}
                                value={formik.values.company_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                label={t("Company Name")}
                                variant="outlined"
                              />
                            </Grid>
                            {formik.values.relationship_with_primary ===
                            "Other" ? (
                              <>
                                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                  <TextField
                                    select
                                    id="relationship_with_primary"
                                    label={t("Relationship With Primary")}
                                    name="relationship_with_primary"
                                    value={
                                      formik.values.relationship_with_primary
                                    }
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "relationship_with_primary",
                                        e.target.value
                                      );
                                    }}
                                    fullWidth
                                  >
                                    {ishaRelationWithPrimary?.length > 0 &&
                                      ishaRelationWithPrimary.map(
                                        (relation, index) => {
                                          return (
                                            <MenuItem
                                              key={relation}
                                              value={relation}
                                            >
                                              {relation}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                  </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                  <TextField
                                    name="other_relationship_with_primary"
                                    id="other_relationship_with_primary"
                                    fullWidth
                                    type="text"
                                    placeholder={t("Other relation to primary")}
                                    value={
                                      formik.values
                                        .other_relationship_with_primary
                                    }
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    label={t("Other relation to primary")}
                                    variant="outlined"
                                  />
                                </Grid>
                              </>
                            ) : (
                              <>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                  <TextField
                                    select
                                    id="relationship_with_primary"
                                    label={t("Relationship With Primary")}
                                    name="relationship_with_primary"
                                    value={
                                      formik.values.relationship_with_primary
                                    }
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "relationship_with_primary",
                                        e.target.value
                                      );
                                    }}
                                    fullWidth
                                  >
                                    {ishaRelationWithPrimary?.length > 0 &&
                                      ishaRelationWithPrimary.map(
                                        (relation, index) => {
                                          return (
                                            <MenuItem
                                              key={relation}
                                              value={relation}
                                            >
                                              {relation}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                  </TextField>
                                </Grid>
                              </>
                            )}
                          </>
                        )}
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                          <TextField
                            select
                            name="purposeOfVisit"
                            id="purposeOfVisit"
                            fullWidth
                            placeholder={t("Purpose of visit")}
                            value={formik.values.purposeOfVisit}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            label={t("Purpose of visit")}
                            variant="outlined"
                          >
                            {ishaPurposeOfVisit?.length > 0 &&
                              ishaPurposeOfVisit.map((purpose, index) => {
                                return (
                                  <MenuItem key={purpose} value={purpose}>
                                    {purpose}
                                  </MenuItem>
                                );
                              })}
                          </TextField>
                        </Grid>
                        {checkIshaUSAccess(hotel?.emailId) && (
                          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <TextField
                              name="eventType"
                              id="eventType"
                              fullWidth
                              type="text"
                              placeholder={t("Event Name")}
                              value={formik.values.eventType}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              label={t("Event Name")}
                              variant="outlined"
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                          {checkIshaAccess(hotel?.emailId) && (
                            <FormControl>
                              <InputLabel id="demo-simple-select-label">
                                {t("Programs Attended")}
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                name="programsAttended"
                                id="programsAttended"
                                label={t("Programs Attended")}
                                value={selectedPrograms}
                                onChange={handleProgramChange}
                                multiple
                                style={{ width: "15rem" }}
                                // disabled={
                                //   formik.values.purposeOfVisit == "Program Participation"
                                //     ? false
                                //     : true
                                // }
                                // MenuProps={MenuProps}
                                placeholder={t("Choose Programs Attended")}
                              >
                                {ishaProgramsAttended?.length > 0 &&
                                  ishaProgramsAttended.map((program, index) => {
                                    return (
                                      <MenuItem key={program} value={program}>
                                        {program}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormControl>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                          <TextField
                            select
                            name="medicalCondition"
                            id="medicalCondition"
                            fullWidth
                            placeholder={t("Medical condition")}
                            value={formik.values.medicalCondition}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            label={t("Medical condition")}
                            variant="outlined"
                            disabled={true}
                          >
                            <MenuItem key={"Yes"} value={"Yes"}>
                              {"Yes"}
                            </MenuItem>
                            <MenuItem key={"No"} value={"No"}>
                              {"No"}
                            </MenuItem>
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                          <FormControl
                            fullWidth
                            // sx={{ width: "12rem", marginLeft: "2rem" }}
                          >
                            <InputLabel id="demo-simple-select-label">
                              {t("Upload Passport Size Photo")}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={passportImageUpload}
                              onChange={(e) =>
                                setPassportImageUpload(e.target.value)
                              }
                              label={t("Upload Passport Size Photo")}
                            >
                              <MenuItem value={"Device"}>
                                {" "}
                                {t("Device upload")}
                              </MenuItem>
                              <MenuItem value={"Camera"}>
                                {" "}
                                {t("Camera upload")}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          {passportImageUpload === "Camera" && (
                            <div className="mt-2 d-flex">
                              <div className="d-flex flex-column mr-3">
                                {passportImage !== undefined && (
                                  <img
                                    src={`${passportImage}`}
                                    style={{
                                      width: "24rem",
                                      heigth: "20rem",
                                      borderRadius: "1rem",
                                    }}
                                  />
                                )}
                                <Button
                                  variant="custom-button"
                                  className="mr-2 mt-3"
                                  onClick={() => handleOpenPassportModal()}
                                >
                                  {t("Upload Passport Size Photo")}
                                </Button>
                              </div>
                            </div>
                          )}
                        </Grid>
                        {passportImageUpload === "Device" && (
                          <Grid item xs={12}>
                            <div className="d-flex">
                              <div className="d-flex flex-column mr-4 mt-3 mb-3 w-50">
                                <h5
                                  className="mb-3"
                                  style={{ marginRight: "13px" }}
                                >
                                  {t("Passport Size Photo")}
                                </h5>
                                <ReactImageUploading
                                  value={passportImages}
                                  onChange={(image) => {
                                    setPassportImages(image);
                                  }}
                                  dataURLKey="data_url"
                                >
                                  {({
                                    imageList,
                                    onImageUpload,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps,
                                  }) => (
                                    // write your building UI
                                    <Stack
                                      // p="2"
                                      // bgColor="gray.50"
                                      // borderRadius="lg"
                                      className="upload__image-wrapper"
                                      sx={{
                                        borderRadius: "10px",
                                        height: "150px",
                                        backgroundColor: "#d3d3d336",
                                        padding: "1rem",
                                      }}
                                    >
                                      {imageList.length === 0 && (
                                        <IconButton
                                          children={
                                            <UploadIcon
                                              icon="bi:upload"
                                              htmlColor="black"
                                            />
                                          }
                                          variant="outline"
                                          // height="100%"
                                          sx={{
                                            borderRadius: "20px",
                                            backgroundColor: "#d3d3d3",
                                            height: "100%",
                                          }}
                                          onClick={onImageUpload}
                                          {...dragProps}
                                        ></IconButton>
                                      )}
                                      <Stack spacing="2">
                                        {imageList.map((image, index) => (
                                          <Stack
                                            sx={{
                                              position: "relative",
                                              padding: "0.5rem",
                                            }}
                                            key={index}
                                          >
                                            <img
                                              src={
                                                image["data_url"].includes(
                                                  "base64"
                                                )
                                                  ? image["data_url"]
                                                  : image["data_url"] +
                                                    "?lastmod=" +
                                                    format(
                                                      new Date(),
                                                      "ddMMyyyyhhmmsstt"
                                                    )
                                              }
                                              style={{
                                                height: "125px",
                                                width: "100%",
                                                borderRadius: "1rem",
                                                boxShadow: "md",
                                                border: "1px solid",
                                                borderColor: "gray.200",
                                              }}
                                              onClick={() =>
                                                handleModalShow(
                                                  "Passport",
                                                  image["data_url"]
                                                )
                                              }
                                              alt=""
                                            />

                                            <IconButton
                                              children={
                                                <CloseIcon
                                                  fontSize="xs"
                                                  htmlColor="black"
                                                />
                                              }
                                              size="xs"
                                              sx={{
                                                position: "absolute",
                                                borderRadius: "100%",
                                                backgroundColor: "white",
                                                top: "0",
                                                // colorScheme="teal"
                                                marginTop: "0px !important",
                                                left: "0",
                                                "&:hover": {
                                                  color: "black",
                                                  backgroundColor: "#ffffffcc",
                                                },
                                              }}
                                              onClick={() => {
                                                onImageRemove(index);
                                                deleteHotelLogo("passport");
                                              }}
                                            />
                                            <IconButton
                                              children={
                                                <ChangeCircleIcon
                                                  fontSize="xs"
                                                  htmlColor="black"
                                                />
                                              }
                                              size="xs"
                                              sx={{
                                                position: "absolute",
                                                borderRadius: "100%",
                                                top: "0",
                                                backgroundColor: "white",
                                                // colorScheme="teal"
                                                marginTop: "0px !important",
                                                right: "0",
                                                "&:hover": {
                                                  color: "black",
                                                  backgroundColor: "#ffffffcc",
                                                },
                                              }}
                                              onClick={() =>
                                                onImageUpdate(index)
                                              }
                                            />
                                          </Stack>
                                        ))}
                                      </Stack>
                                    </Stack>
                                  )}
                                </ReactImageUploading>
                              </div>
                            </div>
                          </Grid>
                        )}
                      </>
                    )}
                  <Grid item xs={4}>
                    <TextField
                      select
                      variant="outlined"
                      value={govIdType}
                      fullWidth
                      id="rate_plan"
                      name="rate_plan"
                      label={t("Govt ID")}
                      onChange={(e) => setGovIdType(e.target.value)}
                    >
                      {hotel.hotelCurrency === "INR"
                        ? govIdIndia?.map((item, index) => (
                            <MenuItem key={index + item} value={item}>
                              {item}
                            </MenuItem>
                          ))
                        : govIdUS?.map((item, index) => (
                            <MenuItem key={index + item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="govtIdValue"
                      style={{ width: "100%" }}
                      label={t("GOVT ID Number")}
                      name="govtIdValue"
                      variant="outlined"
                      placeholder={t("GOVT ID Number")}
                      value={govtIdValue}
                      onChange={(e) => setGovtIdValue(e.target.value)}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={4}>
                <div className="d-flex align-items-center mb-3">
                  <h4 className="mr-3 "> {t("Camera upload ?")}</h4>
                  <Switch
                    checked={cameraUpload}
                    onChange={() => setcameraUpload(!cameraUpload)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
              </Grid>
              {/* <Grid item xs={4}>
                <FormControl
                  fullWidth
                  // sx={{ width: "12rem", marginLeft: "2rem" }}
                >
                  <InputLabel id="demo-simple-select-label">
                    {t("Upload Image")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={imageUpload}
                    onChange={(e) => setimageUpload(e.target.value)}
                    label={t("Upload Image")}
                  >
                    <MenuItem value={"Device"}> {t("Device upload")}</MenuItem>
                    <MenuItem value={"Camera"}> {t("Camera upload")}</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid container item xs={12} sm={12} md={4} lg={4} xl={4}>
                <p
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "#1BC5BD",
                    fontSize: "15px",
                    marginBottom: "0px",
                    fontWeight: "bold",
                    marginTop: "10px",
                  }}
                  onClick={() => setshowMore(!showMore)}
                >{`${showMore ? `${t("Less")}` : `${t("More")}`}`}</p>
              </Grid>
            </Grid>
            <Divider />
            {imageUpload === "Camera" && (
              <div className="mt-4 d-flex">
                <div className="d-flex flex-column mr-3">
                  {image !== undefined && (
                    <img
                      src={`${image}`}
                      style={{
                        width: "24rem",
                        heigth: "20rem",
                        borderRadius: "1rem",
                        marginLeft: "1.5rem",
                        marginBottom: "1rem",
                      }}
                    />
                  )}

                  {image === undefined ? (
                    <div
                      style={{ marginLeft: "1.5rem" }}
                      onClick={handleOpenFrontIdModal}
                    >
                      <CardforButtons
                        heading={t("Upload front id")}
                        variant="custom-button"
                        selectedLanguage={props.selectedLanguage}
                      ></CardforButtons>
                    </div>
                  ) : (
                    <p
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        color: "#1BC5BD",
                        fontSize: "15px",
                        marginBottom: "0px",
                        fontWeight: "bold",

                        margin: "auto",
                      }}
                      onClick={() => handleOpenFrontIdModal()}
                    >
                      {t(`Edit front id`)}
                    </p>
                  )}
                </div>
                <div className="d-flex flex-column">
                  {backImage !== undefined && (
                    <img
                      src={`${backImage}`}
                      style={{
                        width: "24rem",
                        heigth: "20rem",
                        borderRadius: "1rem",
                        marginBottom: "1rem",
                      }}
                    />
                  )}

                  {backImage === undefined ? (
                    <div onClick={handleOpenBackIdModal}>
                      <CardforButtons
                        heading={t("Upload Back id")}
                        variant="custom-button"
                        selectedLanguage={props.selectedLanguage}
                      ></CardforButtons>
                    </div>
                  ) : (
                    <p
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        color: "#1BC5BD",
                        fontSize: "15px",
                        marginBottom: "0px",
                        fontWeight: "bold",

                        margin: "auto",
                      }}
                      onClick={() => handleOpenBackIdModal()}
                    >
                      {" "}
                      {t(`Edit back id`)}
                    </p>
                  )}
                </div>
              </div>
            )}

            {imageUpload === "Device" && (
              <div className="d-flex p-3">
                <div className="d-flex flex-column mr-4 mt-3 mb-3 w-50">
                  <h5 className="mb-3" style={{ marginRight: "13px" }}>
                    {t(`ID front`)}
                  </h5>
                  <ReactImageUploading
                    value={images}
                    onChange={(image) => {
                      setImages(image);
                    }}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <Stack
                        // p="2"
                        // bgColor="gray.50"
                        // borderRadius="lg"
                        className="upload__image-wrapper"
                        sx={{
                          borderRadius: "10px",
                          height: "150px",
                          backgroundColor: "#d3d3d336",
                          padding: "1rem",
                        }}
                      >
                        {imageList.length === 0 && (
                          <IconButton
                            children={
                              <UploadIcon icon="bi:upload" htmlColor="black" />
                            }
                            variant="outline"
                            // height="100%"
                            sx={{
                              borderRadius: "20px",
                              backgroundColor: "#d3d3d3",
                              height: "100%",
                            }}
                            onClick={onImageUpload}
                            {...dragProps}
                          ></IconButton>
                        )}
                        <Stack spacing="2">
                          {imageList.map((image, index) => (
                            <Stack
                              sx={{ position: "relative", padding: "0.5rem" }}
                              key={index}
                            >
                              <img
                                src={
                                  image["data_url"]?.includes("base64")
                                    ? image["data_url"]
                                    : image["data_url"] +
                                      "?lastmod=" +
                                      format(new Date(), "ddMMyyyyhhmmsstt")
                                }
                                style={{
                                  height: "125px",
                                  width: "100%",
                                  borderRadius: "1rem",
                                  boxShadow: "md",
                                  border: "1px solid",
                                  borderColor: "gray.200",
                                }}
                                alt=""
                                onClick={() =>
                                  handleModalShow("Front ID", image["data_url"])
                                }
                              />
                              <IconButton
                                children={
                                  <CloseIcon fontSize="xs" htmlColor="black" />
                                }
                                size="xs"
                                sx={{
                                  position: "absolute",
                                  borderRadius: "100%",
                                  backgroundColor: "white",
                                  top: "0",
                                  // colorScheme="teal"
                                  marginTop: "0px !important",
                                  left: "0",
                                  "&:hover": {
                                    color: "black",
                                    backgroundColor: "#ffffffcc",
                                  },
                                }}
                                onClick={() => {
                                  onImageRemove(index);
                                  deleteHotelLogo("front");
                                }}
                              />

                              <IconButton
                                children={
                                  <ChangeCircleIcon
                                    fontSize="xs"
                                    htmlColor="black"
                                  />
                                }
                                size="xs"
                                sx={{
                                  position: "absolute",
                                  borderRadius: "100%",
                                  top: "0",
                                  backgroundColor: "white",
                                  // colorScheme="teal"
                                  marginTop: "0px !important",
                                  right: "0",
                                  "&:hover": {
                                    color: "black",
                                    backgroundColor: "#ffffffcc",
                                  },
                                }}
                                onClick={() => onImageUpdate(index)}
                              />
                            </Stack>
                          ))}
                        </Stack>
                      </Stack>
                    )}
                  </ReactImageUploading>
                </div>
                <div className="d-flex flex-column mr-4 mt-3 mb-3 w-50">
                  <h5 className="mb-3"> {t(`ID back`)}</h5>
                  <ReactImageUploading
                    value={faviconImages}
                    onChange={(image) => {
                      setFaviconImages(image);
                    }}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <Stack
                        className="upload__image-wrapper"
                        sx={{
                          borderRadius: "10px",
                          height: "150px",
                          backgroundColor: "#d3d3d336",
                          padding: "1rem",
                        }}
                      >
                        {imageList.length === 0 && (
                          <IconButton
                            children={
                              <UploadIcon icon="bi:upload" htmlColor="black" />
                            }
                            variant="outline"
                            sx={{
                              borderRadius: "20px",
                              backgroundColor: "#d3d3d3",
                              height: "100%",
                            }}
                            onClick={onImageUpload}
                            {...dragProps}
                          ></IconButton>
                        )}
                        <Stack spacing="2">
                          {imageList.map((image, index) => (
                            <Stack
                              sx={{ position: "relative", padding: "0.5rem" }}
                              key={index}
                            >
                              <img
                                src={
                                  image["data_url"]?.includes("base64")
                                    ? image["data_url"]
                                    : image["data_url"] +
                                      "?lastmod=" +
                                      format(new Date(), "ddMMyyyyhhmmsstt")
                                }
                                style={{
                                  height: "125px",
                                  width: "100%",
                                  borderRadius: "1rem",
                                  boxShadow: "md",
                                  border: "1px solid",
                                  borderColor: "gray.200",
                                }}
                                alt=""
                                onClick={() =>
                                  handleModalShow("Back ID", image["data_url"])
                                }
                              />
                              <IconButton
                                children={
                                  <CloseIcon fontSize="xs" htmlColor="black" />
                                }
                                size="xs"
                                sx={{
                                  position: "absolute",
                                  borderRadius: "100%",
                                  backgroundColor: "white",
                                  top: "0",
                                  // colorScheme="teal"
                                  marginTop: "0px !important",
                                  left: "0",
                                  "&:hover": {
                                    color: "black",
                                    backgroundColor: "#ffffffcc",
                                  },
                                }}
                                onClick={() => {
                                  onImageRemove(index);
                                  deleteHotelLogo("back");
                                }}
                              />
                              <IconButton
                                children={
                                  <ChangeCircleIcon
                                    fontSize="xs"
                                    htmlColor="black"
                                  />
                                }
                                size="xs"
                                sx={{
                                  position: "absolute",
                                  borderRadius: "100%",
                                  top: "0",
                                  backgroundColor: "white",
                                  // colorScheme="teal"
                                  marginTop: "0px !important",
                                  right: "0",
                                  "&:hover": {
                                    color: "black",
                                    backgroundColor: "#ffffffcc",
                                  },
                                }}
                                onClick={() => onImageUpdate(index)}
                              />
                            </Stack>
                          ))}
                        </Stack>
                      </Stack>
                    )}
                  </ReactImageUploading>
                </div>
                {/*  */}
                <div className="d-flex align-items-center "></div>
                {ImagesData?.frontUrl === "" && (
                  <div className="d-flex flex-column ml-4">
                    <div className="d-flex align-items-center mr-4 mb-4 mt-4">
                      <h6 className="mb-0" style={{ marginRight: "13px" }}>
                        {t("ID front")}
                      </h6>
                      <ImageUploading
                        multiple
                        value={images}
                        onChange={onChange}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                      >
                        {({
                          imageList,
                          onImageUpload,
                          isDragging,
                          dragProps,
                        }) => (
                          // write your building UI
                          <div className="upload__image-wrapper d-flex align-items-center">
                            <label htmlFor="contained-button-file">
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                style={{ height: "2rem", width: "0px" }}
                                // style={isDragging ? { color: "red" } : undefined}
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                {t("Upload")}
                              </Button>
                            </label>
                            <label htmlFor="icon-button-file">
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                              ></IconButton>
                            </label>

                            <div className="image-item">
                              {showUserIdImages === 0 ? (
                                ImagesData.frontUrl !== "" ? (
                                  <img
                                    src={ImagesData.frontUrl}
                                    alt=""
                                    width="100px"
                                    height="60px"
                                  />
                                ) : (
                                  <></>
                                )
                              ) : (
                                <div>
                                  {" "}
                                  {images.length > 0 && (
                                    <img
                                      src={
                                        imageList[imageList.length - 1]
                                          ?.data_url
                                      }
                                      alt=""
                                      width="100px"
                                      height="60px"
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </ImageUploading>
                    </div>
                    <div className="d-flex align-items-center">
                      <h6 className="mb-0 mr-3"> {t("ID back")}</h6>
                      <ImageUploading
                        multiple
                        value={faviconImages}
                        onChange={onChangeFavicon}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                      >
                        {({
                          faviconImageList,
                          onImageUpload,
                          isDragging,
                          dragProps,
                        }) => (
                          // write your building UI
                          <div className="upload__image-wrapper d-flex align-items-center">
                            <label htmlFor="contained-button-file">
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                style={{ height: "2rem", width: "0px" }}
                                // style={isDragging ? { color: "red" } : undefined}
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                {t("Upload")}
                              </Button>
                            </label>
                            <label htmlFor="icon-button-file">
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                              ></IconButton>
                            </label>

                            <div className="image-item">
                              {showUserIdImagesback === 0 ? (
                                ImagesData.backUrl !== "" ? (
                                  <img
                                    src={ImagesData.backUrl}
                                    alt=""
                                    width="100px"
                                    height="60px"
                                  />
                                ) : (
                                  <></>
                                )
                              ) : (
                                <div>
                                  {" "}
                                  {faviconImages.length > 0 && (
                                    <img
                                      src={
                                        faviconImages[faviconImages.length - 1]
                                          ?.data_url
                                      }
                                      alt=""
                                      width="100px"
                                      height="60px"
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </ImageUploading>
                    </div>
                  </div>
                )}
              </div>
            )}
            {reservationData?.group_booking === true && (
              <>
                <Checkbox
                  checked={updateChecked}
                  onChange={() => {
                    setUpdateChecked(!updateChecked);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <FormControlLabel
                  style={{
                    marginLeft: "1px",
                    marginTop: "3px",
                    marginBottom: "3px",
                  }}
                  control={<></>}
                  label={t(`Update customer information for all the rooms`)}
                ></FormControlLabel>
              </>
            )}
            {JSON.parse(
              hotel?.accessControl?.user_feature_map?.IshaExtraGuestDetails
            ).read === "YES" &&
              checkIshaAccess(hotel?.emailId) && (
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    {doc1Url !== "" && (
                      <a href={doc1Url} target="_blank">
                        <Button
                          variant="custom-button"
                          className="mr-4 mt-3 ml-4"
                        >
                          {docLoader ? (
                            <CircularProgress
                              size={15}
                              color="inherit"
                              sx={{ ml: 1.5 }}
                            />
                          ) : (
                            <>{t("View file 1")}</>
                          )}
                        </Button>
                      </a>
                    )}
                    <Button
                      variant="custom-button"
                      className="mr-4 mt-3 ml-4"
                      endIcon={<RiFileEditFill />}
                      onClick={() => {
                        hiddenFileInput1.current.click();
                      }}
                      disabled={docLoader}
                    >
                      {docLoader ? (
                        <CircularProgress
                          size={15}
                          color="inherit"
                          sx={{ ml: 1.5 }}
                        />
                      ) : doc1Url == "" ? (
                        <>{t("Add file 1")}</>
                      ) : (
                        <>{t("Edit file 1")}</>
                      )}

                      <input
                        style={{
                          color: document1 !== "" ? "#20c997" : "black",
                          display: "none",
                        }}
                        id="document1"
                        type="file"
                        className="form-control"
                        accept=".pdf, .jpg, .jpeg, .png"
                        name="document1"
                        ref={hiddenFileInput1}
                        onChange={(eve) => {
                          let imageName =
                            document.getElementById("document1Name");
                          let inputImage = eve.target.files[0];
                          imageName.innerText = inputImage.name;
                          convertToBase64(eve);
                        }}
                      />
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    {doc2Url !== "" && (
                      <a href={doc2Url} target="_blank">
                        <Button
                          variant="custom-button"
                          className="mr-4 mt-3 ml-4"
                        >
                          {docLoader ? (
                            <CircularProgress
                              size={15}
                              color="inherit"
                              sx={{ ml: 1.5 }}
                            />
                          ) : (
                            <>{t("View file 2")}</>
                          )}
                        </Button>
                      </a>
                    )}
                    <Button
                      variant="custom-button"
                      className="mr-4 mt-3 ml-4"
                      endIcon={<RiFileEditFill />}
                      onClick={() => {
                        hiddenFileInput2.current.click();
                      }}
                      disabled={docLoader}
                    >
                      {docLoader ? (
                        <CircularProgress
                          size={15}
                          color="inherit"
                          sx={{ ml: 1.5 }}
                        />
                      ) : doc2Url == "" ? (
                        <>{t("Add file 2")}</>
                      ) : (
                        <>{t("Edit file 2")}</>
                      )}

                      <input
                        style={{
                          color: document1 !== "" ? "#20c997" : "black",
                          display: "none",
                        }}
                        id="document2"
                        type="file"
                        className="form-control"
                        accept=".pdf, .jpg, .jpeg, .png"
                        name="document2"
                        ref={hiddenFileInput2}
                        onChange={(eve) => {
                          let imageName =
                            document.getElementById("document2Name");
                          let inputImage = eve.target.files[0];
                          imageName.innerText = inputImage.name;
                          convertToBase64(eve);
                        }}
                      />
                    </Button>
                  </Grid>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={6}>
                    <span
                      id="document1Name"
                      style={{
                        color: "green",
                        fontSize: "small",
                        marginLeft: "1.2rem",
                      }}
                    ></span>
                  </Grid>
                  <Grid item xs={6}>
                    <span
                      id="document2Name"
                      style={{
                        color: "green",
                        fontSize: "small",
                        marginLeft: "1.2rem",
                      }}
                    ></span>
                  </Grid>
                </Grid>
              )}

            <div
              className="d-flex w-100 mt-4"
              style={{ flexDirection: "row-reverse" }}
            >
              <Button
                // className={classes.footer}
                type="submit"
                disabled={
                  load ||
                  saveDisable ||
                  (JSON.parse(
                    hotel?.accessControl?.user_feature_map
                      ?.IshaExtraGuestDetails
                  ).read === "YES" &&
                    formik.values.address?.length < 12)
                }
                variant="outlined"
                style={{
                  height: "2.5rem",
                  width: "12rem",
                  //   display: "block",
                  //   marginLeft: "auto",
                  marginRight: "1rem",
                  backgroundColor: `${"#36454F"}`,
                  color: `${"white"}`,
                  borderColor: "#36454F",
                  fontWeight: "bold",
                }}
              >
                {t("Save Customer")}

                {load && (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                )}
              </Button>
              {selectedEmailStatus === "CONFIRMED" &&
                JSON.parse(
                  hotel?.accessControl?.user_feature_map?.GuestCheckInCheckOut
                ).read === "YES" &&
                showCheckinButton &&
                reservationData?.is_enquiry !== "true" && (
                  <Button
                    style={{
                      height: "2.5rem",
                      //   display: "block",
                      //   marginLeft: "auto",
                      //   marginTop: "1.8rem",
                      marginRight: "1rem",
                      color: `${"#36454F"}`,
                      borderColor: "#36454F",
                      fontWeight: "bold",
                    }}
                    variant="outlined"
                    onClick={() => {
                      if (
                        hotel?.accessControl?.ADMINRIGHTS === "ADMIN" ||
                        hotel?.accessControl?.ADMINRIGHTS === "SUBADMIN" ||
                        JSON.parse(
                          hotel?.accessControl?.user_feature_map
                            ?.UploadIdToCheckin
                        ).read !== "YES"
                      ) {
                        checkinBooking();
                      } else {
                        handleSpringDataRequest(
                          `core/api/v1/image/get-user-id-images?emailId=${selectedGuestEmail}`
                        )
                          .then((res) => {
                            if (
                              res.frontUrl === null ||
                              res.frontUrl === undefined ||
                              res.frontUrl === "" ||
                              res.backUrl === "" ||
                              res.backUrl === null ||
                              res.backUrl === undefined
                            ) {
                              toast.warning(
                                `${t("Please add user id images for checkin")}`
                              );
                            } else {
                              checkinBooking();
                            }
                          })
                          .catch((err) =>
                            toast.error(`${t("Something went wrong")}`)
                          );
                      }
                    }}
                  >
                    {t("Guest Check-in")}
                  </Button>
                )}
              {selectedEmailStatus === "ADMIN_CONFIRMED" &&
                reservationData?.balance_due === 0 &&
                JSON.parse(
                  hotel?.accessControl?.user_feature_map?.GuestCheckInCheckOut
                ).read === "YES" && (
                  <Button
                    style={{
                      height: "2.5rem",
                      //   display: "block",
                      //   marginLeft: "auto",
                      //   marginTop: "1.8rem",
                      marginRight: "1rem",
                      color: `${"#36454F"}`,
                      borderColor: "#36454F",
                      fontWeight: "bold",
                    }}
                    variant="outlined"
                    onClick={() => checkoutBooking()}
                  >
                    {t("Guest Check-out")}
                  </Button>
                )}
            </div>
          </form>
        </Grid>
      </CommonSlider>
      <div style={{ marginTop: "2rem" }}>
        <Modal show={show} onHide={handleClose} style={{ zIndex: "3000" }}>
          <Modal.Header closeButton>
            <Modal.Title>{t("Enter note for this customer")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TextField
              required
              id="outlined-required"
              label={t("Customer notes")}
              placeholder={t("Enter a note")}
              className="w-100"
              onChange={(e) => setcustomerNoteData(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {t("Close")}
            </Button>
            <Button variant="custom-button" onClick={createCustomerNote}>
              {loading ? (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              ) : (
                `${t("Save")}`
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={dshow} onHide={handleDClose} style={{ zIndex: "3000" }}>
          <Modal.Header closeButton>
            <Modal.Title>
              {t("Do you want to add Donot rent tag to this user")}
            </Modal.Title>
          </Modal.Header>
          <select
            className="form-select form-select-lg ml-4 mr-4"
            size="lg"
            style={{
              marginLeft: "3rem !important",
              marginRight: "3rem !important",
              heigth: "3rem !important",
            }}
            value={Severity}
            onChange={(e) => setSeverity(e.target.value)}
          >
            <option selected value="LOW">
              {t("LOW")}
            </option>
            <option value="MEDIUM"> {t("MEDIUM")}</option>

            <option value="HIGH">{t("HIGH")}</option>
            <option value="BLACKLIST">{t("BLACKLIST")}</option>
          </select>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleDClose}>
              {t("Close")}
            </Button>
            <Button variant="custom-button" onClick={createDoNotRentStatus}>
              {loading ? (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              ) : (
                `${t("Save")}`
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={rshow} onHide={handleRClose} style={{ zIndex: "3000" }}>
          <Modal.Header closeButton>
            <Modal.Title>
              {t("Do you want to remove Donot rent tag to this user")}
            </Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleRClose}>
              {t("Close")}
            </Button>
            <Button variant="custom-button" onClick={removeDoNotRentStatus}>
              {loading ? (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              ) : (
                `${t("Save")}`
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* delete Customer Note */}

        <Modal
          show={showDeleteModal}
          onHide={handleDeleteClose}
          style={{ zIndex: "3000" }}
        >
          <Modal.Header closeButton>
            <Modal.Title> {t("Delete customer note")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>
              {t(
                "Deleting these will not remove donot rent status of customer"
              )}
            </h5>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="custom-button"
              onClick={() => deletCustomerNotes()}
            >
              {t("Save")}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={updateNotesModal}
          onHide={handleNoteClose}
          style={{ zIndex: "3000" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              {t("Are you sure you want to remove donot rent?")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body></Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleDeleteClose}>
              {t("Close")}
            </Button>
            <Button variant="custom-button" onClick={() => UpdateDonotRent()}>
              {t("Save")}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showFrontImagePopup}
          onHide={handleshowFrontImagePopupClose}
          style={{ zIndex: "3000" }}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body style={{ width: 500, height: 300 }}>
            {showPopUpImages === "front" ? (
              ImagesData?.frontUrl !== "" ? (
                <img
                  src={ImagesData?.frontUrl}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    resizeMode: "cover",
                  }}
                />
              ) : (
                <></>
              )
            ) : ImagesData?.backUrl !== "" ? (
              <img
                src={ImagesData?.backUrl}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  resizeMode: "cover",
                }}
              />
            ) : (
              <></>
            )}
          </Modal.Body>
        </Modal>
        <Modal
          show={showFrontIdModal}
          onHide={handleCloseFrontIdModal}
          style={{ zIndex: "3000" }}
        >
          <Modal.Header closeButton>{t("Front id")} </Modal.Header>
          <Modal.Body>
            {!image ? (
              <div>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                />
              </div>
            ) : (
              <img src={image} alt="test-ilustartion" />
            )}
            <Button
              variant="custom-button"
              onClick={() => (!image ? capture() : setImage(undefined))}
              className="mt-4"
            >
              {!image ? `${t("Capture photo")}` : `${t("Retake photo")}`}
            </Button>
          </Modal.Body>
        </Modal>
        <Modal
          show={showBackIdModal}
          onHide={handleCloseBackIdModal}
          style={{ zIndex: "3000" }}
        >
          <Modal.Header closeButton>{t("Back id")}</Modal.Header>
          <Modal.Body>
            {!backImage ? (
              <div>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                />
              </div>
            ) : (
              <img src={backImage} alt="test-ilustartion" />
            )}
            <Button
              variant="custom-button"
              onClick={() =>
                !backImage ? backcapture() : setBackImage(undefined)
              }
              className="mt-4"
            >
              {!backImage ? `${t("Capture photo")}` : `${t("Retake photo")}`}
            </Button>
          </Modal.Body>
        </Modal>
        <Modal
          show={showPassportModal}
          onHide={handleClosePassportModal}
          style={{ zIndex: "3000" }}
        >
          <Modal.Header closeButton>{t("Passport Size Photo")} </Modal.Header>
          <Modal.Body>
            {!passportImage ? (
              <div>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                />
              </div>
            ) : (
              <img src={passportImage} alt="test-ilustartion" />
            )}
            <Button
              variant="custom-button"
              onClick={() =>
                !passportImage ? passportCapture() : setPassportImage(undefined)
              }
              className="mt-4"
            >
              {!passportImage
                ? `${t("Capture photo")}`
                : `${t("Retake photo")}`}
            </Button>
          </Modal.Body>
        </Modal>
        <Modal
          show={modalShow}
          onHide={handleModalClose}
          style={{ zIndex: "3000", width: "100%", height: "100%" }}
          dialogClassName={"myModalBodyCTAImg"}
          contentClassName={"myModalContentCTAImg"}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
            <Button
              className="mx-2"
              variant="secondary"
              onClick={() => downloadImage(imgURL)}
            >
              {t("Download")}
            </Button>
          </Modal.Header>
          <Modal.Body>
            <img
              src={imgURL}
              alt=""
              style={{
                marginRight: "1rem",
                marginLeft: "1rem",
                height: "28rem",
                width: "38rem",
              }}
            />
          </Modal.Body>
        </Modal>
        {/* Early checkout modal*/}
        <Modal
          show={showEarlyCheckout}
          onHide={closeEarlyCheckoutModal}
          style={{ zIndex: "3000" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("Early checkout alert")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Grid container>
              <Grid xs={12}>
                <p className="mb-2">
                  <b> {t("You are requesting an early check-out.")}</b>
                  {t(
                    " The system will automatically update the reservations check-out date to release the inventory."
                  )}
                </p>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={5} textAlign={"start"}>
                <Typography className={classes.displayHeading}>
                  {t("Guest name")}
                </Typography>
              </Grid>
              <Grid item xs={1} textAlign={"start"}>
                :
              </Grid>
              <Grid item xs={6} textAlign={"start"}>
                <Typography className={classes.secondaryHeading}>
                  {allGuestList?.filter((e) => e.email === selectedGuestEmail)
                    ?.length > 0
                    ? allGuestList?.filter(
                        (e) => e.email === selectedGuestEmail
                      )[0].name
                    : reservationData?.user_name}
                </Typography>
              </Grid>
              <Grid item xs={5} textAlign={"start"}>
                <Typography className={classes.displayHeading}>
                  {t("Check-in Date")}
                </Typography>
              </Grid>
              <Grid item xs={1} textAlign={"start"}>
                :
              </Grid>
              <Grid item xs={6} textAlign={"start"}>
                <Typography className={classes.secondaryHeading}>
                  {moment(
                    reservationData.booking_checkin.replace("T", " "),
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("MMM Do YYYY, h A")}
                </Typography>
              </Grid>
              <Grid item xs={5} textAlign={"start"}>
                <Typography className={classes.displayHeading}>
                  {t("Original Check-out Date")}
                </Typography>
              </Grid>
              <Grid item xs={1} textAlign={"start"}>
                :
              </Grid>
              <Grid item xs={6} textAlign={"start"}>
                <Typography className={classes.secondaryHeading}>
                  {moment(
                    reservationData.booking_checkout.replace("T", " "),
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("MMM Do YYYY, h A")}
                </Typography>
              </Grid>
              {/* <Grid item xs={5} textAlign={"start"}>
                <Typography className={classes.displayHeading}>
                  {t("New Check-out Date")}
                </Typography>
              </Grid>
              <Grid item xs={1} textAlign={"start"}>
                :
              </Grid>
              <Grid item xs={6} textAlign={"start"}>
                <Typography className={classes.secondaryHeading}>
                  {moment(newCheckoutDate).format("MMM Do YYYY, h A")}
                </Typography>
              </Grid> */}
            </Grid>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => closeEarlyCheckoutModal()}
            >
              {t("Close")}
            </Button>
            <Button
              variant="custom-button"
              onClick={() => handleEarlyCheckout()}
              disabled={earlyCheckoutLoader}
            >
              {earlyCheckoutLoader ? (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              ) : (
                `${t("Checkout booking")}`
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        {idGuruModal && (
          <IDguru
            show={idGuruModal}
            hide={handleCloseIdGuruModal}
            setselectedUserIdGuruData={setselectedUserIdGuruData}
          />
        )}
      </div>
    </div>
  );
};

export default NewBookingInformation;
