// ** React Imports
import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  Badge,
  Drawer,
  Stack,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Close, MoreVert } from "@mui/icons-material";
import { Modal } from "react-bootstrap";

import { makeStyles } from "@mui/styles";
import {
  cancelBooking,
  cancelReservation,
  getReservationInfo,
  getReservationInfoDjango,
  holdBooking,
  invalidateNightAudit,
  invalidateNightAuditReservation,
  lockReservation,
  setToNoShow,
  setToNoShowBookingDotCom,
  unassignReservation,
  updateCustomer,
  updateCustomerRes,
  voidCancellationCharges,
} from "../api/ReservationApi";
import { ReservationInfoModel } from "../models/RservationInfoModel";
import CustomerInformation from "./CustomerInformation";
import HoldBooking from "./HoldBooking";
import CancelBooking from "./CancelBooking";
import CustomerData from "./BookingInformation";
import CommonSlider from "./CommonSlider";
import ExtendEventSidebar from "./ExtendModalView";
import DragEventSidebar from "./DragModalView";
import DimmingDotsLoader from "./LoaderCustom";
import moment from "moment";
import { updateEnquiryStatus } from "../../BookingDashboard/apis/BookingDashboardApi";
import {
  channelLogoMapDash,
  checkIshaAccess,
  checkIshaUSAccess,
  customErrorMessage,
} from "../constants/Calendar-constants";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import SplitRoom from "./SplitRoom";

import {
  djangoHandleDataMutationRequest,
  djangoHandleDataRequests,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api";
import MoveRoom from "./MoveRoom";
import {
  getAllGuestUsers,
  getBookingUserDetails,
  getIshaGuestConfig,
  getSelectedGuest,
} from "../../Promocodes/apis/PromocodeApi";
import Notes from "./Notes";
import { getAllImagesAndUrl } from "../../BookingDashboard/apis/BrandingModalApi";
import {
  getAllCustomerNotes,
  getAllBookingNotes,
  deleteTheBookingNotes,
  addNewBookingNote,
} from "../api/BookingAndCustomerNotesApi";
import { useTranslation } from "react-i18next";
import i18n from "../../../modules/Auth/pages/i18n";
import axios from "axios";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Button,
  IconButton,
  Collapse,
} from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { connect, shallowEqual, useSelector } from "react-redux";
import PrintRegCard from "./PrintRegCard";
import SendConfirmationModal from "./SendConfirmationModal";
import GuestInformation from "../../Folios/FolioUpdateOptions/GuestInformation";

import * as AiIcons from "react-icons/ai";
import { getAllCurrentConfig } from "../../Folios/FolioUpdateOptions/EditAdultChildrenApi";
import PropTypes from "prop-types";
import NewPaymentModalEditView from "./NewPaymentModalEditView";
import PaymentModal from "./PaymentModal";
import BookingNotesModal from "./BookingNotesModal";
import NewBookingInformation from "./NewBookingInformation";
import EditIcon from "@mui/icons-material/Edit";
import NewGuestInfo from "../../Folios/FolioUpdateOptions/NewGuestInfo";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function NewEditModalView(props) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(props.selectedLanguage);
  }, [props.selectedLanguage]);
  let [reservationData, setReservationData] = useState(
    new ReservationInfoModel({})
  );
  let [loadingData, setLoadingData] = useState(true);

  let [load, setLoad] = useState(false);
  let [tabvalue, setTabValue] = useState("booking-information");
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(Boolean(anchorEl));
  const [menuValue, setMenuValue] = useState("");
  const [waiveNoShowFee, setwaiveNoShowFee] = useState("no");
  const [allGuestList, setAllGuestList] = useState([]);
  const [isEditable, setisEditable] = useState(true);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [magicLinkMail, setMagicLinkMail] = useState("");
  const [magicLinkPhone, setMagicLinkPhone] = useState("");
  const [anchorEls, setAnchorEls] = useState(null);
  const [menuOpens, setMenuOpens] = useState(Boolean(anchorEls));

  const classes = useStyles();
  const {
    lockEvent,
    viewFolio,
    eventData,
    handleAddEventSidebar,
    open,
    addEvent,
    reload,
    removeEvent,
    roomInformation,
    roomTypeInfo,
    setShowModal,
    setActionType,
    setShow,
    data,
  } = props;

  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );

  const hotelType = hotel.hotelType;
  const [subUser, setsubUser] = useState(hotel?.accessControl?.ADMINRIGHTS);
  const [hotelemail, sethotelemail] = useState(
    hotel?.accessControl?.hotel?.hotel_email
  );
  const [bookingId, setbookingId] = useState("");
  const currency = hotel.accessControl.hotel.hotel_currency;
  const [whiteLabelled, setWhiteLabelled] = useState("ENABLED");
  const [invoiceData, setInvoiceData] = useState(null);
  const [selectedReservationData, setselectedReservationData] = useState({});
  const [isNightAuditOn, setIsNightAuditOn] = useState(false);
  const [bookingUserData, setBookingUserData] = useState();
  const [otaLogo, setotaLogo] = useState("");
  const userBrandingSatus = async () => {
    try {
      const data = await getAllImagesAndUrl();
      if (data?.status === null || data?.status === undefined) {
        setWhiteLabelled("DISABLED");
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    userBrandingSatus();
  }, []);

  const whitlableArrMap = [
    `${t("STAYFLEXI_OD")}`,
    `${t("STAYFLEXI_OM")}`,
    `${t("STAYFLEXI MOBILE")}`,
    `${t("STAYFLEXI_GHA")}`,
    `${t("STAYFLEXI_BE")}`,
    `${t("STAYFLEXI_MOBILE")}`,
  ];

  const fetchGuestList = async (bookingId) => {
    const booking_data = await getBookingUserDetails(bookingId);
    setBookingUserData(booking_data);
    const data = await getAllGuestUsers(bookingId);
    setAllGuestList(data);
  };

  const fetchReservationData = async () => {
    setLoadingData(true);
    try {
      let data;
      if (`${process.env.REACT_APP_ENV}` === "LOCAL") {
        data = await getReservationInfo(
          eventData.event.extendedProps.reservationId
        );
      } else {
        data = await getReservationInfoDjango(
          eventData.event.extendedProps.reservationId
        );

        // handleSpringDataRequest(
        //   `core/api/v1/image/get-booking-channel-logo/?source=${data?.booking_source}`
        // ).then((data) => {
        //   setotaLogo(data);
        // });
        let logo = channelLogoMapDash[data?.booking_source];
        if (logo === undefined) logo = "";
        setotaLogo(logo);
      }
      setselectedReservationData(data);

      const temp = new ReservationInfoModel(data);
      setReservationData(temp);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingData(false);
    }
  };

  const getNightAuditStatus = () => {
    handleSpringDataRequest(`core/api/v1/nightAudit/get-night-audit-status`)
      .then((data) => {
        setIsNightAuditOn(data);
      })
      .catch((err) => console.error(err));
  };

  const reloadReservationData = async () => {
    try {
      let data;
      if (`${process.env.REACT_APP_ENV}` === "LOCAL") {
        data = await getReservationInfo(
          eventData.event.extendedProps.reservationId
        );
      } else {
        data = await getReservationInfoDjango(
          eventData.event.extendedProps.reservationId
        );
        // handleSpringDataRequest(
        //   `core/api/v1/image/get-booking-channel-logo/?source=${data?.booking_source}`
        // ).then((data) => {
        //   setotaLogo(data);
        // });
        let logo = channelLogoMapDash[data?.booking_source];
        if (logo === undefined) logo = "";

        if (
          whiteLabelled === "ENABLED" &&
          !whitlableArrMap.includes(data?.booking_source)
        ) {
          logo = "";
        }
        setotaLogo(logo);
      }

      const temp = new ReservationInfoModel(data);
      setReservationData(temp);
    } catch (e) {
      console.log(e);
    }
  };

  // handling the rollback booking

  // confirmation modal for handling the rollback of booking
  const [openRollbackBooking, setOpenRollbackBooking] = useState(false);
  const handleOpenRollbackBooking = () => setOpenRollbackBooking(true);
  const handleCloseRollbackBooking = () => setOpenRollbackBooking(false);
  const [rollbackLoader, setRollbackLoader] = useState(false);

  const handleRollbackBooking = (bookingId) => {
    // api call based on the status
    // if (invoiceData.invoice.status === "ADMIN_CONFIRMED") {
    setRollbackLoader(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_API_URL}core/api/v1/reservation/rollback-checkin-booking`,
        {
          bookingId: bookingId,
          resIds: [],
        }
      )
      // .then((res) =>
      //   toast.success("Status is changed to CONFIRMED successfully.")
      // )
      .then((res) => setRollbackLoader(false))
      .then((res) => handleCloseRollbackBooking())
      .then(() =>
        toast.success(`${t("Successfully rolled back")} ${bookingId}`)
      )
      .then((res) => {
        setTimeout(() => window.location.reload(), 1000);
      })
      .catch((err) =>
        toast.error(`${t("Error in changing the status to CONFIRMED.")}`)
      );
  };

  //Release Inventory
  const [openReleaseInventory, setOpenReleaseInventory] = useState(false);
  const handleOpenReleaseInventoryModal = () => setOpenReleaseInventory(true);
  const handleCloseReleaseInventoryModal = () => setOpenReleaseInventory(false);

  useEffect(() => {
    fetchReservationData();
    getNightAuditStatus();
    // fetchRoomTypeData(eventData.event.getResources()[0].extendedProps.roomTypeId)
  }, []);

  useEffect(() => {
    if (reservationData?.booking_id) {
      fetchGuestList(reservationData?.booking_id);
      getAllBookingNotesData();
      setbookingId(reservationData?.booking_id);
      //  fetchBookingUserDetail(reservationData?.booking_id);
    }
  }, [reservationData.booking_id]);
  useEffect(async () => {}, []);
  const [bookingNotes, setbookingNotes] = useState([]);
  const getAllBookingNotesData = async () => {
    try {
      const data = await getAllBookingNotes(reservationData.booking_id);
      setbookingNotes(data);
    } catch (e) {
      console.error(e);
    }
  };

  const deleteBookingNote = async (id, notes) => {
    const data = await deleteTheBookingNotes(
      reservationData.booking_id,
      id,
      localStorage.getItem("email"),
      notes
    )
      .then((data) => {
        toast.success(`${t("Successfully deleted the booking note")}`);
        getAllBookingNotesData();
      })
      .catch((err) => {
        toast.error(`${t("Something went wrong !")}`);
      });
  };

  const [openBlock, setOpenBlock] = useState(false);

  const [defaultCtadropdown, setDefaultdropdown] = useState([]);
  // handling the CTA dropdowns
  const [ctaDropdown, setCtaDropdown] = useState([]);
  function handleCtaDropdown() {
    // handleTrueWrapperMockLoader();
    setCtaDropdown([]);
    setDefaultdropdown([]);
    // if (Number(summaryData?.total_cta_balance) === 0) {
    // setCtaDropdown([
    //   {
    //     ctaName: "Walk-In",
    //     taxId: null,
    //   },
    // ]);
    djangoHandleDataRequests("invoice/getBookingSrcDropdownData/")
      .then((res) => {
        setDefaultdropdown(res);
        Number(summaryData?.total_cta_balance) === 0 && setCtaDropdown(res);
      })

      .catch((err) => toast.error(`${t("Error fethcing cta dropdown.")}`));
  }

  const getData = () => {
    handleSpringDataRequest(
      `core/api/v1/folio/invoice-data-render/?bookingId=${reservationData?.booking_id}&fromDb=false`
    )
      .then((res) => {
        setInvoiceData(res);
      })
      // .then((res) => fetchBookingData())
      .catch((err) => toast.error(`${t("Error fetching the details")}`));
  };

  useEffect(() => {
    if (
      reservationData.booking_id !== null &&
      reservationData.booking_id !== undefined &&
      reservationData.booking_id !== ""
    ) {
      handleCtaDropdown();
      getData();
      getCustomFolioConfig();
      handleGetSummaryDataWithCache();
      getCompanyDetails();
    }
  }, [reservationData.booking_id]);

  const envType = process.env.REACT_APP_ENV;

  const [customFolioConfig, setCustomFolioConfig] = useState(null);
  const getCustomFolioConfig = () => {
    djangoHandleDataRequests("invoice/getCustomFolioConfig/")
      .then((res) => setCustomFolioConfig(res !== null ? res : []))
      // .then((res) => fetchBookingData())
      .catch((err) =>
        toast.error(`${t("Error getting the folio config data")}`)
      );
  };

  /* PRINT RESERVATION */
  // Modal for printing Registration card
  // Giving an option to print normal Registration card or empty res card for hotels to fill out the details
  const [openResCard, setOpenResCard] = useState(false);
  const handleOpenResCard = () => setOpenResCard(true);
  const handleCloseResCard = () => setOpenResCard(false);

  // Checkbox to determine whether to print with details of fill up the details on own
  const [manualResCard, setManualResCard] = useState(false);
  const handleChangeManualResCard = (e) => {
    setManualResCard(e.target.checked);
  };

  const [hotelLogo, sethotelLogo] = useState(null);
  const handleBase64Img = () => {
    handleSpringDataRequest("core/api/v1/image/get-hotel-base64-logo/")
      .then((res) => sethotelLogo(res))
      .catch((err) => toast.error(`${t("Error in fethcing the hotel logo!")}`));
  };

  // Getting the hotel logo
  useEffect(() => {
    invoiceData && hotelLogo === null && handleBase64Img();
  }, [invoiceData, hotelLogo, hotel.hotelId]);

  // Get the data for invoice summary
  const [summaryData, setSummaryData] = useState(null);
  const handleGetSummaryDataWithCache = () => {
    setSummaryData(null);
    handleSpringDataRequest(
      `core/api/v1/folio/invoice-summary-data/?bookingId=${reservationData?.booking_id}&fromDB=false`
    )
      .then((res) => setSummaryData(res))

      // .then((res) => fetchBookingData())
      .catch((err) =>
        toast.error(`${t("Failed in getting the payment summary")}`)
      );
  };

  const [allCompanyDetails, setallCompanyDetails] = useState([]);

  const getCompanyDetails = () => {
    handleSpringDataRequest(`core/api/v1/guest-company-details/get-all`)
      .then((res) => setallCompanyDetails(res))
      .catch((err) => console.error(err));
  };

  const [openBookingConf, setOpenBookingConf] = useState(false);
  const handleOpenBookingConf = () => {
    setOpenBookingConf(true);
  };
  const handleCloseBookingConf = () => {
    setOpenBookingConf(false);
  };
  // handling booking confirmation email loading
  const [bookingConfLoading, setBookingConfLoading] = useState(false);

  // pre populating booking confirmation mail
  const [bookingConfMail, setBookingConfMail] = useState(
    invoiceData?.invoice.customer_email
  );

  useEffect(() => {
    setBookingConfMail(invoiceData?.invoice?.customer_email);
  }, [invoiceData]);

  const dateToEpoch = (dateString) => {
    const epochTime = moment.tz(dateString, data.hotelTimeZone).unix();
    return epochTime;
  };
  // Handle email booking confirmation
  function handleSendBookingConfirmation() {
    setBookingConfLoading(true);
    hotelType === "ISHA"
      ? handleSpringDataRequest(
          `core/api/v1/reservation/isha-send-email-confirmation/?bookingId=${bookingId}`
        )
          .then((res) =>
            toast.success(
              `${t("Successfully sent booking confirmation for")} ${bookingId}`
            )
          )
          .then((res) => setBookingConfLoading(false))
          .then((res) => handleCloseBookingConf())
          .catch((err) =>
            toast.error(err).catch((err) => setBookingConfLoading(false))
          )
      : invoiceData?.invoice?.is_enquiry === "true"
      ? // ? djangoHandleDataRequests(
        //     `email/sendEnquiryNotif?bookingId=${bookingId}&emailId=${bookingConfMail}&expiryTime=${dateToEpoch(
        //       invoiceData?.invoice?.enquiry_expiration_time
        //     )}`
        //   )
        //     .then((res) => toast.success("Enquiry mail sent successfully."))
        //     .catch((err) => toast.error(err))
        //     .finally(() => {
        //       setBookingConfLoading(false);
        //       handleCloseBookingConf();
        //     })
        handleSpringDataMutationRequest(
          "POST",
          "core/api/v1/reservation/sendEnquiryEmailCustomer",
          {
            bookingId: bookingId,
            emailId: bookingConfMail,
            expiryTime: dateToEpoch(
              invoiceData?.invoice?.enquiry_expiration_time
            ),
            source: invoiceData?.invoice.booking_source,
          }
        )
          .then((res) =>
            toast.success(`${t("Enquiry mail sent successfully.")}`)
          )
          .catch((err) => toast.error(err))
          .finally(() => {
            setBookingConfLoading(false);
            handleCloseBookingConf();
          })
      : handleSpringDataMutationRequest(
          "POST",
          `core/api/v1/reservation/${
            invoiceData?.invoice?.status === "CONFIRMED"
              ? "sendBookingConfirmationCust"
              : "sendCheckinConfirmationCust"
          }`,
          {
            booking_id: bookingId,
            email_id: bookingConfMail,
          }
        )
          .then((res) =>
            toast.success(
              `${t("Successfully sent booking confirmation for")} ${bookingId}`
            )
          )
          .then((res) => setBookingConfLoading(false))
          .then((res) => handleCloseBookingConf())
          .catch((err) =>
            toast.error(err).catch((err) => setBookingConfLoading(false))
          );
  }
  const displayFalse = () => {
    setMenuValue("");
  };
  const holdExisting = async (params) => {
    setLoad(true);
    try {
      await holdBooking(params);
      await invalidateNightAudit(params.bookingId);
      removeEvent();
      toast.success(
        `${t("Successfully placed")} ${params?.bookingId} ${t("on hold")}`
      );
    } catch (e) {
      const message = e.message ? e.message : customErrorMessage;
      toast.error(
        typeof message === "string" || message instanceof String
          ? message
          : customErrorMessage
      );
      console.error(e);
      handleAddEventSidebar();
    }
  };

  const cancelExisting = async (type, params, voidParams) => {
    if (voidParams.voidType === "") {
      toast.error(`${"Please select one option to cancel booking"}`);
    } else {
      setLoad(true);
      try {
        if (type) {
          // console.log("voidParams1 : ", voidParams, params)
          let isApplyRefund = voidParams?.voidType === "APPLY_REFUND";
          // console.log("isApplyRefund : ", isApplyRefund)
          params["applyCancellationPolicy"] = isApplyRefund;
          await cancelBooking(params).then(() =>
            toast.success(`${"Successfully cancelled"} ${params.bookingId}`)
          );
          await invalidateNightAudit(params.bookingId);
          if (!isApplyRefund) {
            await voidCancellationCharges(voidParams);
          }
          removeEvent();
        } else {
          // console.log("voidParams2 : ", voidParams, params)
          let isApplyRefund = voidParams?.voidType === "APPLY_REFUND";
          // console.log("isApplyRefund : ", isApplyRefund)
          await cancelReservation(
            params,
            isApplyRefund,
            reservationData?.booking_id
          ).then(() =>
            toast.success(`${"Successfully cancelled"} ${params.bookingId}`)
          );
          if (!isApplyRefund) {
            await voidCancellationCharges(voidParams);
          }
          removeEvent();
        }
      } catch (e) {
        const message = e.message ? e.message : customErrorMessage;
        toast.error(
          typeof message === "string" || message instanceof String
            ? message
            : customErrorMessage
        );
        console.error(e);
        handleAddEventSidebar();
      }
    }
  };

  const unassign = async (params) => {
    setLoad(true);
    try {
      await unassignReservation(params);
      // await invalidateNightAuditReservation(params.reservationId);
      removeEvent();
      toast.success(
        `${t("Successfully unassigned room for")} ${
          reservationData?.booking_id
        }`
      );
    } catch (e) {
      const message = e.message ? e.message : customErrorMessage;
      toast.error(
        typeof message === "string" || message instanceof String
          ? message
          : customErrorMessage
      );
      console.error(e);
    } finally {
      handleAddEventSidebar();
    }
  };

  const setLock = async (params) => {
    setLoad(true);
    try {
      await lockReservation(params).then(() => {
        lockEvent(params.status);
        toast.success(
          `Successfully ${params?.status} ${params?.reservationId}`
        );
      });
    } catch (e) {
      const message = e.message ? e.message : customErrorMessage;
      toast.error(
        typeof message === "string" || message instanceof String
          ? message
          : customErrorMessage
      );
      console.error(e);
      handleAddEventSidebar();
    }
  };

  const setNoShow = async (params, voidParams) => {
    if (voidParams.voidType === "") {
      toast.error(`${"Please select any value"}`);
    } else {
      let isApplyRefund = voidParams?.voidType === "APPLY_REFUND";
      // params["applyCancellationPolicy"] = isApplyRefund;
      // if (!isApplyRefund) {
      //   await voidCancellationCharges(voidParams);
      // }
      if (reservationData.booking_source === "BOOKING") {
        setLoad(true);
        try {
          await setToNoShowBookingDotCom(
            params.bookingId,
            params.isReservation,
            "booking",
            waiveNoShowFee,
            isApplyRefund
          ).then(() =>
            toast.success(
              `${"Successfully marked  NO SHOW"} ${params?.bookingId}`
            )
          );
          if (!isApplyRefund) {
            await voidCancellationCharges(voidParams);
          }
          await invalidateNightAudit(params.bookingId);
          removeEvent();
        } catch (e) {
          const message = e.message ? e.message : customErrorMessage;
          toast.error(
            typeof message === "string" || message instanceof String
              ? message
              : customErrorMessage
          );
          console.error(e);
          handleAddEventSidebar();
        }
      } else {
        setLoad(true);
        try {
          await setToNoShow(
            params.bookingId,
            params.isReservation,
            "booking",
            isApplyRefund
          ).then(() =>
            toast.success(
              `${"Successfully marked  NO SHOW"}${params?.bookingId}`
            )
          );
          if (!isApplyRefund) {
            await voidCancellationCharges(voidParams);
          }
          await invalidateNightAudit(params.bookingId);
          removeEvent();
        } catch (e) {
          const message = e.message ? e.message : customErrorMessage;
          toast.error(
            typeof message === "string" || message instanceof String
              ? message
              : customErrorMessage
          );
          console.error(e);
          handleAddEventSidebar();
        }
      }
    }
  };

  const returnDataObj = (type) => {
    const temp_obj = Object.assign({}, eventData);
    temp_obj.oldEvent = {};
    if (type === "start") temp_obj.oldEvent.startStr = "";
    else if (type === "move") {
      const temp_id = eventData.event.getResources()[0].id;
      temp_obj.oldEvent.startStr = "";
      temp_obj.oldEvent.endStr = "";
      temp_obj.oldEvent.getResources = () => {
        return [{ id: temp_id }];
      };
      // let temp_date = temp_obj.event.start
      // let temp_end = temp_obj.event.end
      // temp_date.setDate(temp_date.getDate() - 1)
      // temp_end.setDate(temp_end.getDate() + 1)
      // temp_obj.oldEvent.setDates(temp_date, temp_end)
    } else temp_obj.oldEvent.startStr = temp_obj.event.startStr;
    return temp_obj;
  };

  const checkinCheckout = (data) => {
    eventData.event.setDates(data.start, data.end);
    handleAddEventSidebar();
    reload();
  };

  const [selectedCustomerNotes, setSelectedCustomerNotes] = useState(null);

  const [allGuestUsers, setallGuestUsers] = useState([]);
  useEffect(async () => {
    if (
      bookingId &&
      bookingId !== null &&
      bookingId !== undefined &&
      bookingId !== ""
    ) {
      await getAllData();
    }
  }, [bookingId]);

  const getAllData = async () => {
    try {
      const data = await getAllGuestUsers(bookingId);
      setallGuestUsers(data);
    } catch (e) {
      toast.error(e);
    }
  };

  //Get selcted guest info
  const [selectedGuestInfo, setselectedGuestInfo] = useState(null);
  const [selectedIshaConfig, setSelectedIshaConfig] = useState(null);
  const [resCurrentConfig, setResCurrentConfig] = useState(null);

  const getAllSelectedUsersData = async (email) => {
    try {
      let lowercase_email = String(email).toLowerCase();
      const data = await getSelectedGuest(lowercase_email, bookingId);

      setselectedGuestInfo(data);
    } catch (e) {
      toast.error(e);
    }
  };

  // guest right drawer
  const [gueststate, setguestState] = useState({
    right: false,
  });

  const [value, setValue] = useState(0);
  const [selectedUserResId, setselectedUserResId] = useState("");
  const [selectedGuestStatus, setselectedGuestStatus] = useState("");
  const [invoiceHeader, setInvoiceHeader] = useState("Folio");
  const [UserIdImages, setUserIdImages] = useState();
  const handleInvoiceHeader = () => {
    if (
      customFolioConfig?.booking_invoice_header &&
      !customFolioConfig?.booking_invoice_header
        .toLowerCase()
        .includes("null", "n/a")
    ) {
      return setInvoiceHeader(customFolioConfig?.booking_invoice_header);
    } else {
      return setInvoiceHeader("Folio");
    }
  };

  useEffect(() => {
    handleInvoiceHeader();
  }, [customFolioConfig]);

  const toggleGuestDrawer =
    (anchor, open, email, status, selectedResId) => async (event) => {
      setselectedGuestStatus(status);
      setselectedUserResId(selectedResId);
      if (email === "test") {
        setselectedGuestInfo(null);
        setSelectedCustomerNotes(null);
      } else if (email !== undefined) {
        await getAllGuestNotes(email);
        await getAllSelectedUsersData(email);
        if (
          JSON.parse(
            data?.accessControl?.user_feature_map?.IshaExtraGuestDetails
          ).read === "YES" &&
          (checkIshaAccess(data?.emailId) || checkIshaUSAccess(data?.emailId))
        ) {
          await getIshaGuestConfigData(email);
        }
      }
      await getCurrentResConfig();
      //to render only when guest drawer opens
      // if (open) {
      //   const dataUser = await getUserId(email);
      //   setUserIdImages(dataUser);
      // }
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      setguestState({ ...gueststate, [anchor]: open });
    };

  const closeDrawer = () => {
    console.log("sasas");
    getAllData();
    fetchGuestList(reservationData?.booking_id);
    setguestState({ ...gueststate, ["right"]: false });
  };

  console.log("gueststate", gueststate);

  const getCurrentResConfig = async () => {
    const data = await getAllCurrentConfig(bookingId);
    setResCurrentConfig(data);
  };
  const getIshaGuestConfigData = async (email) => {
    try {
      const data = await getIshaGuestConfig(email);

      setSelectedIshaConfig(data);
    } catch (e) {
      toast.error(e);
    }
  };

  const [selectedRooms, setselectedRooms] = useState([]);
  const handleRate = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length > 0)
      setselectedRooms(
        // On autofill we get a the stringified value.
        typeof value === "string" ? value.split(",") : value
      );
  };
  const [reservationIds, setreservationIds] = useState(null);
  useEffect(() => {
    handleSpringDataRequest(
      `core/api/v1/reservation/fetch-reservation-ids?bookingId=${bookingId}`,
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        setreservationIds(res);
        setselectedRooms([Object.keys(res)[0]]);
      })
      .catch((err) => console.log(err));
  }, [bookingId]);

  const getAllGuestNotes = async (email) => {
    let lowercase_email = String(email).toLowerCase();
    handleSpringDataRequest(
      `core/api/v1/do-not-rent/get-all?emailId=${lowercase_email}`
    )
      .then((res) => {
        setSelectedCustomerNotes(res);
      })
      .catch((err) => {
        console.log("The err is : ", err);
      });
  };
  const guestList = (anchor) => (
    <Box
      sx={{ width: 800 }}
      // role="presentation"
    >
      <div className="folioUpdateWrapper">
        <div className="folioInformationActionWrapper">
          {invoiceData && (
            <>
              <div className="folioInformation">
                <div className="fId">{bookingId}</div>
                <div className="bookingSrc">
                  {/* {invoiceData?.invoice.booking_source_displayname} */}
                  {t("Guest information")}
                  {selectedCustomerNotes?.length > 0 &&
                    selectedCustomerNotes[0]?.do_not_rent === "YES" && (
                      <>
                        <div
                          className="badge badge-danger ml-4"
                          style={{ fontSize: "11px" }}
                        >
                          {t("Do-Not Rent")}
                        </div>
                        {selectedCustomerNotes[0]?.severity === "LOW" && (
                          <div
                            className="badge badge-info ml-2"
                            style={{ fontSize: "11px" }}
                          >
                            {t("LOW")}
                          </div>
                        )}
                        {selectedCustomerNotes[0]?.severity === "MEDIUM" && (
                          <div
                            className="badge badge-warning ml-2"
                            style={{ fontSize: "11px" }}
                          >
                            {t("MEDIUM")}
                          </div>
                        )}
                        {selectedCustomerNotes[0]?.severity === "HIGH" && (
                          <div
                            className="badge badge-dark ml-2"
                            style={{ fontSize: "11px" }}
                          >
                            {t("HIGH")}
                          </div>
                        )}
                        {selectedCustomerNotes[0]?.severity === "BLACKLIST" && (
                          <div
                            className="badge badge-danger ml-2"
                            style={{ fontSize: "11px" }}
                          >
                            {t("BLACKLIST")}
                          </div>
                        )}
                      </>
                    )}
                </div>
              </div>
              <div
                className="closeToggleDrawer"
                onClick={toggleGuestDrawer("right", false)}
              >
                <AiIcons.AiOutlineClose size="20px" color="black" />
              </div>
            </>
          )}

          {/* <Divider /> */}
        </div>
        <Divider />
        <div className="folioTabs">
          <TabPanel value={value} index={0} className="tabsContent">
            {invoiceData && (
              <NewGuestInfo
                hotelId={hotel.hotelId}
                bookingId={bookingId}
                onCancelClick={() => closeDrawer("right", false)}
                updateData={() => closeDrawer("right", false)}
                roomIdList={invoiceData?.invoice?.room_ids_list}
                datas={selectedGuestInfo}
                customerNotes={selectedCustomerNotes}
                getCustomerNotes={getAllGuestNotes}
                guestDataList={getAllData}
                ishaGuestData={selectedIshaConfig}
                UserImagesData={UserIdImages}
                emailId={invoiceData?.invoice.customer_email}
                reservationIds={reservationIds}
                reservationInfoData={
                  invoiceData?.invoice?.reservation_room_info_list
                }
                allGuestsList={allGuestUsers}
                resCurrentConfig={resCurrentConfig}
                selectedGuestStatus={selectedGuestStatus}
                updateInvoice={() => closeDrawer("right", false)}
                balanceDue={summaryData?.balance_due}
                selectedUserResId={selectedUserResId}
                invoiceData={invoiceData}
                selectedLanguage={props.selectedLanguage}
              />
            )}
          </TabPanel>
        </div>
      </div>
    </Box>
  );

  console.log("allGuestList", allGuestList);

  const [openSettleModal, setopenSettleModal] = useState(false);

  const loggedUserEmail = localStorage.getItem("logedUserEmail");
  const [offlineCardPayLoading, setOfflineCardPayLoading] = useState(false);

  // accepting cash payment modal
  const [openCashPayment, setOpenCashPayment] = useState(false);
  const handleOpenCashPayment = () => {
    if (
      hotelType === "ISHA" &&
      Number(reservationData.total_amount_with_services).toFixed(2) > 100000
    ) {
      toast.error(
        `${t("Cannot take cash transaction of more than 100000 in cash")}`
      );
    } else {
      if (hotelType === "ISHA") {
        toast.error(
          `${t(
            "Please ensure to preview the E-Receipt before accepting the payment"
          )}`
        );
      }
      setOpenCashPayment(true);
    }
  };

  // handling the format in which data is being sent
  const handleSentDateFormat = (recordDate) => {
    const currentDate = new Date();
    if (
      moment(currentDate).format("YYYY-MM-DD") ==
      moment(recordDate).format("YYYY-MM-DD")
    ) {
      return moment(recordDate).format("YYYY-MM-DD HH:mm:ss");
    }
    return moment(recordDate).format("YYYY-MM-DD") + " 00:00:00";
  };

  // cash payment loading
  const [cashPayLoading, setCashPayLoading] = useState(false);
  const [amount, setAmount] = useState(
    Number(reservationData.balance_due).toFixed(2)
  );
  // cheque pay loading
  const [offlineCheckPayLoading, setOfflineCheckPayLoading] = useState(false);
  const [offlineCardAmt, setOfflineCardAmt] = useState(
    Number(reservationData.balance_due).toFixed(2)
  );
  const [paymentType, setpaymentType] = useState("");
  const [offlineCheckDate, setOfflineCheckDate] = useState(new Date());
  const [offlineCashDate, setOfflineCashDate] = useState(new Date());
  const [chequeDesc, setChequeDesc] = useState();
  const [serviceAmountDesc, setServiceAmountDesc] = useState();
  const [offlineCardDesc, setOfflineCardDesc] = useState();
  const handleCloseCashPayment = () => setOpenCashPayment(false);
  const handleCloseOfflineCardPay = () => {
    setOpenOfflineCardPay(false);
  };

  const handleCashPayment = () => {
    setCashPayLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordCashPayment/`,
        {
          hotelId: hotel.hotelId,
          bookingID: reservationData.booking_id,
          reservation_id: "",
          amount: amount,
          offline_card_date: handleSentDateFormat(offlineCashDate),
          service_amount_desc: serviceAmountDesc,
          logged_user_email: loggedUserEmail,
        }
      )
      .then((res) =>
        toast.success(`${t("Cash payment of")} ${amount} ${t("recorded")}`)
      )
      .then((res) => setCashPayLoading(false))
      .then((res) => setAmount(""))
      .then((res) => setServiceAmountDesc())
      .then((res) => {
        handleCloseCashPayment();
        setOpenPaymentModal(false);
      })

      .catch((error) => setCashPayLoading(false))
      .catch((error) => {
        handleCloseCashPayment();
        setOpenPaymentModal(false);
      })
      .catch((error) => toast.error(error));
  };

  // UPI payment modal loading
  const [openUpi, setOpenUpi] = useState(false);
  const handleOpenUpiPay = () => setOpenUpi(true);
  const handleCloseUpiPay = () => setOpenUpi(false);
  const [upiPayLoading, setUpiPayLoading] = useState(false);

  const [upiDate, setUpiDate] = useState(new Date());

  const [upiAmt, setUpiAmt] = useState(reservationData?.balance_due);
  const [upiDesc, setUpiDesc] = useState("");

  const handleUpiPayment = () => {
    setUpiPayLoading(true);
    axios
      .post(`${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordUPIPayment/`, {
        hotelId: hotel?.hotelId,
        bookingID: reservationData?.booking_id,
        reservation_id: "",
        amount: Number(upiAmt),
        offline_upi_date: handleSentDateFormat(upiDate),
        service_amount_desc: upiDesc,
        logged_user_email: loggedUserEmail,
      })
      // .then((res) => updateSummaryDataFromCache())
      .then((res) =>
        toast.success(
          `${t("UPI payment of")} ${upiAmt} ${t("recorded for")} ${
            reservationData?.booking_id
          }!`
        )
      )

      .then((res) => setUpiPayLoading(false))
      .then((res) => setUpiAmt(""))
      .then((res) => setUpiDate(new Date()))
      .then((res) => setUpiDesc(""))
      .then((res) => {
        handleCloseUpiPay();
        setOpenPaymentModal(false);
      })
      .catch((err) => toast.error(err))
      .then((err) => setUpiPayLoading(false))
      .then((err) => {
        handleCloseUpiPay();
        setOpenPaymentModal(false);
      });
  };

  // Other payment mode modal loading
  const [openOtherModePay, setOpenOtherModePay] = useState(false);
  const handleOpenOtherModePay = () => setOpenOtherModePay(true);
  const handleCloseOtherModePay = () => setOpenOtherModePay(false);
  const [otherModePayLoading, setOtherModePayLoading] = useState(false);

  // Bank transfer payment mode modal
  const [openBankTransferModePay, setOpenBankTransferModePay] = useState(false);
  const handleOpenBankTransferModePay = () => setOpenBankTransferModePay(true);
  const handleCloseBankTransferModePay = () =>
    setOpenBankTransferModePay(false);

  const [bankTransferModePayLoading, setBankTransferModePayLoading] =
    useState(false);

  const [bankTransferModeDate, setBankTransferModeDate] = useState(new Date());

  const [bankTransferModeAmt, setBankTransferModeAmt] = useState(
    reservationData?.balance_due
  );
  const [bankTransferModeDesc, setBankTransferModeDesc] = useState("");

  const [otherModeDate, setOtherModeDate] = useState(new Date());

  const [otherModeAmt, setOtherModeAmt] = useState(
    reservationData?.balance_due
  );
  const [otherModeDesc, setOtherModeDesc] = useState("");

  const handleBankTransferPayment = () => {
    setBankTransferModePayLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordBankTransferPayment/`,
        {
          hotelId: hotel?.hotelId,
          bookingID: reservationData?.booking_id,
          reservation_id: "",
          amount: bankTransferModeAmt,
          bank_transfer_date: handleSentDateFormat(bankTransferModeDate),
          service_amount_desc: bankTransferModeDesc,
          logged_user_email: loggedUserEmail,
        }
      )
      .then((res) =>
        toast.success(
          `${t("Bank transfer payment of")} ${bankTransferModeAmt} ${t(
            "recorded for"
          )} ${reservationData?.booking_id}!`
        )
      )
      .catch((err) => toast.error(err))
      .finally(() => setBankTransferModeAmt(""))
      .finally(() => setBankTransferModeDate(new Date()))
      .finally(() => setBankTransferModeDesc(""))
      .finally(() => {
        handleCloseBankTransferModePay();
        setOpenPaymentModal(false);
      })
      .finally(() => setBankTransferModePayLoading(false));
  };

  const handleOtherModePayment = () => {
    setOtherModePayLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordOtherPayment/`,
        {
          hotelId: hotel?.hotelId,
          bookingID: reservationData?.booking_id,
          reservation_id: "",
          amount: otherModeAmt,
          other_payment_date: handleSentDateFormat(otherModeDate),
          service_amount_desc: otherModeDesc,
          logged_user_email: loggedUserEmail,
        }
      )
      .then((res) =>
        toast.success(
          `${t("Other payment of")} ${otherModeAmt} ${t("recorded for")} ${
            reservationData?.booking_id
          }!`
        )
      )

      .then((res) => setOtherModePayLoading(false))
      .then((res) => setOtherModeAmt(""))
      .then((res) => setOtherModeDate(new Date()))
      .then((res) => setOtherModeDesc(""))
      .then((res) => {
        handleCloseOtherModePay();
        setOpenPaymentModal(false);
      })
      .catch((err) => setOtherModePayLoading(false))
      .catch((err) => {
        handleCloseOtherModePay();
        setOpenPaymentModal(false);
      })
      .catch((err) => toast.error(err));
  };

  // paymentLink modal

  const [openSendPaymentLink, setOpenSendPaymentLink] = useState(false);
  const handleOpenSendPaymentLink = () => setOpenSendPaymentLink(true);
  const handleCloseSendPaymentLink = () => setOpenSendPaymentLink(false);

  // cash deposit modal

  const [openCashDeposit, setOpenCashDeposit] = useState(false);
  const handleOpenCashDeposit = () => setOpenCashDeposit(true);
  const handleCloseCashDeposit = () => setOpenCashDeposit(false);
  const [offlineCheckAmt, setOfflineCheckAmt] = useState(
    reservationData?.balance_due
  );

  // handling cash deposit date format
  const handleOpenCashDepositDate = (cashDepositDate) => {
    const currentDate = new Date();
    if (
      moment(currentDate).format("YYYY-MM-DD") ==
      moment(cashDepositDate).format("YYYY-MM-DD")
    ) {
      return moment(cashDepositDate).format("MMM DD YYYY HH:mm:ss");
    }
    return moment(cashDepositDate).format("MMM DD YYYY") + " 00:00:00";
  };
  // deposit loading
  const [depositLoading, setDepositLoading] = useState(false);
  // handling the cash deposit

  const handleDeposit = () => {
    setDepositLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/addServiceToInvoice/`,
        {
          hotelId: hotel?.hotelId,
          booking_id: reservationData?.booking_id,
          reservation_id: "",
          service_item: {
            service_date: handleOpenCashDepositDate(depositDate),
            service_amount: depositAmt,
            service_desc: depositName,
            service_amount_type: "DEPOSIT",
          },
          logged_user_email: loggedUserEmail,
        }
      )
      .then((res) => toast.success(`${t("Deposit recorded successfully.")}`))

      .then((res) => setDepositLoading(false))
      .then((res) => setDepositAmt(""))
      .then((res) => setDepositDate(new Date()))
      .then((res) => setDepositName())
      .then((res) => {
        handleCloseCashDeposit();
        setOpenPaymentModal(false);
      })
      .catch((error) => setDepositLoading(false))
      .catch((error) => {
        handleCloseCashDeposit();
        setOpenPaymentModal(false);
      })
      .catch((error) => toast.error(error));
  };

  // fields for for cash deposit

  const [depositAmt, setDepositAmt] = useState(0);
  const [depositDate, setDepositDate] = useState(new Date());
  const [depositName, setDepositName] = useState();
  const [offlineCardDate, setOfflineCardDate] = useState(new Date());
  const handleCloseOfflineCheckPay = () => setOpenOfflineCheckPay(false);

  const [openOfflineCardPay, setOpenOfflineCardPay] = useState(false);
  const handleOpenOfflineCardPay = () => {
    if (hotelType === "ISHA") {
      toast.error(
        `${t(
          "Please ensure to preview the E-Receipt before accepting the payment"
        )}`
      );
    }
    setOpenOfflineCardPay(true);
  };

  const handleOfflineCardPayment = () => {
    const payload = {
      hotelId: hotel?.hotelId,
      bookingID: reservationData.booking_id,
      reservation_id: "",
      amount: offlineCardAmt,
      offline_card_date: handleSentDateFormat(offlineCardDate),
      service_amount_desc: offlineCardDesc,
      logged_user_email: loggedUserEmail,
    };

    setOfflineCardPayLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordOfflineCardPayment/`,
        payload
      )
      .then((res) =>
        toast.success(
          `${t("Card payment of")} ${offlineCardAmt} ${t("recorded for")} ${
            reservationData.booking_id
          }!`
        )
      )

      .then((res) => setOfflineCardPayLoading(false))
      .then((res) => setOfflineCardAmt(""))
      .then((res) => setOfflineCardDate(new Date()))
      .then((res) => setOfflineCardDesc())
      .then((res) => {
        handleCloseOfflineCardPay();
        setOpenPaymentModal(false);
      })
      .catch((err) => setOfflineCardPayLoading(false))
      .catch((err) => {
        handleCloseOfflineCardPay();
        setOpenPaymentModal(false);
      })
      .catch((err) => toast.error(err));
  };

  // handling the offline cheque payment flows

  const handleOfflineCheckPayment = () => {
    setOfflineCheckPayLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordOfflineCheckPayment/`,
        {
          hotelId: hotel?.hotelId,
          booking_id: reservationData?.booking_id,
          reservation_id: "",
          amount: offlineCheckAmt,
          offline_card_date: handleSentDateFormat(offlineCardDate),
          logged_user_email: loggedUserEmail,
          service_amount_desc: chequeDesc,
        }
      )
      .then((res) =>
        toast.success(
          `${t("Offline cheque payment of")} ${offlineCheckAmt} ${t(
            "recorded"
          )}`
        )
      )

      .then((res) => setOfflineCheckPayLoading(false))
      .then((res) => setOfflineCheckAmt(""))
      .then((res) => setOfflineCheckDate(new Date()))
      .then((res) => setChequeDesc())
      .then((res) => {
        handleCloseOfflineCheckPay();
        setOpenPaymentModal(false);
      })
      .catch((error) => toast.error(error))
      .then((res) => setOfflineCheckPayLoading(false))
      .then((res) => {
        handleCloseOfflineCheckPay();
        setOpenPaymentModal(false);
      })
      .then((res) => setOfflineCheckAmt(""))
      .then((res) => setOfflineCheckDate(new Date()))
      .then((res) => setChequeDesc());
  };

  // paymentLink loading
  const [paymentLinkLoading, setPaymentLinkLoading] = useState(false);
  const [paymentLinkEmail, setPaymentLinkEmail] = useState(
    reservationData?.user_email
  );
  const [paymentLinkMobile, setPaymentLinkMobile] = useState(
    reservationData?.user_contact
  );
  const [paymentLinkAmt, setPaymentLinkAmt] = useState(
    reservationData?.balance_due
  );
  const [openOfflineCheckPay, setOpenOfflineCheckPay] = useState(false);
  const handleOpenOfflineCheckPay = () => setOpenOfflineCheckPay(true);
  const [hideCheckin, sethideCheckin] = useState(false);

  // sending customer payment link
  function handlePaymentLink() {
    setPaymentLinkLoading(true);
    djangoHandleDataMutationRequest(
      "POST",
      "invoice/sendInvoicePaymentCustEmail/",
      {
        hotel_id: hotel?.hotelId,
        booking_id: reservationData?.booking_id,
        reservation_id: null,
        email: paymentLinkEmail,
        phone: paymentLinkMobile,
        amount: paymentLinkAmt,
        logged_user_email: loggedUserEmail,
      }
    )
      .then((res) =>
        toast.success(`${t("Payment link sent to the customer successfully.")}`)
      )

      .then((res) => setPaymentLinkLoading(false))
      .then((res) => {
        handleCloseSendPaymentLink();
        setOpenPaymentModal(false);
      })
      .catch((err) => setPaymentLinkLoading(false))
      .catch((err) => toast.error(err));
  }

  useEffect(async () => {
    if (eventData.event.extendedProps.reservationId) {
      const data = await getReservationInfoDjango(
        eventData.event.extendedProps.reservationId
      );
      setMagicLinkMail(data.user_email);
      setMagicLinkPhone(data.user_contact);
      setUpiAmt(data.balance_due);
      setAmount(data.balance_due);
      setOfflineCardAmt(data.balance_due);
      setBankTransferModeAmt(data.balance_due);
      setOtherModeAmt(data.balance_due);
      setOfflineCheckAmt(data.balance_due);
      setPaymentLinkAmt(data.balance_due);
    }
  }, [eventData.event.extendedProps.reservationId]);

  const [ctaAmt, setCtaAmt] = useState(reservationData?.balance_due);
  const [ctaDesc, setCtaDesc] = useState("");
  const [ctaLoader, setCtaLoader] = useState(false);
  const [settleCtaDate, setSettleCtaDate] = useState(new Date());

  // perform EMV transaction loading
  const [performEMVTransactionLoading1, setPerformEMVTransactionLoading1] =
    useState(false);
  const [performEMVTransactionLoading2, setPerformEMVTransactionLoading2] =
    useState(false);
  const [performEMVTransactionLoading3, setPerformEMVTransactionLoading3] =
    useState(false);

  const [isAdmin, setisAdmin] = useState(
    hotel?.accessControl?.ADMINRIGHTS === "ADMIN"
  );

  const [paymentViewAccess, setpaymentViewAccess] = useState(
    JSON.parse(hotel?.accessControl?.user_feature_map?.PaymentViewAccess)
      .read === "YES"
      ? "YES"
      : "NO"
  );

  //STRIPE ONLINE PAYMENT INTENT CREATION
  const [stripeAccount, setStripeAccount] = useState(
    hotel?.accessControl?.hotel?.pg_account_id
  );
  const [savedCards, setSavedCards] = useState(null);
  const getSavedCards = () => {
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/customer-card/get-saved-customer-card?bookingId=${reservationData?.booking_id}`,
      { email: invoiceData?.invoice?.customer_email }
    )
      .then((res) => setSavedCards(res))
      // .then((res) => fetchBookingData())
      .catch((err) => toast.error(err));
  };

  useEffect(() => {
    if (invoiceData?.invoice?.customer_email) {
      getSavedCards();
    }
  }, [reservationData?.booking_id, invoiceData?.invoice?.customer_email]);

  const [customPgData, setCustomPgData] = useState(null);
  const getCustomPgData = () => {
    djangoHandleDataRequests(`payments/getHotelCustomPgInfo/`)
      .then((res) => setCustomPgData(res !== null ? res : []))
      // .then((res) => fetchBookingData())
      .catch((err) => toast.error(err));
  };

  // fields for for perform EMV transaction
  const [performEMVTransactionAmt, setPerformEMVTransactionAmt] = useState();
  const [performEMVTransactionSaveCard, setPerformEMVTransactionSaveCard] =
    useState(true);
  const [emvTerminalId, setEmvTerminalId] = useState(null);
  useEffect(() => {
    const terminalIds = () => {
      if (customPgData && customPgData?.hasOwnProperty("terminals")) {
        setEmvTerminalId(customPgData?.terminals[0].id);
      }
    };
    terminalIds();
  }, [customPgData, hotel.hotelId]);

  const enablePerformEMVTxn = ["FORTIS", "TSYS", "SHIFT4", "STRIPE"].includes(
    customPgData?.active_pg_name
  );

  const enableAddCard = ["FORTIS", "TSYS", "SHIFT4", "STRIPE"].includes(
    customPgData?.active_pg_name
  );
  // Disabling the flow untill reports is fixed - Sasank (commit by arunesh-kumar-sf)
  const enableRefundCard = false;
  const custom_pg_name = customPgData?.active_pg_name;
  const shift4_utg_url = customPgData?.shift4_utg_url;
  const shift4_access_token = customPgData?.shift4_access_token;

  // OTA BOOKING INFO DATAs
  // Get the OTA booking info data
  const [otaBookingInfoData, setOtaBookingInfoData] = useState([]);
  const getOtaBookingInfoData = () => {
    djangoHandleDataRequests(
      `invoice/getOTADetailsForBooking/?bookingID=${reservationData?.booking_id}`
    )
      .then((res) => setOtaBookingInfoData(res !== null ? res : []))
      // .then((res) => fetchBookingData())
      .catch((err) => toast.error(err));
  };

  useEffect(() => {
    reservationData?.booking_id && getOtaBookingInfoData();
    reservationData?.booking_id && getCustomPgData();
  }, [reservationData?.booking_id]);

  const [openOTAOTAAddedCard, setOpenOTAAddedCard] = useState(false);
  const handleOpenOTAAddedCard = () => setOpenOTAAddedCard(true);
  const handleCloseOTAAddedCard = () => setOpenOTAAddedCard(false);

  const [openAddedCard, setOpenAddedCard] = useState(false);
  const [openedAddedCardData, setOpenedAddedCardData] = useState(false);
  const handleOpenAddedCard = (item) => {
    setOpenAddedCard(true);
    setOpenedAddedCardData(item);
  };
  const handleCloseAddedCard = () => setOpenAddedCard(false);

  const [openPerformEMVTransaction, setOpenPerformEMVTransaction] =
    useState(false);
  const handleOpenPerformEMVTransaction = () =>
    setOpenPerformEMVTransaction(true);
  const handleClosePerformEMVTransaction = () =>
    setOpenPerformEMVTransaction(false);

  const [openCta, setOpenCta] = useState(false);
  const handleOpenCta = () => setOpenCta(true);
  const handleCloseCta = () => setOpenCta(false);

  // add Card modal

  const [openAddCard, setOpenAddCard] = useState(false);
  const handleOpenAddCard = () => setOpenAddCard(true);
  const handleCloseAddCard = () => setOpenAddCard(false);

  /*SHIFT4 multi terminal support
   * Get the specific terminal details if multiple terminals are configured
   * */
  function getShift4TerminalDetails(custom_pg_terminal_id) {
    let terminals = customPgData?.terminals;
    let shift4_utg_url = "";
    let shift4_api_terminal_id = "";
    terminals.forEach((element) => {
      if (custom_pg_terminal_id === element.id) {
        shift4_utg_url = element.shift4_utg_url;
        shift4_api_terminal_id = element.shift4_api_terminal_id;
      }
    });
    return {
      shift4_utg_url: shift4_utg_url,
      shift4_api_terminal_id: shift4_api_terminal_id,
    };
  }

  // handling the perform EMV transaction flow

  const handlePerformEMVTransactionCharge = async () => {
    setPerformEMVTransactionLoading1(true);
    let data = {
      hotelId: hotel.hotelId,
      bookingID: reservationData?.booking_id,
      reservation_id: eventData.event.extendedProps.reservationId,
      cust_email: invoiceData?.invoice?.customer_email,
      name: invoiceData?.invoice?.customer_name,
      is_emv: "YES",
      trans_type: "sale",
      add_card: performEMVTransactionSaveCard,
      amount: Number(performEMVTransactionAmt),
      logged_user_email: loggedUserEmail,
    };
    if (custom_pg_name === "SHIFT4" && shift4_utg_url) {
      djangoHandleDataMutationRequest(
        "POST",
        "invoice/generateShift4UtgRequest/",
        data
      )
        .then((shift4_utg_request) => {
          console.log(shift4_utg_request);
          let URL = shift4_utg_url + "api/rest/v1/transactions/sale";
          if (emvTerminalId) {
            data["terminal_id"] = emvTerminalId;
            let shift4_terminal_info = getShift4TerminalDetails(emvTerminalId);
            URL =
              shift4_terminal_info.shift4_utg_url +
              "api/rest/v1/transactions/sale";
            shift4_utg_request["device"]["terminalId"] =
              shift4_terminal_info.shift4_api_terminal_id;
          }

          let myHeaders = new Headers();
          myHeaders.append("InterfaceVersion", "4.0");
          myHeaders.append("InterfaceName", "Stayflexi");
          myHeaders.append("CompanyName", "Stayflexi");
          myHeaders.append("AccessToken", shift4_access_token);
          myHeaders.append("Content-Type", "application/json");

          let raw = JSON.stringify(shift4_utg_request);
          let requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };

          fetch(URL, requestOptions)
            .then((response) => {
              return response.json().then((json) => {
                return response.ok
                  ? json
                  : Promise.reject({ status: response.status, json });
              });
            })
            .then((result) => {
              console.log(result);
              data["shift4_utg_response"] = result;
              data["shift4_utg_request"] = shift4_utg_request;
              djangoHandleDataMutationRequest(
                "POST",
                "invoice/recordShift4UtgResponse/",
                data
              )
                .then((res) => callInvoiceIdGeneration())
                .then((res) => {
                  console.log(res);
                  toast.success(`${t("EMV Card charged successfully")}`, {
                    closeOnClick: true,
                    pauseOnHover: true,
                    autoClose: 8000,
                    hideProgressBar: true,
                    bodyClassName: "importantToast",
                  });
                })
                .then((res) => setPerformEMVTransactionLoading1(false))
                .then((res) => handleClosePerformEMVTransaction())
                .then((res) => setPerformEMVTransactionAmt())
                .then((res) => setPerformEMVTransactionSaveCard(true))
                .catch((error) => {
                  toast.error(error, {
                    closeOnClick: true,
                    pauseOnHover: true,
                    autoClose: 8000,
                    hideProgressBar: true,
                    bodyClassName: "importantToast",
                  });
                  setPerformEMVTransactionLoading1(false);
                });
            })
            .catch((error) => {
              setPerformEMVTransactionLoading1(false);
              if (
                error.json &&
                error.json.result &&
                error.json.result.length > 0 &&
                error.json.result[0].error
              ) {
                console.log(error);
                console.log(error.json.result[0].error.longText);
                console.error(error.json);
                toast.error(
                  "Transaction failed. Gateway message: " +
                    error.json.result[0].error.longText,
                  {
                    closeOnClick: true,
                    pauseOnHover: true,
                    autoClose: 8000,
                    hideProgressBar: true,
                    bodyClassName: "importantToast",
                  }
                );
              } else {
                console.log(error);
                toast.error("Failed to charge the card", {
                  closeOnClick: true,
                  pauseOnHover: true,
                  autoClose: 8000,
                  hideProgressBar: true,
                  bodyClassName: "importantToast",
                });
              }
            });
        })
        .catch((error) => {
          toast.error(error, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
          setPerformEMVTransactionLoading1(false);
        });
    } else {
      if (emvTerminalId) {
        data["terminal_id"] = emvTerminalId;
      }
      djangoHandleDataMutationRequest(
        "POST",
        "invoice/chargeCustomerCardOwn/",
        data
      )
        .then((res) => callInvoiceIdGeneration())
        .then((res) => {
          toast.success(`${t("EMV Card charged successfully")}`, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
        })
        .then((res) => setPerformEMVTransactionLoading1(false))
        .then((res) => handleClosePerformEMVTransaction())
        .then((res) => setPerformEMVTransactionAmt())
        .then((res) => setPerformEMVTransactionSaveCard(true))
        .catch((error) => {
          toast.error(error, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
          setPerformEMVTransactionLoading1(false);
        });
    }
  };

  const handlePerformEMVTransactionRefund = async () => {
    setPerformEMVTransactionLoading3(true);
    if (enablePerformEMVTxn && enableRefundCard) {
      let data = {
        hotelId: hotel.hotelId,
        bookingID: reservationData?.booking_id,
        reservation_id: eventData.event.extendedProps.reservationId,
        cust_email: invoiceData?.invoice?.customer_email,
        name: invoiceData?.invoice?.customer_name,
        is_emv: "YES",
        trans_type: "refund",
        add_card: performEMVTransactionSaveCard,
        amount: Number(performEMVTransactionAmt),
        logged_user_email: loggedUserEmail,
      };
      if (emvTerminalId) {
        data["terminal_id"] = emvTerminalId;
      }
      djangoHandleDataMutationRequest(
        "POST",
        "invoice/savedCardStandaloneRefund/",
        data
      )
        .then((res) => callInvoiceIdGeneration())
        .then((res) => {
          toast.success(`${t("EMV Card refunded successfully")}`, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
        })
        .then((res) => setPerformEMVTransactionLoading3(false))
        .then((res) => handleClosePerformEMVTransaction())
        .then((res) => setPerformEMVTransactionAmt())
        .then((res) => setPerformEMVTransactionSaveCard(true))
        .catch((error) => {
          toast.error(error, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
          setPerformEMVTransactionLoading3(false);
        });
    }
  };

  const handlePerformEMVTransactionHold = () => {
    setPerformEMVTransactionLoading2(true);
    let data = {
      hotelId: hotel.hotelId,
      bookingID: reservationData?.booking_id,
      reservation_id: eventData.event.extendedProps.reservationId,
      cust_email: invoiceData?.invoice?.customer_email,
      name: invoiceData?.invoice?.customer_name,
      is_emv: "YES",
      trans_type: "auth",
      add_card: performEMVTransactionSaveCard,
      amount: Number(performEMVTransactionAmt),
      logged_user_email: loggedUserEmail,
    };
    if (emvTerminalId) {
      data["terminal_id"] = emvTerminalId;
    }
    if (custom_pg_name === "SHIFT4" && shift4_utg_url) {
      djangoHandleDataMutationRequest(
        "POST",
        "invoice/generateShift4UtgRequest/",
        data
      )
        .then((shift4_utg_request) => {
          console.log(shift4_utg_request);
          let URL = shift4_utg_url + "api/rest/v1/transactions/authorization";
          if (emvTerminalId) {
            data["terminal_id"] = emvTerminalId;
            let shift4_terminal_info = getShift4TerminalDetails(emvTerminalId);
            URL =
              shift4_terminal_info.shift4_utg_url +
              "api/rest/v1/transactions/authorization";
            shift4_utg_request["device"]["terminalId"] =
              shift4_terminal_info.shift4_api_terminal_id;
          }

          let myHeaders = new Headers();
          myHeaders.append("InterfaceVersion", "4.0");
          myHeaders.append("InterfaceName", "Stayflexi");
          myHeaders.append("CompanyName", "Stayflexi");
          myHeaders.append("AccessToken", shift4_access_token);
          myHeaders.append("Content-Type", "application/json");
          let raw = JSON.stringify(shift4_utg_request);
          let requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };

          fetch(URL, requestOptions)
            .then((response) => {
              return response.json().then((json) => {
                return response.ok
                  ? json
                  : Promise.reject({ status: response.status, json });
              });
            })
            .then((result) => {
              console.log(result);
              data["shift4_utg_response"] = result;
              data["shift4_utg_request"] = shift4_utg_request;
              djangoHandleDataMutationRequest(
                "POST",
                "invoice/recordShift4UtgResponse/",
                data
              )
                .then((res) => callInvoiceIdGeneration())
                .then((res) => {
                  console.log(res);
                  toast.success(`${t("EMV Card authorized successfully")}`, {
                    closeOnClick: true,
                    pauseOnHover: true,
                    autoClose: 8000,
                    hideProgressBar: true,
                    bodyClassName: "importantToast",
                  });
                })
                .then((res) => setPerformEMVTransactionLoading2(false))
                .then((res) => handleClosePerformEMVTransaction())
                .then((res) => setPerformEMVTransactionAmt())
                .then((res) => setPerformEMVTransactionSaveCard(true))
                .catch((error) => {
                  toast.error(error, {
                    closeOnClick: true,
                    pauseOnHover: true,
                    autoClose: 8000,
                    hideProgressBar: true,
                    bodyClassName: "importantToast",
                  });
                  setPerformEMVTransactionLoading2(false);
                });
            })
            .catch((error) => {
              if (
                error.json &&
                error.json.result &&
                error.json.result.length > 0 &&
                error.json.result[0].error
              ) {
                console.log(error);
                console.log(error.json.result[0].error.longText);
                console.error(error.json);
                toast.error(
                  "Transaction failed. Gateway message: " +
                    error.json.result[0].error.longText,
                  {
                    closeOnClick: true,
                    pauseOnHover: true,
                    autoClose: 8000,
                    hideProgressBar: true,
                    bodyClassName: "importantToast",
                  }
                );
                setPerformEMVTransactionLoading2(false);
              } else {
                console.log(error);
                toast.error("Failed to authorize the card", {
                  closeOnClick: true,
                  pauseOnHover: true,
                  autoClose: 8000,
                  hideProgressBar: true,
                  bodyClassName: "importantToast",
                });
              }
            });
        })
        .catch((error) => {
          toast.error(error, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
          setPerformEMVTransactionLoading2(false);
        });
    } else {
      djangoHandleDataMutationRequest(
        "POST",
        "invoice/authorizeCustomerCardOwn/",
        data
      )
        .then((res) => callInvoiceIdGeneration())
        .then((res) => {
          toast.success(`${t("EMV Card authorized successfully")}`, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
        })
        .then((res) => setPerformEMVTransactionLoading2(false))
        .then((res) => handleClosePerformEMVTransaction())
        .then((res) => setPerformEMVTransactionAmt())
        .then((res) => setPerformEMVTransactionSaveCard(true))
        .catch((error) => {
          toast.error(error, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
          setPerformEMVTransactionLoading2(false);
        });
    }
  };

  function handleDirectCtaBilling() {
    setCtaLoader(true);
    djangoHandleDataMutationRequest(
      "POST",
      "invoice/addBillToCTAServiceToInvoice/",
      {
        hotelId: hotel.hotelId,
        booking_id: reservationData?.booking_id,
        reservation_id: eventData.event.extendedProps.reservationId,
        bill_to_cta_date: moment(settleCtaDate).format("YYYY-MM-DD"),
        bill_to_cta_amt: ctaAmt,
        bill_to_cta_amt_desc: ctaDesc,
        logged_user_email: loggedUserEmail,
      }
    )
      .then((res) => toast.success(`${t("Operation successful.")}`))
      /* .then((res) =>
            amplitude
              .getInstance()
              .logEvent(
                "USER PREFORMED DIRECT BILLING OPERATION THROUGH FOLIO PAGE",
                amplitudeEventProperties
              )
          ) */
      .then((res) => setCtaLoader(false))
      .then((res) => handleCloseCta())
      .then((res) => callInvoiceIdGeneration())
      .catch((err) => setCtaLoader(false))
      .catch((err) => handleCloseCta())
      .catch((err) => toast.error(err));
  }

  const [performOnlinePaymentModal, setPerformOnlinePaymentModal] =
    useState(false);
  const openPerformOnlinePaymentModal = () =>
    setPerformOnlinePaymentModal(true);
  const closePerformOnlinePaymentModal = () =>
    setPerformOnlinePaymentModal(false);

  const [onlinePaymentAmount, setOnlinePaymentAmount] = useState(0);
  const [onlinePaymentLoader, setOnlinePaymentLoader] = useState(false);
  const [clientSecretKey, setClientSecretKey] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");

  const [paymentIntentModal, setPaymentIntentModal] = useState(false);
  const openPaymentIntentModal = () => setPaymentIntentModal(true);
  const closePaymentIntentModal = () => setPaymentIntentModal(false);
  const [performPayment, setPerformPayment] = useState(false);
  const [openTransferReservation, setOpenTransferReservation] = useState(false);

  const handleCreateOnlinePaymentIntent = () => {
    if (onlinePaymentAmount <= 0) {
      toast.error(`${t("Payment amount must be greater than 0")}`);
      return;
    }
    setOnlinePaymentLoader(true);
    let request_payload = {
      hotel_id: hotel.hotelId,
      amount: Number(onlinePaymentAmount).toFixed(2),
      hotelName: invoiceData.hotel.hotel_name,
      currency: currency,
      booking_id: reservationData?.booking_id,
      module_source: "BOOKING_FOLIO",
      booking_source: "BOOKING_FOLIO",
      stripe_account: stripeAccount,
    };
    djangoHandleDataMutationRequest(
      "POST",
      "payments/createDirectPaymentIntent/",
      request_payload
    )
      .then((res) => {
        if (res?.client_secret_key !== "") {
          setClientSecretKey(res?.client_secret_key);
          setPaymentIntentId(res?.id);
        }
      })
      .then((res) => setOnlinePaymentLoader(false))
      .then((res) => openPaymentIntentModal());
  };

  const handleStripeSuccessPayment = (paymentId, bookingId) => {
    setOnlinePaymentLoader(true);
    let request_payload = {
      hotelId: hotel.hotelId,
      booking_id: reservationData?.booking_id,
      payment_gateway_id: paymentId,
      pg_name: "STRIPE",
      module_source: "BOOKING_FOLIO",
      booking_source: "BOOKING_FOLIO",
      amount: Number(onlinePaymentAmount).toFixed(2),
      requires_post_payment_confirmation: "true",
      customer_email: invoiceData?.invoice?.customer_email,
      stripe_account: stripeAccount,
      logged_user_email: loggedUserEmail,
    };
    djangoHandleDataMutationRequest(
      "POST",
      "payments/recordPayment/",
      request_payload
    )
      .then((res) =>
        toast.success(`${t("Online payment successfully recorded!")}`)
      )
      .then((res) => setOnlinePaymentLoader(false))
      .then((res) => setOnlinePaymentAmount(0))
      .then((res) => closePaymentIntentModal())
      .then((res) => closePerformOnlinePaymentModal());
  };

  //call to create custom invoice
  const callInvoiceIdGeneration = () => {
    axios.get(
      `${process.env.REACT_APP_SPRING_API_URL}web-api/generate-custom-invoice-id?bookingId=${reservationData?.booking_id}&bookingStatus=${reservationData?.booking_status}`
    );
  };

  useEffect(() => {
    setPerformEMVTransactionAmt(reservationData?.balance_due);
  }, [reservationData]);

  //Add Booking Note Modal
  const [showTagModal, setshowTagModal] = useState(false);

  const handleCloseTagModal = () => setshowTagModal(false);
  const handleShowTagModal = () => setshowTagModal(true);

  const [allBookingTags, setallBookingTags] = useState([]);
  // tag modal apis
  const [allHotelTags, setallHotelTags] = useState([]);
  const [loading, setloading] = useState(false);
  const [bookingNoteData, setbookingNoteData] = useState("");
  const [remark, setremark] = useState("");
  const [bookingRemark, setbookingRemark] = useState("");

  const [bookingShow, setBookingShow] = useState(false);

  const handleCloseBookingShow = () => setBookingShow(false);
  const handleOpenBookingShow = () => setBookingShow(true);

  useEffect(() => {
    handleSpringDataRequest(
      `core/api/v1/booking-tags/get-all?bookingId=${reservationData?.booking_id}`
    )
      .then((res) => {
        setallBookingTags(res[0]?.tags?.split(","));
        setbookingRemark(res[0]?.remark);
        // const data = res[0].tags.split(",");
        // console.log("allbokking tags", data);
        // setselectedBookingTags(data);
      })
      .catch((err) => console.error(`${t("Error in fetching tags")}`));

    handleSpringDataRequest(`core/api/v1/hotel-booking-tags/get-all`)
      .then((res) => {
        const data = res.filter((item) => item.status === "ACTIVE");
        // console.log("all hotel tags", data);
        setallHotelTags(data);
      })
      .catch((err) => console.error(`${t("Error in fetching tags")}`));
  }, [reservationData]);

  const createBookingNote = async () => {
    if (bookingNoteData.length === 0) {
      return toast.error("Please enter booking notes");
    }
    setloading(true);
    const payload = {
      hotelId: hotel.hotelId,
      bookingID: reservationData.booking_id,
      note: bookingNoteData,
      logged_in_user: localStorage.getItem("email"),
    };
    const data = await addNewBookingNote(payload);

    if (data.message === "Booking note recorded") {
      toast.success(`${t("Successfully created the booking note")}`);
      getAllBookingNotesData();
      setloading(false);
    } else {
      toast.error(`${t("Something went wrong !")}`);
      setloading(false);
    }
    setloading(false);
    await getAllData();
    handleCloseBookingShow();
  };

  const updateBookingTags = () => {
    const payload = {
      id: "",
      bookingId: reservationData.booking_id,
      tags: allBookingTags.toString(),
      remark: remark,
    };
    handleSpringDataMutationRequest(
      "PUT",
      `core/api/v1/booking-tags/update`,
      payload
    )
      .then((res) => {
        toast.success(`${t("Successfully added tag")}`);
        setremark("");
        handleCloseTagModal();
      })
      .catch((err) => {
        toast.error(err.response);
        setremark("");
        handleCloseTagModal();
      });
  };

  const handleSelectedBookingTags = (name) => {
    // console.log(name, allBookingTags.indexOf(name));
    var idx = allBookingTags.indexOf(name);
    if (idx !== -1) {
      const temp = allBookingTags.filter((item) => item !== name && item);
      // console.log("temp : ", temp);
      return setallBookingTags(temp);
    } else {
      setallBookingTags([...allBookingTags, name]);
    }
  };

  const saveCustomer = async (data, id, name, reservationId) => {
    setLoad(true);
    try {
      await updateCustomer(data, id, reservationId);
      eventData.event.setProp("title", name);
      reload();
      setLoad(false);
      // try(
      //   reloadReservationData()
      // )catch(e){

      // }
    } catch (e) {
      const message = e.message ? e.message : customErrorMessage;
      toast.error(
        typeof message === "string" || message instanceof String
          ? message
          : customErrorMessage
      );
      console.log(e);
      setLoad(false);
    }
  };

  const saveCustomerRes = async (data, id, name) => {
    setLoad(true);
    try {
      await updateCustomerRes(data, id);
      eventData.event.setProp("title", name);
      reload();
      setLoad(false);
      //reloadReservationData();
    } catch (e) {
      const message = e.message ? e.message : customErrorMessage;
      toast.error(
        typeof message === "string" || message instanceof String
          ? message
          : customErrorMessage
      );
      console.log(e);
      setLoad(false);
    }
  };

  const [userEmail, setUserEmail] = useState(null);
  useEffect(() => {
    setUserEmail(reservationData?.user_email);
  }, [reservationData]);

  const [doNotRent, setDoNotRent] = useState(null);

  const getDoNotRentStatus = (email) => {
    handleSpringDataRequest(`core/api/v1/do-not-rent/get-all?emailId=${email}`)
      .then((res) => {
        setDoNotRent(res[0]);
      })
      .catch((err) => {
        console.log("The err is : ", err);
      });
  };

  useEffect(() => {
    userEmail && getDoNotRentStatus(userEmail);
  }, [userEmail]);

  const [openCustomerInfo, setopenCustomerInfo] = useState(false);
  const [selectedEmailGuest, setselectedEmailGuest] = useState("");

  console.log("opemn", openCustomerInfo);

  return (
    <div className={classes.root}>
      <CommonSlider open={open} onClose={handleAddEventSidebar}>
        <Grid
          container
          sx={{ height: "100%", width: "100%", display: "block" }}
        >
          <Grid container height={"max-content"} className={classes.textField}>
            <Grid
              container
              justifyContent="flex-start"
              alignSelf="center"
              item
              xs={10}
            >
              <Typography variant="h5">
                <div className="d-flex align-items-center">
                  <h5 className="mb-0 mr-2">{t("Reservation details")}</h5>
                </div>
              </Typography>
            </Grid>
            <Grid container item justifyContent="flex-end" xs={2}>
              <IconButton title="close" onClick={handleAddEventSidebar}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>

          {loadingData && (
            <Grid
              sx={{ width: "100%", height: "inherit" }}
              container
              alignItems="center"
              direction="column"
              justifyContent="center"
            >
              <DimmingDotsLoader />
            </Grid>
          )}

          {!loadingData && (
            <Grid item>
              <Card
                variant="outlined"
                style={{
                  marginTop: "1rem",
                  borderRadius: "1rem",
                  marginBottom: "1rem",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid
                      container
                      height={"max-content"}
                      className={classes.textField}
                    >
                      <Grid
                        container
                        justifyContent="flex-start"
                        alignSelf="center"
                        item
                        xs={10}
                      >
                        <Typography variant="h5">
                          <div className="d-flex align-items-center">
                            <h5
                              style={{ fontWeight: "bold" }}
                              className="mb-0 mr-2"
                            >
                              {t("Reservation")}
                            </h5>
                          </div>
                        </Typography>
                      </Grid>
                      <Grid container item justifyContent="flex-end" xs={2}>
                        {isNightAuditOn &&
                        selectedReservationData?.booking_status ===
                          "CHECKED_OUT" &&
                        subUser.toLowerCase() === "subuser" ? (
                          <></>
                        ) : (
                          <div>
                            {" "}
                            <Button
                              variant="outlined"
                              style={{
                                textTransform: "none",
                                color: `${"#36454F"}`,
                                borderColor: "#36454F",
                                fontWeight: "bold",
                              }}
                              onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                                setMenuOpen(!menuOpen);
                              }}
                            >
                              <EditIcon style={{ marginRight: "0.4rem" }} />
                              Modify
                            </Button>
                            <Popper
                              style={{ zIndex: 3 }}
                              open={menuOpen}
                              anchorEl={anchorEl}
                              placement="bottom-end"
                              transition
                              disablePortal
                            >
                              {({ TransitionProps, placement }) => (
                                <Grow
                                  {...TransitionProps}
                                  style={{
                                    transformOrigin: (placement = "bottom-end"),
                                  }}
                                >
                                  <Paper>
                                    <ClickAwayListener
                                      onClickAway={() => {
                                        setMenuOpen(false);
                                      }}
                                    >
                                      <MenuList
                                        id="composition-menu"
                                        aria-labelledby="composition-button"
                                      >
                                        {selectedReservationData?.booking_status !==
                                          "CHECKED_OUT" && (
                                          <MenuItem
                                            onClick={() => {
                                              setMenuOpen(!menuOpen);
                                              setMenuValue("hold-booking");
                                            }}
                                            key="hold-booking"
                                          >
                                            {t("Hold booking")}
                                          </MenuItem>
                                        )}
                                        {selectedReservationData?.booking_status !==
                                          "CHECKED_OUT" &&
                                          selectedReservationData?.booking_status !==
                                            "ADMIN_CONFIRMED" &&
                                          JSON.parse(
                                            hotel?.accessControl
                                              ?.user_feature_map?.CancelBooking
                                          ).read === "YES" && (
                                            <MenuItem
                                              onClick={() => {
                                                setMenuOpen(!menuOpen);
                                                setMenuValue("cancel-booking");
                                              }}
                                              key="cancel-booking"
                                            >
                                              {t("Cancel booking")}
                                            </MenuItem>
                                          )}
                                        {selectedReservationData?.booking_status !==
                                          "CHECKED_OUT" &&
                                          reservationData?.is_enquiry ===
                                            "false" && (
                                            <MenuItem
                                              onClick={() => {
                                                setMenuOpen(!menuOpen);
                                                setMenuValue("no-show");
                                              }}
                                              disabled={
                                                eventData.event.extendedProps
                                                  .status !== "CONFIRMED"
                                              }
                                              key="no-show"
                                            >
                                              {t("Set to no show")}
                                            </MenuItem>
                                          )}
                                        {selectedReservationData?.booking_status !==
                                          "CHECKED_OUT" &&
                                          JSON.parse(
                                            hotel?.accessControl
                                              ?.user_feature_map
                                              ?.LockAndUnlockBooking
                                          ).read === "YES" && (
                                            <MenuItem
                                              onClick={() => {
                                                setMenuOpen(!menuOpen);
                                                setMenuValue("lock-booking");
                                              }}
                                              key="lock-booking"
                                            >
                                              {eventData.event.extendedProps
                                                .locked_status !== "LOCKED"
                                                ? `${t("Lock booking")}`
                                                : `${t("Unlock booking")}`}
                                            </MenuItem>
                                          )}
                                        {selectedReservationData?.booking_status !==
                                          "CHECKED_OUT" &&
                                          JSON.parse(
                                            hotel?.accessControl
                                              ?.user_feature_map?.UnassignRoom
                                          ).read === "YES" && (
                                            <MenuItem
                                              onClick={() => {
                                                setMenuOpen(!menuOpen);
                                                setMenuValue("unassign-room");
                                              }}
                                              key="unassign-room"
                                            >
                                              {t("Unassign room")}
                                            </MenuItem>
                                          )}
                                        {selectedReservationData?.booking_status !==
                                          "CHECKED_OUT" &&
                                          JSON.parse(
                                            hotel?.accessControl
                                              ?.user_feature_map?.ModifyCheckIn
                                          ).read === "YES" && (
                                            <MenuItem
                                              disabled={
                                                eventData.event.extendedProps
                                                  .status === "ADMIN_CONFIRMED"
                                              }
                                              onClick={() => {
                                                setMenuOpen(!menuOpen);
                                                setMenuValue("extend-checkin");
                                              }}
                                              key="extend-checkin"
                                            >
                                              {t("Modify checkin")}
                                            </MenuItem>
                                          )}
                                        {selectedReservationData?.booking_status !==
                                          "CHECKED_OUT" &&
                                          JSON.parse(
                                            hotel?.accessControl
                                              ?.user_feature_map?.ModifyCheckOut
                                          ).read === "YES" && (
                                            <MenuItem
                                              onClick={() => {
                                                setMenuOpen(!menuOpen);
                                                setMenuValue("extend-checkout");
                                              }}
                                              key="extend-checkout"
                                            >
                                              {t("Modify checkout")}
                                            </MenuItem>
                                          )}
                                        {selectedReservationData?.booking_status !==
                                          "CHECKED_OUT" &&
                                          JSON.parse(
                                            hotel?.accessControl
                                              ?.user_feature_map
                                              ?.EditReservation
                                          ).read === "YES" && (
                                            <MenuItem
                                              onClick={() => {
                                                setMenuOpen(!menuOpen);
                                                setMenuValue("move");
                                              }}
                                              key="move"
                                            >
                                              {t("Edit reservation")}
                                            </MenuItem>
                                          )}
                                        {/* {reservationData?.is_enquiry === "true" && (
                        <MenuItem onClick={Confirmed} key="confirm">
                          Confirmed booking
                        </MenuItem>
                      )} */}
                                        {((subUser.toLowerCase() ===
                                          "subuser" &&
                                          hotelemail !==
                                            "reservation@maharashtratourism.gov.in") ||
                                          subUser.toLowerCase() === "admin") &&
                                          selectedReservationData?.booking_status !==
                                            "CHECKED_OUT" &&
                                          reservationData?.is_enquiry ===
                                            "false" &&
                                          JSON.parse(
                                            hotel?.accessControl
                                              ?.user_feature_map?.SplitRoom
                                          ).read === "YES" &&
                                          hotel.slotType !== "PURE_HOURLY" && (
                                            <MenuItem
                                              onClick={() => {
                                                setMenuOpen(!menuOpen);
                                                setMenuValue("split-room");
                                              }}
                                              key="split-room"
                                            >
                                              {t("Split Room")}
                                            </MenuItem>
                                          )}
                                        {((subUser.toLowerCase() ===
                                          "subuser" &&
                                          hotelemail !==
                                            "reservation@maharashtratourism.gov.in") ||
                                          subUser.toLowerCase() === "admin") &&
                                          selectedReservationData?.booking_status !==
                                            "CHECKED_OUT" && (
                                            <MenuItem
                                              onClick={() => {
                                                setMenuOpen(!menuOpen);
                                                setMenuValue("move-room");
                                              }}
                                              key="move-room"
                                            >
                                              {t("Move Room")}
                                            </MenuItem>
                                          )}
                                        {/* <MenuItem onClick={() => {
                        setMenuOpen(!menuOpen)
                        setMenuValue("upgrade")
                      }} key="upgrade">Upgrade resrvation</MenuItem> */}
                                        {reservationData.booking_status ===
                                          "ADMIN_CONFIRMED" &&
                                          JSON.parse(
                                            hotel?.accessControl
                                              ?.user_feature_map
                                              ?.RollbackBooking
                                          ).read === "YES" && (
                                            <MenuItem
                                              onClick={() =>
                                                handleOpenRollbackBooking()
                                              }
                                            >
                                              {t("Rollback booking")}
                                            </MenuItem>
                                          )}

                                        {reservationData.booking_status ===
                                          "CANCELLED" &&
                                          JSON.parse(
                                            hotel?.accessControl
                                              ?.user_feature_map
                                              ?.RollbackBooking
                                          ).read === "YES" && (
                                            <MenuItem
                                              onClick={() =>
                                                handleOpenRollbackBooking()
                                              }
                                            >
                                              {t("Rollback booking")}
                                            </MenuItem>
                                          )}

                                        {selectedReservationData?.booking_status ===
                                          "CHECKED_OUT" && (
                                          <MenuItem
                                            onClick={() =>
                                              handleOpenReleaseInventoryModal()
                                            }
                                            key="move-room"
                                          >
                                            {t("Release inventory")}
                                          </MenuItem>
                                        )}
                                        {/* <MenuItem
                        onClick={() => {
                          setMenuOpen(!menuOpen);
                          setMenuValue("upgrade");
                        }}
                        key="upgrade"
                      >
                        Move and upgrade resrvation
                      </MenuItem> */}
                                      </MenuList>
                                    </ClickAwayListener>
                                  </Paper>
                                </Grow>
                              )}
                            </Popper>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      {reservationData.booking_id}
                    </Grid>
                    <Grid item xs={12}>
                      {moment(
                        reservationData?.booking_checkin.replace("T", " "),
                        "YYYY-MM-DD HH:mm:ss"
                      ).format("MMM Do YYYY, h A")}{" "}
                      -{" "}
                      {moment(
                        reservationData?.booking_checkout.replace("T", " "),
                        "YYYY-MM-DD HH:mm:ss"
                      ).format("MMM Do YYYY, h A")}
                    </Grid>
                    <Grid item xs={12}>
                      {`${reservationData?.room_type_name}  ( ${reservationData?.rate_plan_name} )`}
                    </Grid>
                    <Grid item xs={12}>
                      {`Booked Room No.(s) : ${reservationData.reservation_rooms}`}
                    </Grid>
                    <Grid item xs={12}>
                      {`${reservationData.adults} Adults , ${reservationData.children} Children , ${reservationData.children1} Infants`}
                    </Grid>
                    <Grid item xs={12}>
                      {`Booking made on : ${moment(
                        reservationData.booking_made_on,
                        "YYYY-MM-DD HH:mm:ss"
                      ).format("MMM Do YYYY, hh:mm A")}`}
                    </Grid>

                    <Grid item xs={12}>
                      {`Booking source : ${
                        reservationData?.booking_source === "STAYFLEXI_HD"
                          ? `${t("Walk In")}`
                          : reservationData?.booking_source === "STAYFLEXI_OD"
                          ? `${t("Booking Engine")}`
                          : reservationData?.booking_source
                      }`}
                    </Grid>
                    {reservationData?.ota_booking_id !== null && (
                      <Grid item xs={12}>
                        {`OTA Booking Id : ${
                          reservationData?.ota_booking_id === undefined
                            ? "NA"
                            : reservationData?.ota_booking_id
                        }`}
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} container justifyContent="flex-end">
                    <Link
                      to={{
                        pathname: `/folio/${reservationData.booking_id}`,
                        search: `&hotelId=${hotel.hotelId}`,
                      }}
                      target="_blank"
                    >
                      <Button
                        variant="outlined"
                        style={{
                          textTransform: "none",
                          color: `${"#36454F"}`,
                          borderColor: "#36454F",
                          fontWeight: "bold",
                        }}
                      >
                        View folio
                      </Button>
                    </Link>
                    <Button
                      variant="outlined"
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                        textTransform: "none",
                        color: `${"#36454F"}`,
                        borderColor: "#36454F",
                        fontWeight: "bold",
                      }}
                      onClick={() => handleOpenBookingConf()}
                    >
                      {invoiceData?.invoice?.is_enquiry === "true"
                        ? `${t("Resend enquiry mail")}`
                        : `${t("Email booking confirmation")}`}
                    </Button>
                    <Button
                      variant="outlined"
                      style={{
                        textTransform: "none",
                        color: `${"#36454F"}`,
                        borderColor: "#36454F",
                        fontWeight: "bold",
                      }}
                      onClick={() => setOpenBlock(true)}
                    >
                      Print registeration card
                    </Button>
                  </Grid>
                </CardContent>
              </Card>

              <Card
                variant="outlined"
                style={{
                  marginTop: "1rem",
                  borderRadius: "1rem",
                  marginBottom: "1rem",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid
                      container
                      height={"max-content"}
                      className={classes.textField}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h5" style={{ marginBottom: "0px" }}>
                        <div className="d-flex align-items-center mb-0">
                          <h5
                            style={{ fontWeight: "bold" }}
                            className="mb-0 mr-2"
                          >
                            {t("Guests")}
                          </h5>
                        </div>
                      </Typography>

                      {isNightAuditOn &&
                      selectedReservationData?.booking_status ===
                        "CHECKED_OUT" &&
                      subUser.toLowerCase() === "subuser" ? (
                        <></>
                      ) : (
                        <div className="d-flex">
                          {" "}
                          <Button
                            variant="outlined"
                            style={{
                              textTransform: "none",
                              color: `${"#36454F"}`,
                              borderColor: "#36454F",
                              fontWeight: "bold",
                            }}
                            onClick={
                              isEditable &&
                              toggleGuestDrawer("right", true, "test")
                            }
                          >
                            <AddIcon style={{ marginRight: "0.4rem" }} />
                            {t("Add Guests")}
                          </Button>
                        </div>
                      )}
                    </Grid>

                    {allGuestList.length > 0 && (
                      <>
                        <Grid item xs={12} style={{ display: "flex" }}>
                          <p
                            style={{
                              fontWeight: "bold",
                              textDecoration: "underline",
                            }}
                          >
                            {reservationData?.user_name}
                          </p>

                          <EditIcon
                            style={{ marginLeft: "10px", cursor: "pointer" }}
                            onClick={() => {
                              setselectedEmailGuest(
                                reservationData?.user_email
                              );
                              setopenCustomerInfo(true);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {reservationData?.user_email}
                        </Grid>
                        <Grid item xs={12}>
                          {reservationData?.user_contact}
                        </Grid>
                        <Grid item xs={12}>
                          {reservationData?.city === ""
                            ? ""
                            : `${reservationData?.city}, `}
                          {reservationData?.state === ""
                            ? ""
                            : `${reservationData?.state}, `}
                          {reservationData?.country === ""
                            ? ""
                            : `${reservationData?.country} `}
                        </Grid>

                        <Grid item xs={12} style={{ display: "flex" }}>
                          {allGuestList
                            .filter((item) => item.primaryGuest !== "YES")
                            ?.map((guest, index) => {
                              return (
                                <div
                                  className="d-flex"
                                  style={{
                                    marginLeft: `${
                                      index + 2 >= 3 ? "1rem" : "0rem"
                                    }`,
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    {`Guest ${index + 2} : ${guest.name}`}
                                  </p>
                                  <EditIcon
                                    style={{
                                      marginLeft: "10px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setselectedEmailGuest(guest?.email);
                                      setopenCustomerInfo(true);
                                    }}
                                  />
                                </div>
                              );
                            })}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </CardContent>
              </Card>

              <Card
                variant="outlined"
                style={{
                  marginTop: "1rem",
                  borderRadius: "1rem",
                  marginBottom: "1rem",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid
                      container
                      height={"max-content"}
                      className={classes.textField}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h5" style={{ marginBottom: "0px" }}>
                        <div className="d-flex align-items-center mb-0">
                          <h5
                            style={{ fontWeight: "bold" }}
                            className="mb-0 mr-2"
                          >
                            {t("Payment details")}
                          </h5>
                        </div>
                      </Typography>

                      {isNightAuditOn &&
                      selectedReservationData?.booking_status ===
                        "CHECKED_OUT" &&
                      subUser.toLowerCase() === "subuser" ? (
                        <></>
                      ) : (
                        <div className="d-flex">
                          {" "}
                          <div>
                            <Button
                              variant="outlined"
                              style={{
                                // marginRight: "1rem",
                                textTransform: "none",
                                color: `${"#36454F"}`,
                                borderColor: "#36454F",
                                fontWeight: "bold",
                              }}
                              onClick={(event) => {
                                setAnchorEls(event.currentTarget);
                                setMenuOpens(!menuOpens);
                              }}
                            >
                              <AttachMoneyIcon
                                style={{ marginRight: "0.4rem" }}
                              />
                              {t("Settle dues")}
                            </Button>

                            <Popper
                              style={{ zIndex: 3 }}
                              open={menuOpens}
                              anchorEl={anchorEls}
                              placement="bottom-end"
                              transition
                              disablePortal
                            >
                              {({ TransitionProps, placement }) => (
                                <Grow
                                  {...TransitionProps}
                                  style={{
                                    transformOrigin: (placement = "bottom-end"),
                                  }}
                                >
                                  <Paper>
                                    <ClickAwayListener
                                      onClickAway={() => {
                                        setMenuOpens(false);
                                      }}
                                    >
                                      <MenuList
                                        id="composition-menu"
                                        aria-labelledby="composition-button"
                                      >
                                        <div
                                          className="settleDuesActions"
                                          style={{
                                            width: "13rem",
                                            paddingLeft: "10px",
                                          }}
                                        >
                                          {JSON.parse(
                                            hotel?.accessControl
                                              ?.user_feature_map?.CashPayment
                                          ).read === "YES" && (
                                            <div
                                              className="item"
                                              onClick={() => {
                                                setpaymentType("Cash payment");
                                                setOpenPaymentModal(true);
                                              }}
                                            >
                                              {t("Cash payment")}
                                            </div>
                                          )}
                                          {JSON.parse(
                                            hotel?.accessControl
                                              ?.user_feature_map
                                              ?.OfflineCardPayment
                                          ).read === "YES" && (
                                            <div
                                              className="item"
                                              onClick={() => {
                                                setpaymentType(
                                                  "Offline card payment"
                                                );
                                                setOpenPaymentModal(true);
                                              }}
                                            >
                                              {t("Offline card payment")}
                                            </div>
                                          )}
                                          {JSON.parse(
                                            hotel?.accessControl
                                              ?.user_feature_map
                                              ?.OfflineChequePayment
                                          ).read === "YES" && (
                                            <div
                                              className="item"
                                              onClick={() => {
                                                setpaymentType(
                                                  "Offline cheque payment"
                                                );
                                                setOpenPaymentModal(true);
                                              }}
                                            >
                                              {t("Offline cheque payment")}
                                            </div>
                                          )}
                                          {hotelType !== "ISHA" &&
                                            currency === "INR" && (
                                              <div
                                                className="item"
                                                onClick={() => {
                                                  setpaymentType("UPI Payment");
                                                  setOpenPaymentModal(true);
                                                }}
                                              >
                                                {t("UPI Payment")}
                                              </div>
                                            )}

                                          {hotelType !== "ISHA" &&
                                            JSON.parse(
                                              hotel?.accessControl
                                                ?.user_feature_map
                                                ?.OtherPaymentModes
                                            ).read === "YES" && (
                                              <div
                                                className="item"
                                                onClick={() => {
                                                  setpaymentType(
                                                    "Other payment modes"
                                                  );
                                                  setOpenPaymentModal(true);
                                                }}
                                              >
                                                {t("Other payment modes")}
                                              </div>
                                            )}

                                          {JSON.parse(
                                            hotel?.accessControl
                                              ?.user_feature_map
                                              ?.OtherPaymentModes
                                          ).read === "YES" && (
                                            <div
                                              className="item"
                                              onClick={() => {
                                                setpaymentType("Bank transfer");
                                                setOpenPaymentModal(true);
                                              }}
                                            >
                                              {t("Bank transfer")}
                                            </div>
                                          )}
                                          {JSON.parse(
                                            hotel?.accessControl
                                              ?.user_feature_map?.CashDeposit
                                          ).read === "YES" && (
                                            <div
                                              className="item"
                                              onClick={() => {
                                                {
                                                  setpaymentType(
                                                    "Cash deposit"
                                                  );
                                                  setOpenPaymentModal(true);
                                                }
                                              }}
                                            >
                                              {t("Cash deposit")}
                                            </div>
                                          )}

                                          {JSON.parse(
                                            hotel?.accessControl
                                              ?.user_feature_map?.PaymentLink
                                          ).read === "YES" ? (
                                            <>
                                              {invoiceData?.invoice
                                                ?.is_enquiry === "true" ? (
                                                <>
                                                  {moment(
                                                    invoiceData?.invoice
                                                      ?.enquiry_expiration_time
                                                  ).unix() >
                                                    moment().unix() && (
                                                    <div
                                                      className="item"
                                                      onClick={() => {
                                                        setpaymentType(
                                                          "Send payment link"
                                                        );
                                                        setOpenPaymentModal(
                                                          true
                                                        );
                                                      }}
                                                    >
                                                      {t("Send payment link")}
                                                    </div>
                                                  )}
                                                </>
                                              ) : (
                                                <div
                                                  className="item"
                                                  onClick={() => {
                                                    setpaymentType(
                                                      "Send payment link"
                                                    );
                                                    setOpenPaymentModal(true);
                                                  }}
                                                >
                                                  {t("Send payment link")}
                                                </div>
                                              )}
                                            </>
                                          ) : (
                                            <></>
                                          )}

                                          {invoiceData.hotel.hotel_country?.toUpperCase() !==
                                            "INDIA" &&
                                            hotel?.accessControl?.hotel
                                              ?.pg_account_id !== "" &&
                                            hotelType !== "ISHA" &&
                                            JSON.parse(
                                              hotel?.accessControl
                                                ?.user_feature_map?.ChargeCard
                                            ).read === "YES" && (
                                              <div
                                                className="item"
                                                onClick={() =>
                                                  openPerformOnlinePaymentModal()
                                                }
                                              >
                                                {t("Gateway Payment (Stripe)")}
                                              </div>
                                            )}
                                          {isAdmin === "ADMIN" &&
                                            custom_pg_name !== "SHIFT4" &&
                                            Object.keys(otaBookingInfoData) &&
                                            otaBookingInfoData?.card_num &&
                                            JSON.parse(
                                              hotel?.accessControl
                                                ?.user_feature_map?.ChargeCard
                                            ).read === "YES" && (
                                              <div
                                                className="item"
                                                onClick={() =>
                                                  handleOpenOTAAddedCard()
                                                }
                                              >
                                                {t("Charge")}{" "}
                                                {
                                                  otaBookingInfoData?.card_holder_first_name
                                                }{" "}
                                                {
                                                  otaBookingInfoData?.card_holder_last_name
                                                }{" "}
                                                CARD **
                                                {otaBookingInfoData?.card_num?.slice(
                                                  12,
                                                  16
                                                )}{" "}
                                                - {otaBookingInfoData?.source}
                                              </div>
                                            )}
                                          {isAdmin !== "ADMIN" &&
                                            custom_pg_name !== "SHIFT4" &&
                                            paymentViewAccess === "YES" &&
                                            Object.keys(otaBookingInfoData) &&
                                            otaBookingInfoData?.card_num &&
                                            JSON.parse(
                                              hotel?.accessControl
                                                ?.user_feature_map?.ChargeCard
                                            ).read === "YES" && (
                                              <div
                                                className="item"
                                                onClick={() =>
                                                  handleOpenOTAAddedCard()
                                                }
                                              >
                                                {t("Charge")}{" "}
                                                {
                                                  otaBookingInfoData?.card_holder_first_name
                                                }{" "}
                                                {
                                                  otaBookingInfoData?.card_holder_last_name
                                                }{" "}
                                                CARD **
                                                {otaBookingInfoData?.card_num?.slice(
                                                  12,
                                                  16
                                                )}{" "}
                                                - {otaBookingInfoData?.source}
                                              </div>
                                            )}
                                          <div className="horizontalSep" />
                                          {enableAddCard &&
                                            JSON.parse(
                                              hotel?.accessControl
                                                ?.user_feature_map?.AddCard
                                            ).read === "YES" && (
                                              <div
                                                className="item"
                                                onClick={() =>
                                                  handleOpenAddCard()
                                                }
                                              >
                                                {t("Add card")}
                                              </div>
                                            )}
                                          {enablePerformEMVTxn &&
                                            JSON.parse(
                                              hotel?.accessControl
                                                ?.user_feature_map?.PerformEMV
                                            ).read === "YES" && (
                                              <div
                                                className="item"
                                                onClick={() =>
                                                  handleOpenPerformEMVTransaction()
                                                }
                                              >
                                                {t("Perform EMV transaction")}
                                              </div>
                                            )}
                                          {(enableAddCard ||
                                            stripeAccount !== "") &&
                                            savedCards?.map((item, index) => {
                                              return (
                                                <div
                                                  key={index}
                                                  className="item"
                                                  onClick={() => {
                                                    handleOpenAddedCard(item);
                                                  }}
                                                >
                                                  {item?.is_emv === "YES"
                                                    ? "EMV "
                                                    : ""}
                                                  {item.card_first_name}{" "}
                                                  {t("CARD")}**
                                                  {item.card_last4}
                                                </div>
                                              );
                                            })}
                                          {(enableAddCard ||
                                            stripeAccount !== "") &&
                                            invoiceData?.saved_cards_own?.map(
                                              (item, index) => {
                                                return (
                                                  <div
                                                    key={index}
                                                    className="item"
                                                    onClick={() =>
                                                      handleOpenAddedCard(item)
                                                    }
                                                  >
                                                    {item.card_first_name}{" "}
                                                    {t("CARD")}
                                                    **
                                                    {item.card_last4}
                                                  </div>
                                                );
                                              }
                                            )}
                                          {invoiceData?.invoice
                                            ?.cta_details && (
                                            <>
                                              <div className="horizontalSep" />
                                              <div
                                                className="item"
                                                onClick={() => handleOpenCta()}
                                              >
                                                {t("Direct bill to")} -{" "}
                                                {
                                                  invoiceData?.invoice
                                                    .booking_source_displayname
                                                }
                                              </div>
                                            </>
                                          )}
                                        </div>
                                        {/* <MenuItem
                                                                                  onClick={() => {
                                                                                    setMenuOpen(!menuOpen);
                                                                                    setMenuValue("upgrade");
                                                                                  }}
                                                                                  key="upgrade"
                                                                                >
                                                                                  Move and upgrade resrvation
                                                                                </MenuItem> */}
                                      </MenuList>
                                    </ClickAwayListener>
                                  </Paper>
                                </Grow>
                              )}
                            </Popper>
                          </div>
                          {/* <Button variant="outlined">
                            <ViewTimelineIcon
                              style={{ marginRight: "0.4rem" }}
                            />
                            {t("Refund")}
                          </Button> */}
                        </div>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      {`Final amount with tax : ${`${currency} ${Number(
                        reservationData.total_amount_with_services
                      ).toFixed(2)}`}`}
                    </Grid>

                    <Grid item xs={12}>
                      {`Payment made : ${`${currency} ${Number(
                        reservationData.payment_made
                      ).toFixed(2)}`}`}
                    </Grid>

                    <Grid item xs={12}>
                      {`Balance due : ${`${currency} ${Number(
                        reservationData.balance_due
                      ).toFixed(2)}`}`}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card
                variant="outlined"
                style={{
                  marginTop: "1rem",
                  borderRadius: "1rem",
                  marginBottom: "2rem",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                }}
              >
                <CardContent style={{ marginBottom: "2rem" }}>
                  <Grid container spacing={2}>
                    <Grid
                      container
                      height={"max-content"}
                      className={classes.textField}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h5" style={{ marginBottom: "0px" }}>
                        <div className="d-flex align-items-center mb-0">
                          <h5
                            style={{ fontWeight: "bold" }}
                            className="mb-0 mr-2"
                          >
                            {t("Notes")}
                          </h5>
                        </div>
                      </Typography>
                      <Button
                        variant="outlined"
                        style={{
                          marginRight: "0.4rem",
                          textTransform: "none",
                          color: `${"#36454F"}`,
                          borderColor: "#36454F",
                          fontWeight: "bold",
                        }}
                        onClick={handleOpenBookingShow}
                      >
                        <AddIcon />
                        Add notes
                      </Button>
                    </Grid>

                    <Grid item xs={12}>
                      {bookingNotes.length <= 0 ? (
                        <h6
                          style={{ marginLeft: "16px" }}
                          className="text-muted"
                        >
                          {t("No booking notes")}
                        </h6>
                      ) : (
                        bookingNotes?.map((e) => (
                          <div
                            className="d-flex align-items-center w=100"
                            style={{ justifyContent: "space-between" }}
                          >
                            <p className="mb-0">{e.notes}</p>

                            <DeleteIcon
                              onClick={() => deleteBookingNote(e.id, e?.notes)}
                              className="ml-2"
                              style={{ cursor: "pointer", marginRight: "18px" }}
                            />
                          </div>
                        ))
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </CommonSlider>

      {openBlock && (
        <PrintRegCard
          closeFunc={() => {
            setOpenBlock(false);
          }}
          open={openBlock}
          modalClose={() => {
            setOpenBlock(false);
          }}
          selectedLanguage={props.selectedLanguage}
          bookingId={reservationData?.booking_id}
          invoiceData={invoiceData}
          hotelType={hotelType}
          defaultCtadropdown={defaultCtadropdown}
          allCompanyDetails={allCompanyDetails}
          summaryData={summaryData}
          hotelLogo={hotelLogo}
          currency={currency}
          customFolioConfig={customFolioConfig}
          handleCloseResCard={() => {
            setOpenBlock(false);
          }}
          manualResCard={manualResCard}
          handleChangeManualResCard={handleChangeManualResCard}
        ></PrintRegCard>
      )}

      {openBookingConf && (
        <SendConfirmationModal
          closeFunc={() => {
            handleCloseBookingConf();
          }}
          open={openBookingConf}
          modalClose={() => {
            handleCloseBookingConf();
          }}
          selectedLanguage={props.selectedLanguage}
          invoiceData={invoiceData}
          handleCloseBookingConf={() => handleCloseBookingConf()}
          handleSendBookingConfirmation={() => handleSendBookingConfirmation()}
          hotelType={hotelType}
          setBookingConfMail={setBookingConfMail}
          bookingConfMail={bookingConfMail}
          bookingConfLoading={bookingConfLoading}
        ></SendConfirmationModal>
      )}
      {menuValue === "hold-booking" && (
        <HoldBooking
          value="hold-booking"
          title={t("Hold Booking")}
          buttonText={t("Yes, Hold Booking")}
          reservationData={reservationData}
          buttonFunction={holdExisting}
          closeBackDrop={handleAddEventSidebar}
          load={load}
          reload={reload}
          closeModal={displayFalse}
          reservation_id={eventData.event.extendedProps.reservationId}
          eventData={eventData}
          selectedLanguage={props.selectedLanguage}
        />
      )}

      {menuValue === "cancel-booking" && (
        <CancelBooking
          value="cancel-booking"
          title={t("Cancel Booking")}
          buttonText={t("Yes, Cancel Booking")}
          reservationData={reservationData}
          buttonFunction={cancelExisting}
          closeBackDrop={handleAddEventSidebar}
          load={load}
          closeModal={displayFalse}
          reservation_id={eventData.event.extendedProps.reservationId}
          selectedLanguage={props.selectedLanguage}
        />
      )}

      {menuValue === "unassign-room" && (
        <HoldBooking
          value="unassign-room"
          title={t("Unassign Room")}
          buttonText={t("Yes, un-assign")}
          reservationData={reservationData}
          buttonFunction={unassign}
          closeBackDrop={handleAddEventSidebar}
          load={load}
          closeModal={displayFalse}
          reservation_id={eventData.event.extendedProps.reservationId}
          eventData={eventData}
          selectedLanguage={props.selectedLanguage}
        />
      )}
      {menuValue === "lock-booking" && (
        <HoldBooking
          value="lock-booking"
          title={
            eventData.event.extendedProps.locked_status !== "LOCKED"
              ? `${t("Lock Room")}`
              : `${t("Unlock Room")}`
          }
          buttonText={
            eventData.event.extendedProps.locked_status !== "LOCKED"
              ? `${t("Yes, Lock")}`
              : `${t("Yes, Unlock")}`
          }
          reservationData={reservationData}
          buttonFunction={setLock}
          load={load}
          closeBackDrop={handleAddEventSidebar}
          closeModal={displayFalse}
          reservation_id={eventData.event.extendedProps.reservationId}
          eventData={eventData}
          selectedLanguage={props.selectedLanguage}
        />
      )}
      {menuValue === "no-show" && (
        <CancelBooking
          value="no-show"
          title={t("Set No Show")}
          buttonText={t("Yes")}
          buttonFunction={setNoShow}
          reservationData={reservationData}
          load={load}
          closeBackDrop={handleAddEventSidebar}
          closeModal={displayFalse}
          reservation_id={eventData.event.extendedProps.reservationId}
          setwaiveNoShowFee={setwaiveNoShowFee}
          waiveNoShowFee={waiveNoShowFee}
          selectedLanguage={props.selectedLanguage}
        />
      )}

      {menuValue === "extend-checkin" && (
        <ExtendEventSidebar
          reload={reload}
          eventData={returnDataObj("start")}
          open={menuValue === "extend-checkin"}
          handleAddEventSidebar={displayFalse}
          addEvent={checkinCheckout}
          selectedLanguage={props.selectedLanguage}
        ></ExtendEventSidebar>
      )}
      {menuValue === "extend-checkout" && (
        <ExtendEventSidebar
          reload={reload}
          eventData={returnDataObj("")}
          open={menuValue === "extend-checkout"}
          handleAddEventSidebar={displayFalse}
          addEvent={checkinCheckout}
          selectedLanguage={props.selectedLanguage}
        ></ExtendEventSidebar>
      )}
      {menuValue === "split-room" && (
        <SplitRoom
          value="split-room"
          title={t("Split Room")}
          buttonText="Yes, Split room"
          roomID={reservationData.reservation_rooms}
          bookingId={reservationData.booking_id}
          buttonFunction={cancelExisting}
          closeBackDrop={handleAddEventSidebar}
          load={load}
          closeModal={displayFalse}
          reservation_id={eventData.event.extendedProps.reservationId}
          reload={reload}
          reservationData={reservationData}
          selectedLanguage={props.selectedLanguage}
        />
      )}
      {menuValue === "move-room" && (
        <MoveRoom
          value="move-room"
          title={t("Move Room")}
          buttonText={t("Yes, Move room")}
          bookingId={reservationData.booking_id}
          buttonFunction={cancelExisting}
          closeBackDrop={handleAddEventSidebar}
          load={load}
          closeModal={displayFalse}
          reservation_id={eventData.event.extendedProps.reservationId}
          reservationData={reservationData}
          reload={reload}
          selectedLanguage={props.selectedLanguage}
        />
      )}
      {menuValue === "move" && (
        <DragEventSidebar
          reload={reload}
          eventData={returnDataObj("move")}
          open={menuValue === "move"}
          handleAddEventSidebar={displayFalse}
          addEvent={checkinCheckout}
          roomInformation={roomInformation}
          roomTypeInfo={roomTypeInfo}
          editRes={true}
          reservationData={reservationData}
          selectedLanguage={props.selectedLanguage}
        ></DragEventSidebar>
      )}
      {menuValue === "upgrade" && (
        <DragEventSidebar
          reload={reload}
          eventData={returnDataObj("upgrade")}
          open={menuValue === "upgrade"}
          handleAddEventSidebar={displayFalse}
          addEvent={checkinCheckout}
          roomInformation={roomInformation}
          roomTypeInfo={roomTypeInfo}
          upgrade={"upgrade"}
          selectedLanguage={props.selectedLanguage}
        ></DragEventSidebar>
      )}

      <Drawer
        anchor={"right"}
        open={gueststate["right"]}
        onClose={toggleGuestDrawer("right", false)}
        PaperProps={{
          sx: { width: 825 },
        }}
      >
        {guestList("right")}
      </Drawer>

      {openSettleModal && (
        <NewPaymentModalEditView
          open={openSettleModal}
          handleAddEventSidebar={() => setopenSettleModal(false)}
          invoiceData={invoiceData}
          currency={currency}
          hotelType={hotelType}
          reservationData={reservationData}
          selectedLanguage={props.selectedLanguage}
          reservationId={eventData?.event?.extendedProps?.reservationId}
        ></NewPaymentModalEditView>
      )}

      {openPaymentModal && (
        <PaymentModal
          closeFunc={() => {
            setOpenPaymentModal(false);
          }}
          open={openPaymentModal}
          modalClose={() => {
            setOpenPaymentModal(false);
          }}
          selectedLanguage={props.selectedLanguage}
          // bookingData={bookingData}
          invoiceData={invoiceData}
          hotelType={hotelType}
          summaryData={summaryData}
          hotelLogo={hotelLogo}
          currency={currency}
          paymentType={paymentType}
          cashPayLoading={cashPayLoading}
          offlineCashDate={offlineCashDate}
          serviceAmountDesc={serviceAmountDesc}
          handleCashPayment={handleCashPayment}
          reservationData={reservationData}
          handleCloseCashPayment={() => setOpenPaymentModal(false)}
          amount={amount}
          setServiceAmountDesc={setServiceAmountDesc}
          setOfflineCashDate={setOfflineCashDate}
          setAmount={setAmount}
          handleCloseOfflineCardPay={() => setOpenPaymentModal(false)}
          setOfflineCardDate={setOfflineCardDate}
          offlineCardDate={offlineCardDate}
          offlineCardAmt={offlineCardAmt}
          setOfflineCardDesc={setOfflineCardDesc}
          setOfflineCardAmt={setOfflineCardAmt}
          offlineCardPayLoading={offlineCardPayLoading}
          handleOfflineCardPayment={handleOfflineCardPayment}
          handleOfflineCheckPayment={handleOfflineCheckPayment}
          setChequeDesc={setChequeDesc}
          handleCloseOfflineCheckPay={() => setOpenPaymentModal(false)}
          offlineCheckPayLoading={offlineCheckPayLoading}
          setOfflineCheckAmt={setOfflineCheckAmt}
          offlineCheckAmt={offlineCheckAmt}
          setOfflineCheckDate={setOfflineCheckDate}
          offlineCheckDate={offlineCheckDate}
          chequeDesc={chequeDesc}
          upiAmt={upiAmt}
          setUpiAmt={setUpiAmt}
          upiDate={upiDate}
          setUpiDate={setUpiDate}
          upiDesc={upiDesc}
          setUpiDesc={setUpiDesc}
          handleUpiPayment={handleUpiPayment}
          upiPayLoading={upiPayLoading}
          handleCloseOtherModePay={() => setOpenPaymentModal(false)}
          handleOtherModePayment={handleOtherModePayment}
          otherModeAmt={otherModeAmt}
          setOtherModeAmt={setOtherModeAmt}
          otherModeDesc={otherModeDesc}
          otherModePayLoading={otherModePayLoading}
          otherModeDate={otherModeDate}
          setOtherModeDate={setOtherModeDate}
          setOtherModeDesc={setOtherModeDesc}
          setBankTransferModeAmt={setBankTransferModeAmt}
          bankTransferModeAmt={bankTransferModeAmt}
          bankTransferModePayLoading={bankTransferModePayLoading}
          setBankTransferModeDesc={setBankTransferModeDesc}
          bankTransferModeDesc={bankTransferModeDesc}
          bankTransferModeDate={bankTransferModeDate}
          setBankTransferModeDate={setBankTransferModeDate}
          handleBankTransferPayment={handleBankTransferPayment}
          setDepositAmt={setDepositAmt}
          depositAmt={depositAmt}
          depositName={depositName}
          handleCloseCashDeposit={() => setOpenPaymentModal(false)}
          handleDeposit={handleDeposit}
          depositLoading={depositLoading}
          depositDate={depositDate}
          setDepositDate={setDepositDate}
          setDepositName={setDepositName}
          paymentLinkEmail={paymentLinkEmail}
          setPaymentLinkEmail={setPaymentLinkEmail}
          paymentLinkMobile={paymentLinkMobile}
          setPaymentLinkMobile={setPaymentLinkMobile}
          paymentLinkAmt={paymentLinkAmt}
          setPaymentLinkAmt={setPaymentLinkAmt}
          paymentLinkLoading={paymentLinkLoading}
          handlePaymentLink={handlePaymentLink}
        ></PaymentModal>
      )}

      {bookingShow && (
        <BookingNotesModal
          closeFunc={() => {
            handleCloseBookingShow();
          }}
          open={bookingShow}
          modalClose={() => {
            handleCloseBookingShow();
          }}
          selectedLanguage={props.selectedLanguage}
          invoiceData={invoiceData}
          handleCloseBookingConf={() => handleCloseBookingShow()}
          handleSendBookingConfirmation={() => handleSendBookingConfirmation()}
          hotelType={hotelType}
          setBookingConfMail={setBookingConfMail}
          bookingConfMail={bookingConfMail}
          bookingConfLoading={bookingConfLoading}
          createBookingNote={createBookingNote}
          loading={loading}
          bookingNoteData={bookingNoteData}
          setbookingNoteData={setbookingNoteData}
        ></BookingNotesModal>
      )}

      {openCustomerInfo && (
        <NewBookingInformation
          allGuestList={allGuestList}
          saveCustomer={saveCustomer}
          saveCustomerRes={saveCustomerRes}
          load={load}
          reservationData={reservationData}
          reloadReservationData={reloadReservationData}
          doNotRent={doNotRent}
          getDoNotRentStatus={getDoNotRentStatus}
          setLoad={setLoad}
          eventData={eventData}
          fetchGuestList={() => fetchGuestList(reservationData?.booking_id)}
          handleAddEventSidebar={handleAddEventSidebar}
          setShowModal={setShowModal}
          setTabValue={setTabValue}
          setActionType={setActionType}
          setShows={setShow}
          reload={reload}
          bookingUserData={bookingUserData}
          selectedLanguage={props.selectedLanguage}
          setInvoiceData={setInvoiceData}
          invoiceData={invoiceData}
          open={openCustomerInfo}
          close={() => setopenCustomerInfo(false)}
          selectedEmailGuest={selectedEmailGuest}
        />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    data: state.auth.selectedHotel,
    hotelType: state.auth.hotelType,
    groupHotelEmail: state.auth.email,
    folioConfigs: state.auth.folioConfig,
  };
}
export default NewEditModalView;
