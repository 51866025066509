import React, { useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Fab,
  Grid,
  Grow,
  IconButton,
  Paper,
  Popper,
  TextField,
  Typography,
  Button,
  Stack,
  Checkbox,
} from "@mui/material";
import { Close, Send } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import CommonSlider from "./CommonSlider";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { shallowEqual, useSelector } from "react-redux";
import { toast } from "material-react-toastify";
import { useTranslation } from "react-i18next";
import i18n from "../../../modules/Auth/pages/i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
  custom_box: {
    width: "100%",
    padding: theme.spacing(2),
    whiteSpace: "no-wrap",
    borderRadius: 10,
  },
}));

const DirtyModal = (props) => {
  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );
  const {
    open,
    closeFunc,
    modalClose,
    selectedLanguage,
    magicLinkLoading,
    invoiceData,
    checkin,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CommonSlider
        onBackdropClick={closeFunc}
        onClose={modalClose}
        open={open}
      >
        <Grid container className={classes.textField}>
          <Grid item xs={10} alignSelf="center"></Grid>
          <Grid item xs={2}>
            <IconButton
              onClick={() => {
                modalClose();
              }}
              sx={{ float: "right" }}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>

        <Divider />

        <Grid container spacing={2} className={classes.textField}>
          <Grid item xs={12}>
            <div className="folioConfirmationWrapper">
              <h3 className="mb-4 mt-4">
                {`Room number - ${invoiceData?.invoice?.roomids} is Dirty. Click Yes to check-in`}
              </h3>

              <Button
                className="submit w-auto"
                variant="custom-button"
                onClick={() => checkin()}
                disabled={magicLinkLoading}
                style={{ float: "right" }}
              >
                {magicLinkLoading ? (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                ) : (
                  `${t("Yes")}`
                )}
              </Button>
            </div>
          </Grid>
        </Grid>
      </CommonSlider>
    </div>
  );
};

export default DirtyModal;
