import React, { useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Fab,
  Grid,
  Grow,
  IconButton,
  Paper,
  Popper,
  TextField,
  Typography,
  Button,
  Stack,
  Checkbox,
} from "@mui/material";
import { Close, Send } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import CommonSlider from "./CommonSlider";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { shallowEqual, useSelector } from "react-redux";
import { toast } from "material-react-toastify";
import { useTranslation } from "react-i18next";
import i18n from "../../../modules/Auth/pages/i18n";

import {
  Document,
  Page,
  PDFDownloadLink,
  StyleSheet,
} from "@react-pdf/renderer";
import { handleSpringDataRequest } from "../../../api";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
  custom_box: {
    width: "100%",
    padding: theme.spacing(2),
    whiteSpace: "no-wrap",
    borderRadius: 10,
  },
}));

const SendMagicLinkModal = (props) => {
  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );
  const {
    open,
    closeFunc,
    modalClose,
    bookingData,
    selectedLanguage,
    setMagicLinkPhone,
    setMagicLinkMail,
    magicLinkPhone,
    magicLinkMail,
    envType,
    handleSendMagicLink,
    magicLinkLoading,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const classes = useStyles();
  const [hash, sethash] = useState("");
  useEffect(() => {
    handleSpringDataRequest(
      `web-api/get-booking-id-hash?bookingId=${bookingData.bookingId}`
    )
      .then((res) => sethash(res.hash))
      .catch((err) => console.error(err));
  }, [bookingData.bookingId]);

  return (
    <div className={classes.root}>
      <CommonSlider
        onBackdropClick={closeFunc}
        onClose={modalClose}
        open={open}
      >
        <Grid container className={classes.textField}>
          <Grid item xs={10} alignSelf="center">
            <Typography variant="h5">{t("Send magic link")}</Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              onClick={() => {
                modalClose();
              }}
              sx={{ float: "right" }}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>

        <Divider />

        <Grid container spacing={2} className={classes.textField}>
          <Grid item xs={12}>
            <div className="folioConfirmationWrapper">
              <p className="text-muted mb-4 mt-4">
                {t(
                  "Magic Link connects your guests to your property in real time. They can view the itinerary, perform self check-ins, room upgrades, stay extensions, and add-on purchases."
                )}
              </p>
              <div
                className="folioContent"
                style={{
                  borderBottom: "1px solid #DDDDDD",
                  paddingBottom: "15px",
                  marginBottom: "15px",
                }}
              >
                <div
                  className="fieldWrapper"
                  style={{
                    position: "relative",
                    width: "100%",
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#C8E5F3",
                    padding: "5px",
                    borderRadius: "5px",
                    color: "black",
                  }}
                >
                  {envType.toLowerCase() === "prod"
                    ? `https://ss.stayflexi.com/${bookingData.bookingId.replace(
                        /\D/g,
                        ""
                      )}/${hash}`
                    : envType.toLowerCase() === "beta"
                    ? `https://beta-next.selfservice.stayflexi.com/${bookingData.bookingId.replace(
                        /\D/g,
                        ""
                      )}/${hash}`
                    : `https://preprod-next.selfservice.stayflexi.com/${bookingData.bookingId.replace(
                        /\D/g,
                        ""
                      )}/${hash}`}
                </div>
                <br></br>
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label={t("Email")}
                    name="email"
                    helperText={`${t(
                      "By default customers email has been populated. Override if necessary."
                    )}`}
                    value={magicLinkMail}
                    onChange={(e) => setMagicLinkMail(e.target.value)}
                    sx={{ width: "100%" }}
                  />
                </div>
                <br></br>
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label={t("Phone number")}
                    name="phone"
                    helperText={`${t(
                      "By default customers contact number has been populated. Override if necessary."
                    )}`}
                    value={magicLinkPhone}
                    onChange={(e) => {
                      /^[\+0-9]*$/.test(e.target.value) &&
                        setMagicLinkPhone(e.target.value);
                    }}
                    sx={{ width: "100%" }}
                  />
                </div>
              </div>

              <Button
                className="submit w-auto"
                variant="custom-button"
                onClick={() => handleSendMagicLink()}
                disabled={magicLinkLoading}
                style={{ float: "right" }}
              >
                {magicLinkLoading ? (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                ) : (
                  `${t("Send Magic Link")}`
                )}
              </Button>
            </div>
          </Grid>
        </Grid>
      </CommonSlider>
    </div>
  );
};

export default SendMagicLinkModal;

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    width: "50vw",
    backgroundColor: "#fff",
    height: "100%",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});
