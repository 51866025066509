import React, { useState, useEffect, useRef } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  djangoHandleDataMutationRequest,
  handleSpringDataRequest,
  handleSpringDataMutationRequest,
} from "../../api/index";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import cellEditFactory from "react-bootstrap-table2-editor";
import moment from "moment";
import { connect } from "react-redux";
import axios from "axios";
import { Stack, Button, TextField, CircularProgress } from "@mui/material";
import i18n from "../../modules/Auth/pages/i18n";
import { useTranslation } from "react-i18next";

function AdvInvoiceSummaryTable({
  addServiceBtn,
  invoiceData,
  advInvoiceData,
  bookingId,
  hotelId,
  updateInvoice,
  updateAdvInvoice,
  currency,
  userId,
  loggedUserEmail,
  updateSummaryData,
  mockTableTrue,
  mockTableFalse,
  data,
  folioConfig,
  handleGetAllCustomFolios,
  isNightAuditOn,
  subUser,
  selectedLanguage,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);
  // Returning actual date based on the epoch date
  const timeFormatter = (cell, row) => {
    return row.service_date;
  };

  // Getting the hotel currency
  const hotelCurrency = localStorage.getItem("hotelCurrency");

  const [hotelConvertedCurrency, sethotelConvertedCurrency] = useState(
    localStorage.getItem("hotelCurrency")
  );
  const [hotelConvertedCurrencySymbol, sethotelConvertedCurrencySymbol] =
    useState(localStorage.getItem("symbol"));

  useEffect(() => {
    if (
      sessionStorage.getItem("convertedHotelCurrency") != null &&
      sessionStorage.getItem("convertedHotelCurrency") !== undefined
    ) {
      sethotelConvertedCurrency(
        sessionStorage.getItem("convertedHotelCurrency")
      );
      sethotelConvertedCurrencySymbol(
        sessionStorage.getItem("convertedHotelCurrencySymbol")
      );
      if (
        hotelCurrency !== "" &&
        hotelCurrency !== undefined &&
        hotelCurrency !== null &&
        sessionStorage.getItem("convertedHotelCurrency") !== "" &&
        sessionStorage.getItem("convertedHotelCurrency") !== undefined &&
        sessionStorage.getItem("convertedHotelCurrency") !== null
      ) {
        getConvertedvalue();
      }
    }
  }, [
    sessionStorage.getItem("convertedHotelCurrency"),
    sessionStorage.getItem("defaultRate"),
  ]);

  // Check if the row is editable or not
  const checkIfEditable = (cell, row) => {
    if (
      JSON.parse(
        data?.accessControl?.user_feature_map
          ?.AllowPerNightRoomPriceEditAdvFolio
      ).read === "YES"
    ) {
      if (row?.room_id && row?.room_id.length > 0) {
        return true;
      } else if (
        row?.line_item_type === "OFFLINE_PAYMENT" &&
        !row?.service_desc.includes("DISCOUNT")
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  // Tax percentage formatter
  const taxPercentageFormatter = (cell, row) => {
    let taxPercent = 0;
    if (row?.service_amount != 0) {
      taxPercent = Number(row?.service_tax) / Number(row?.service_amount);
      taxPercent = taxPercent * 100;
    } else {
      taxPercent = 0;
    }
    return Number(taxPercent).toFixed(2);
  };

  // getting the services data for the invoice

  // HOTFIX : Rounding off the folio amount

  const { SearchBar } = Search;

  // const products = advInvoiceData.items;

  // let products = invoiceData?.invoice.items;
  const [products, setproducts] = useState(advInvoiceData.items);
  const [convertedAmount, setconvertedAmount] = useState(0);

  // Setting up the advanced invoice items
  function setAdvInvItems() {
    let totalBookingFee = [];
    if (Number(invoiceData?.invoice?.total_fees) > 0) {
      let bookingFee = {};
      bookingFee["service_id"] = "-2";
      bookingFee["service_amount"] = Number(invoiceData?.invoice?.total_fees);
      bookingFee["service_tax"] = "-";
      bookingFee["service_total"] = Number(invoiceData?.invoice?.total_fees);
      bookingFee["service_amount_type"] = "DEBIT";
      bookingFee["service_date"] = moment(
        invoiceData?.invoice?.checkin,
        "YYYY-MM-DDTHH:mm"
      ).format("MMM DD YYYY");
      bookingFee["pos_order_id"] = "";
      bookingFee["service_desc"] = "Booking fees";
      bookingFee["line_item_type"] = "BOOKING_FEES";
      bookingFee["line_item_id"] = "-2";
      totalBookingFee.push(bookingFee);
    }
    sessionStorage.setItem(
      "advitems",
      JSON.stringify([...advInvoiceData.items, ...totalBookingFee])
    );
  }
  useEffect(() => {
    setAdvInvItems();
  }, []);

  const getConvertedvalue = async () => {
    if (
      sessionStorage.getItem("defaultRate") === 0 &&
      sessionStorage.getItem("defaultRate") === undefined &&
      sessionStorage.getItem("defaultRate") === null
    ) {
      const data = await axios.get(
        `${
          process.env.REACT_APP_SPRING_API_URL
        }web-api/currency-convert-value?from=${hotelCurrency}&to=${sessionStorage.getItem(
          "convertedHotelCurrency"
        )}`
      );
      setconvertedAmount(data.data);
    } else {
      const data = await axios.get(
        `${
          process.env.REACT_APP_SPRING_API_URL
        }web-api/currency-convert-value?from=${hotelCurrency}&to=${sessionStorage.getItem(
          "convertedHotelCurrency"
        )}&amount=${sessionStorage.getItem("defaultRate")}`
      );
      setconvertedAmount(data.data);
    }
  };

  // Function to set the advanced invoice items from the session storage
  function getAdvInvItems() {
    if (
      hotelCurrency !== null &&
      hotelCurrency !== undefined &&
      hotelConvertedCurrency !== null &&
      hotelConvertedCurrency !== undefined &&
      hotelConvertedCurrency !== ""
    ) {
      if (hotelCurrency !== hotelConvertedCurrency) {
        const data = JSON.parse(sessionStorage.getItem("advitems"))?.map(
          (res) => {
            let e = res;
            e["service_amount"] = e["service_amount"] * convertedAmount;
            e["service_tax"] = e["service_tax"] * convertedAmount;
            e["service_total"] = e["service_total"] * convertedAmount;
            return e;
          }
        );

        setproducts(data);
      } else {
        setproducts(JSON.parse(sessionStorage.getItem("advitems")));
      }
    }
  }
  useEffect(() => {
    getAdvInvItems();
  }, [convertedAmount, hotelConvertedCurrency]);

  // Creating the bootstrap table ref
  const advInvoiceSummTableRef = useRef();
  const columns = [
    {
      dataField: "service_id",
      text: `${t("Id")}`,
      sort: true,
      headerStyle: {
        fontSize: "12px",
      },
      editable: false,
      hidden: true,
    },
    {
      dataField: "epoch_date",
      text: `${t("Date")}`,
      sort: true,
      headerStyle: {
        fontSize: "12px",
        width: "100px",
      },
      editable: false,
      // hidden: true,
      formatter: timeFormatter,
    },

    {
      dataField: "hsn_code",
      text: `${t("HSN / SAC code")}`,
      sort: true,
      headerStyle: {
        fontSize: "12px",
        width: "100px",
      },
      editable: false,
      hidden:
        hotelCurrency !== "INR" ||
        folioConfig?.display_hsn_code_invoice !== "YES",
    },
    {
      dataField: "service_date",
      text: `${t("Date")}`,
      sort: true,
      headerStyle: {
        fontSize: "12px",
        width: "100px",
      },
      editable: false,
      hidden: true,
    },
    {
      dataField: "room_id",
      text: `${t("Room ID")}`,
      headerStyle: {
        fontSize: "12px",
        width: "80px",
      },
      editable: false,
      // hide: true
    },
    {
      dataField: "service_desc",
      text: `${t("Description")}`,
      headerStyle: {
        fontSize: "12px",
        width: "250px",
      },
      editable: false,
    },
    {
      dataField: "service_amount_type",
      text: `${t("Type")}`,
      headerStyle: {
        fontSize: "12px",
        width: "100px",
      },
      editable: false,
    },
    {
      dataField: "service_amount",
      text: `${t("Sub-total")} (${
        hotelCurrency === hotelConvertedCurrency
          ? currency
          : hotelConvertedCurrencySymbol
      })`,
      headerStyle: {
        fontSize: "12px",
        width: "100px",
      },
      formatter: (cell) => {
        return Number(Math.abs(cell)).toFixed(2);
      },
      style: (cell, row) => {
        return {
          fontWeight:
            row?.room_id &&
            row?.room_id.length > 0 &&
            JSON.parse(data?.accessControl?.user_feature_map?.EditBookingPrice)
              .read === "YES"
              ? 500
              : 400,
          textDecoration:
            row?.room_id &&
            row?.room_id.length > 0 &&
            JSON.parse(data?.accessControl?.user_feature_map?.EditBookingPrice)
              .read === "YES"
              ? "underline"
              : "none",
        };
      },
      editable: (cell, row) =>
        // isNightAuditOn &&
        // subUser.toLowerCase() === "subuser"
        invoiceData.invoice.status === "CHECKED_OUT"
          ? false
          : hotelCurrency === hotelConvertedCurrency &&
            JSON.parse(data?.accessControl?.user_feature_map?.EditBookingPrice)
              .read === "YES"
          ? checkIfEditable(cell, row)
          : false,
    },
    {
      isDummyField: true,
      text: `${t("Tax")} %`,
      sort: true,
      headerStyle: {
        fontSize: "12px",
        width: "115px",
      },
      formatter: (cell, row) => taxPercentageFormatter(cell, row),
      editable: false,
      hidden: true,
    },
    {
      dataField: "service_tax",
      text: `${t("Tax")} (${
        hotelCurrency === hotelConvertedCurrency
          ? currency
          : hotelConvertedCurrencySymbol
      })`,
      headerStyle: {
        fontSize: "12px",
        width: "100px",
      },
      formatter: (cell, row) => {
        if (row?.service_id === "-2") {
          return "-";
        } else {
          return Number(cell).toFixed(2);
        }
      },
      editable: false,
    },
    {
      dataField: "pos_service_charge",
      text: `${t("Service charge")} (${
        hotelCurrency === hotelConvertedCurrency
          ? currency
          : hotelConvertedCurrencySymbol
      })`,
      sort: true,
      headerStyle: {
        fontSize: "12px",
        width: "115px",
      },
      formatter: (cell, row) => {
        if (
          ["null", "", undefined].includes(row?.pos_order_id) &&
          !row?.service_desc?.includes("POS ORDER")
        ) {
          return "-";
        }
        return parseFloat(cell).toFixed(2);
      },
      editable: false,
      hidden:
        // (hotelType === "ISHA" ||
        invoiceData?.items?.items?.filter(
          (item) => !["null", ""].includes(item?.pos_order_id)
        ).length === 0 && true,
    },
    {
      dataField: "service_total",
      text: `${t("Total")} (${
        hotelCurrency === hotelConvertedCurrency
          ? currency
          : hotelConvertedCurrencySymbol
      })`,
      headerStyle: {
        fontSize: "12px",
        width: "100px",
      },
      style: (cell, row) => {
        return {
          fontWeight:
            row?.room_id &&
            row?.room_id.length > 0 &&
            JSON.parse(data?.accessControl?.user_feature_map?.EditBookingPrice)
              .read === "YES"
              ? 500
              : 400,
          textDecoration:
            row?.room_id &&
            row?.room_id.length > 0 &&
            JSON.parse(data?.accessControl?.user_feature_map?.EditBookingPrice)
              .read === "YES"
              ? "underline"
              : "none",
        };
      },
      editable: (cell, row) =>
        // isNightAuditOn &&
        // invoiceData.invoice.status === "CHECKED_OUT" &&
        invoiceData.invoice.status === "CHECKED_OUT"
          ? false
          : hotelCurrency === hotelConvertedCurrency &&
            JSON.parse(data?.accessControl?.user_feature_map?.EditBookingPrice)
              .read === "YES"
          ? checkIfEditable(cell, row)
          : false,
      formatter: (cell) => {
        return Number(Math.abs(cell)).toFixed(2);
      },
    },
    // {
    //   dataField: "loader",
    //   isDummyField: true,
    //   formatter: (cell, row, rowIndex) => {
    //     if (pretaxEditLoading) {
    //       return <CircularProgress size="15px" color="secondary" />;
    //     } else if (editTaxLoading) {
    //       return <CircularProgress size="15px" color="secondary" />;
    //     } else {
    //       return "";
    //     }
    //   },
    //   editable: false,
    // },
  ];

  // getting the selected row data

  // selected rows ids
  const [selectedRows, setSelectedRows] = useState([]);
  // selected rows desc list
  // const [selectedRowsDesc, setSelectedRowsDesc] = useState([]);
  // selected rows amt list
  // const [selectedRowsAmt, setSelectedRowsAmt] = useState([]);
  // selected rows tax list
  // const [selectedRowsTax, setSelectedRowsTax] = useState([]);

  // row selection for operations
  // selecting the rows
  const handleOnSelect = (row, isSelect) => {
    if (isSelect && row.service_id === "0") {
      return false;
    } else if (isSelect) {
      setSelectedRows((selectedRows) => [...selectedRows, row.service_id]);
      // setSelectedRowsDesc((selectedRows) => [
      //   ...selectedRows,
      //   row.service_desc,
      // ]);
      // setSelectedRowsAmt((selectedRows) => [
      //   ...selectedRows,
      //   parseInt(row.service_amount),
      // ]);
      // setSelectedRowsTax((selectedRows) => [
      //   ...selectedRows,
      //   parseInt(row.service_tax),
      // ]);
    } else {
      setSelectedRows(selectedRows.filter((x) => x !== row.service_id));
      // setSelectedRowsDesc(selectedRows.filter((x) => x !== row.service_desc));
      // setSelectedRowsAmt(
      //   selectedRows.filter((x) => x !== parseInt(row.service_amount))
      // );
      // setSelectedRowsTax(
      //   selectedRows.filter((x) => x !== parseInt(row.service_tax))
      // );
    }
  };

  // selecting all rows at once
  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows
      .filter((row) => row.service_id !== "0")
      .map((r) => r.service_id);
    // const desc = rows
    //   .filter((row) => row.service_id !== "0")
    //   .map((r) => r.service_desc);
    // const amount = rows
    //   .filter((row) => row.service_id !== "0")
    //   .map((r) => parseInt(r.service_amount));
    // const tax = rows
    //   .filter((row) => row.service_id !== "0")
    //   .map((r) => parseInt(r.service_tax));

    if (isSelect) {
      setSelectedRows(ids);
      // setSelectedRowsDesc(desc);
      // setSelectedRowsAmt(amount);
      // setSelectedRowsTax(tax);
    } else {
      setSelectedRows([]);
      // setSelectedRowsDesc([]);
      // setSelectedRowsAmt([]);
      // setSelectedRowsTax([]);
    }
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    selected: selectedRows,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    // nonSelectable: ["-2"],
  };

  // Handle which payment type to edit
  const handlePaymentType = (
    dataField,
    newAmt,
    oldAmt,
    roomId,
    serviceDate
  ) => {
    if (dataField === "service_amount") {
      handleEditPretaxAmt(newAmt, roomId, serviceDate);
    } else if (dataField === "service_total") {
      handleEditTaxAmt(newAmt, roomId, serviceDate);
    }
  };

  // editing invoice room with pretax amount

  // Loader for invoice pretax amount
  const [pretaxEditLoading, setPretaxLoading] = useState(false);

  function handleEditPretaxAmt(pretaxAmt, roomId, serviceDate) {
    const disc = products?.filter(
      (item) => item?.service_desc === "Promo discount - DISCOUNT"
    );
    if (disc?.length > 0) {
      if (Math.abs(disc[0]?.service_amount) > pretaxAmt) {
        toast.error(
          `${t("The new amount can't be lower than the applied discount")}`
        );
        updateAdvInvoice();
        return;
      }
    }
    setPretaxLoading(true);
    mockTableTrue();
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/editAdvInvoiceRoomPretaxAmount/`,
        {
          hotelId: hotelId,
          booking_id: bookingId,
          amount: pretaxAmt,
          date: moment(serviceDate).format("MMM DD, YYYY"),
          room_id: roomId,
          logged_user_email: loggedUserEmail,
          // user_ip: getIpAddress(),
        }
      )
      .then((res) => updateSummaryData())
      .then((res) => updateAdvInvoice())
      .catch((error) => toast.error(`${t("Oops! Error updating the amount.")}`))
      .then(() => setPretaxLoading(false))
      .then(() => mockTableFalse());
    // .then(() => updateSummaryData());
  }

  // Editing with tax amount
  const [editTaxLoading, setEditTaxLoading] = useState(false);
  function handleEditTaxAmt(newVal, rowId, serviceDate) {
    const disc = products?.filter(
      (item) => item?.service_desc === "Promo discount - DISCOUNT"
    );
    if (disc?.length > 0) {
      if (Math.abs(disc[0]?.service_total) > newVal) {
        toast.error(
          `${t("The new amount can't be lower than the applied discount")}`
        );
        updateAdvInvoice();
        return;
      }
    }
    mockTableTrue();
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/editAdvInvoiceRoomWithTaxAmount/`,
        {
          hotelId: hotelId,
          booking_id: bookingId,
          amount: newVal,
          row_id: rowId,
          date: moment(serviceDate).format("MMM DD, YYYY"),
          logged_user_email: loggedUserEmail,
          // user_ip: getIpAddress(),
        }
      )
      .then((res) => updateAdvInvoice())
      .then((res) => updateSummaryData())
      .catch((error) => toast.error(`${t("Oops! Error updating the amount.")}`))
      .then(() => setEditTaxLoading(false))
      .then(() => mockTableFalse());
  }

  // Creating the custom folio
  // GET call
  // Custom folio name
  const [customFolioName, setCustomFolioName] = useState("");
  const [createCustomInvoiceLoading, setCreateCustomInvoiceLoading] =
    useState(false);
  // Unselect all the rows once api is done reloading
  function unselectAllRows(selectionContext) {
    return selectionContext.handleSelectionChange([]);
  }
  function handleCreateCustomFolio() {
    setCreateCustomInvoiceLoading(true);
    handleSpringDataMutationRequest(
      "POST",
      "core/api/v1/folio/create-custom-folio",
      {
        booking_id: bookingId,
        custom_folio_name: customFolioName,
        hotel_services_ids: JSON.parse(
          sessionStorage.getItem("advitems")
        ).filter((advItems) => selectedRows.includes(advItems.service_id)),
      }
    )
      .then((res) =>
        toast.success(`${customFolioName} custom folio is successfully created`)
      )
      .then((res) => handleGetAllCustomFolios())
      .catch((err) => toast.error(err))
      .finally(() => setCreateCustomInvoiceLoading(false))
      .finally(() => setCustomFolioName(""))
      .finally(() => setSelectedRows([]));
  }

  const updatePaymentAmount = (oldValue, newValue, id) => {
    handleSpringDataRequest(
      `core/api/v1/folio/update-payment?previousAmount=${oldValue}&updatedAmount=${newValue}&bookingId=${bookingId}&serviceId=${id}`
    )
      .then((res) => {
        toast.success(res);
      })
      .then((res) => {
        updateInvoice();
        updateAdvInvoice();
      })
      .then((res) => updateSummaryData())
      .catch((err) => toast.error(err.response.data));
  };

  return (
    <>
      <div className="invoiceSummTableWrapper">
        {selectedRows?.length > 0 && (
          <Stack
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
            direction={"row"}
            spacing={1}
          >
            <TextField
              required
              id="standard-basic"
              variant="standard"
              name="Enter custom folio name"
              label={t("Enter custom folio name")}
              value={customFolioName}
              onChange={(e) => setCustomFolioName(e.target.value)}
              // InputLabelProps={{ shrink: true }}
              size={"small"}
              sx={{ width: "200px" }}
            />
            <Button
              variant="custom-button"
              disabled={
                customFolioName?.trim()?.length === 0 ||
                createCustomInvoiceLoading
              }
              onClick={() => handleCreateCustomFolio()}
            >
              {createCustomInvoiceLoading ? (
                <CircularProgress size={"15px"} />
              ) : (
                `${t("Create custom folio")}`
              )}
            </Button>
          </Stack>
        )}
        <ToolkitProvider
          keyField="service_id"
          data={products}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div className="folioTableWrapper">
                <BootstrapTable
                  {...props.baseProps}
                  ref={advInvoiceSummTableRef}
                  tabIndexCell
                  headerWrapperClasses="foo"
                  bodyClasses="tableBody"
                  bordered={false}
                  selectRow={selectRow}
                  pagination={paginationFactory()}
                  cellEdit={cellEditFactory({
                    mode: "click",
                    blurToSave: true,
                    beforeSaveCell: (oldValue, newValue, row, column) => {
                      Number(oldValue) !== Number(newValue) &&
                        row.room_id.length > 0 &&
                        handlePaymentType(
                          column.dataField,
                          newValue,
                          oldValue,
                          row.room_id,
                          row.service_date
                        );
                      // handleEditTaxAmt(newValue, row.roomId, row.service_date)
                    },
                    afterSaveCell: (oldValue, newValue, row, column) => {
                      if (row.line_item_type === "OFFLINE_PAYMENT") {
                        if (oldValue !== newValue) {
                          updatePaymentAmount(
                            oldValue,
                            -Math.abs(parseFloat(newValue)),
                            row.line_item_id
                          );
                        }
                      }
                    },
                  })}
                  // rowStyle = {rowStyle}
                />
              </div>
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    data: state.auth.selectedHotel,
    folioConfig: state?.auth?.folioConfig,
  };
}
export default connect(mapStateToProps)(AdvInvoiceSummaryTable);
